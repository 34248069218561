import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AvailableLanguages } from "../models/Translated";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languageSubject: BehaviorSubject<AvailableLanguages> = new BehaviorSubject<AvailableLanguages>("es");
  language: string;

  constructor() {
    this.language = this.languageSubject.value;
  }

  setLanguage(newLanguage: AvailableLanguages) {
    this.languageSubject.next(newLanguage);
    this.language = newLanguage;
  }
}
