import { OrderExpand } from './../models/Order';
import { Coupon } from './../models/Coupon';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { firestore } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {

  constructor(
    private db: AngularFirestore
  ) { }

  async verifyCouponDiscount(
    code: string,
    clientId: string,
    subtotalPrice: number
  ): Promise<{ valid: boolean, discount: number, coupon: Coupon, error: string }> {
    const coupon = await this.db.collection<Coupon>('coupons',
      ref => ref.where('active', '==', true).where('code', '==', code).where('usesLeft', '>=', 1)
    ).get({ source: 'server' })
      .pipe(map(coupons => {
        return coupons.docs[0] ? { ...coupons.docs[0].data(), id: coupons.docs[0].id } as Coupon : null;
      })).toPromise();

    if (!coupon) {
      return { valid: false, discount: 0, coupon: null, error: 'Cupón inválido' };
    } else if (coupon.usedBy?.find(used => used.clientId === clientId)) {
      return { valid: false, discount: 0, coupon: null, error: 'Esta cupón ya está en uso' };
    } else if (coupon.type === 'FIXED' && coupon.amount >= subtotalPrice) {
      return { valid: false, discount: 0, coupon: null, error: `Cupón solo válido para compras mayores a $${coupon.amount}` };
    }

    return {
      valid: true,
      discount: coupon.type === 'FIXED' ? coupon.amount : subtotalPrice * (coupon.amount / 100),
      coupon,
      error: null
    };
  }

  getValidCoupon(couponId: string): Observable<Coupon> {
    return this.db.doc<Coupon>(`coupons/${couponId}`).valueChanges()
      .pipe(map(coupon => {
        if (coupon.active && coupon.usesLeft >= 1) {
          return coupon;
        } else {
          return null;
        }
      }));
  }

  getValidCoupons(): Observable<Coupon[]>{
    return this.db.collection<Coupon>('coupons')
          .valueChanges({idField: 'id'})
  }


  updateCouponDataUsed(order: OrderExpand): Promise<any> {
    const coupon = this.db.doc<Coupon>(`coupons/${order.usedCoupon.id}`).ref;

    return coupon.set({
      usedBy: firestore.FieldValue.arrayUnion({ clientId: order.clientId, orderId: order.id }),
      usesLeft: firestore.FieldValue.increment(-1),
    } as any, { merge: true });
  }
}
