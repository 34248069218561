import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'formatAmount'
})
export class FormatAmountPipe implements PipeTransform {

  constructor(
    private currencyPipe: CurrencyPipe,
  ) {}

  transform(amount: number, sign: string = null): string {
    return this.currencyPipe.transform(amount, null, sign, '1.2-2', 'de');
  }

}
