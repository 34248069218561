import { SubCategory } from './../models/SubCategory';
import { switchMap, map } from 'rxjs/operators';
import { Category, CategoryWithSubcategories } from './../models/Category';
import { Injectable } from '@angular/core';
import { Observable, of, combineLatest } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { QuerySource } from '../models/General';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  constructor(private db: AngularFirestore) {}

  getSubcategoryById(categoryId: string, subcategoryId: string, source: QuerySource = "default"): Observable<SubCategory> {
    return this.db
      .collection("categories")
      .doc(categoryId)
      .collection("subcategories")
      .doc(subcategoryId)
      .get({ source })
      .pipe(
        map((doc) => {
          return {
            ...doc.data() as SubCategory,
            id: doc.id,
            categoryId,
          };
        })
      );
  }

  getAllCategories_dict_id(): Observable<{ [id: string]: Category }> {
    return this.db.collection("categories").snapshotChanges().pipe(
      map((docs) => {

        const categoriesDict: { [id: string]: Category } = {};
        docs.forEach((doc) => {
          categoriesDict[doc.payload.doc.id] = { ...doc.payload.doc.data() as Category, id: doc.payload.doc.id }
        })

        return categoriesDict;
      })
    )
  }


  getAllCategories(): Observable<Category[]> {
    return this.db.collection("categories").snapshotChanges().pipe(
      map((docs) => docs.map((doc) => ({ ...doc.payload.doc.data() as Category, id: doc.payload.doc.id })))
    )
  }

  getCategoryBySlug(slug: string): Observable<Category> {
    return this.db.collection<Category>("categories", (ref) => {
      return ref.where("slug", "==", slug).limit(1);
    })
    .valueChanges({ idField: "id" })
    .pipe(
      map((cats) => {
        return cats[0];
      })
    );
  }

  getCategoryById(id: string): Observable<Category> {
    return this.db.collection("categories").doc<Category>(id).valueChanges();
  }

  /**
   * Description: Gets all categories
   * @author Carlos Fontes
   */
  getCategories(): Observable<Category[]> {
    const categories$ = this.db
      .collection<Category>('categories', (ref) =>
        ref.where('active', '==', true)
      )
      .valueChanges({ idField: 'id' });

    return categories$;
  }

  /**
   * Description: Gets all categories with their subcategories
   * @author Carlos Fontes
   */
  getCategoriesWithSubcategories(): Observable<CategoryWithSubcategories[]> {
    const subcategories$ = this.db
      .collectionGroup<SubCategory>('subcategories', (ref) =>
        ref.where('active', '==', true)
      )
      .snapshotChanges()
      .pipe(
        map((dbSubcategories) => {
          const subcategories = dbSubcategories.map((subcategory) => {
            return {
              id: subcategory.payload.doc.id,
              categoryId: subcategory.payload.doc.ref.path.split('/')[1],
              ...subcategory.payload.doc.data(),
            };
          });
          return subcategories;
        })
      );

    const categories$ = this.db
      .collection<Category>('categories', (ref) =>
        ref.where('active', '==', true)
      )
      .valueChanges({ idField: 'id' });

    return combineLatest([subcategories$, categories$]).pipe(
      map(([subcategories, categories]) => {
        return categories.map((catg) => {
          return {
            ...catg,
            subcategories: subcategories.filter(
              (subcatg) => subcatg.categoryId === catg.id
            ),
          };
        });
      })
    );
  }

  /**
   * Description: Gets a category with its subcategories
   * @author Carlos Fontes
   */
  getCategoryWithSubcategories(
    categoryId: string
  ): Observable<CategoryWithSubcategories> {
    const category$ = this.db
      .doc<Category>(`categories/${categoryId}`)
      .valueChanges()
      .pipe(
        switchMap((dbCategory) => {
          return this.db
            .collection<SubCategory>(
              `categories/${categoryId}/subcategories`,
              (ref) => ref.where('active', '==', true)
            )
            .valueChanges({ idField: 'id' })
            .pipe(
              map((subcategories) => {
                return {
                  ...dbCategory,
                  id: categoryId,
                  subcategories: subcategories.map((subcatg) => ({
                    ...subcatg,
                    categoryId,
                  })),
                };
              })
            );
        })
      );

    return category$;
  }
}
