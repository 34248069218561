<ng-container *ngIf="hasFullfilledOrderWithProduct">
  <div class="comment-area">
    <app-comment-box-sender [productId]="productId"></app-comment-box-sender>

    <div class="hr"></div>

    <h2 class="title">Reviews</h2>

    <div class="comment-area__comments">
      <ng-container *ngIf="comments?.length > 0">
        <ng-container *ngFor="let comment of comments">
          <div class="comment-area__comments__comment">
            <div class="avatar">
              <p>{{getInitials(comment.user.firstName + ' ' + comment.user.lastName)}}</p>
            </div>
            <div class="content">
              <div class="content__name">
                <p class="name">
                  {{comment.user.firstName}} {{comment.user.lastName}}
                  <span>{{comment.userId === userId ? '(You)' : ''}}</span>
                  <svg-icon *ngIf="comment.userId === userId" class="icon-container" [class]="'icon'" [applyClass]="true"
                    src="../../../assets/images/icons/trash.svg" (click)="deleteComment(comment)">
                  </svg-icon>
                </p>
                <p class="date">{{comment.createdAt.toDate() | date}}</p>
              </div>
              <div class="content__comment">{{comment.content}}</div>
              <ng-container *ngIf="comment.rating">
                <app-rating-stars [pointsIn]="comment.rating" [onlyView]="true"></app-rating-stars>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="comments?.length === 0">
        No comments
      </ng-container>
    </div>

  </div>
</ng-container>  