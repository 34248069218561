<div class="comment-box">
  <div class="comment-box__top">
    <div class="comment-box__top__area">
      <textarea [disabled]="!actualUser" [placeholder]="actualUser ? 'Escribe tu reseña' : 'Debes iniciar sesion primero'"
        [(ngModel)]="comment"></textarea>
    </div>
    <div class="comment-box__top__rating">
      <div class="cont" *ngIf="alreadyPurchased && !ratedPerUser">
        <p class="title">Calificacion</p>
        <app-rating-stars (pointsChanged)="onRate($event)"></app-rating-stars>
      </div>
      <button [disabled]="ratedPerUser ? !comment : (!rating && !comment)" (click)="postComment()"
        class="btn">Postear</button>
    </div>
  </div>
  <div class="comment-box__bottom">
    <ng-container *ngIf="alreadyPurchased && ratedPerUser">
      <p>Ultimo rating:</p>
      <app-rating-stars (pointsChanged)="onRate($event)" [pointsIn]="ratedPerUser"></app-rating-stars>
      <button class="btn" (click)="onChangeOldRate()">Editar rating</button>
    </ng-container>
  </div>
</div>
