<div class="addresses-selector">
  <ng-container *ngIf="(creatingAddress || editingAddress) && countries">
    <button
      class="link"
      *ngIf="addresses?.length !== 0"
      (click)="creatingAddress = false; editingAddress = false"
    >
      ← Ir a todas las direcciones
    </button>
    <button
      class="link"
      *ngIf="addresses?.length === 0 && selected"
      (click)="selectAddress(selected)"
    >
      ← Volver
    </button>
    <form
      [formGroup]="addressForm"
      (ngSubmit)="creatingAddress ? createAddress() : editAddress()"
    >
      <div class="input-group-2">
        <div class="input-group">
          <label for="firstname">Nombre</label>
          <input type="text" id="firstname" formControlName="firstName" />
          <p
            *ngIf="
              addressForm.controls.firstName.touched &&
              (addressForm.controls.firstName.hasError('minlength') ||
                addressForm.controls.firstName.hasError('required'))
            "
            class="errorMessage"
          >
            Mínimo 2 carácteres
          </p>
        </div>
        <div class="input-group">
          <label for="lastname">Apellido</label>
          <input type="text" id="lastname" formControlName="lastName" />
          <p
            *ngIf="
              addressForm.controls.lastName.touched &&
              (addressForm.controls.lastName.hasError('minlength') ||
                addressForm.controls.lastName.hasError('required'))
            "
            class="errorMessage"
          >
            Mínimo 2 carácteres
          </p>
        </div>
      </div>

      <div class="input-group">
        <label for="addressOne">Dirección línea 1</label>
        <input type="text" id="addressOne" formControlName="addressOne" />
        <p
          *ngIf="
            addressForm.controls.addressOne.touched &&
            (addressForm.controls.addressOne.hasError('minlength') ||
              addressForm.controls.addressOne.hasError('required'))
          "
          class="errorMessage"
        >
          Campo obligatorio
        </p>
      </div>

      <div class="input-group">
        <label for="addressTwo">Dirección línea 2</label>
        <input type="text" id="addressTwo" formControlName="addressTwo" />
      </div>

      <div class="input-group-2">
        <div class="select-group">
          <label for="country">País</label>
          <div class="select-container">
            <svg-icon
              class="icon-container"
              [class]="'icon'"
              [applyClass]="true"
              src="../../../assets/images/icons/arrow-down.svg"
            >
            </svg-icon>
            <select id="country" formControlName="country">
              <option hidden selected value="">Selecciona tu país</option>
              <option *ngFor="let country of countries" [value]="country.name">
                {{ country.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="select-group">
          <label for="country">Estado</label>
          <div class="select-container">
            <svg-icon
              class="icon-container"
              [class]="'icon'"
              [applyClass]="true"
              src="../../../assets/images/icons/arrow-down.svg"
            >
            </svg-icon>
            <select id="state" formControlName="state">
              <option hidden selected value="">Selecciona tu Estado</option>
              <ng-container *ngFor="let state of selectedCountryStates">
                <option [value]="state.name">{{ state.name }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>

      <div class="input-group-2">
        <div class="select-group">
          <label for="country">Ciudad</label>
          <div class="select-container">
            <svg-icon
              class="icon-container"
              [class]="'icon'"
              [applyClass]="true"
              src="../../../assets/images/icons/arrow-down.svg"
            >
            </svg-icon>
            <select id="country" formControlName="city">
              <option hidden selected value="">Selecciona tu ciudad</option>
              <option *ngFor="let city of selectedStateCities" [value]="city">
                {{ city }}
              </option>
            </select>
          </div>
        </div>
        <div class="input-group">
          <label for="zipCode">Código postal</label>
          <input type="text" id="zipCode" formControlName="zipCode" />
          <p
            *ngIf="
              addressForm.controls.zipCode.touched &&
              addressForm.controls.zipCode.hasError('required')
            "
            class="errorMessage"
          >
            Campo obligatorio
          </p>
        </div>
      </div>

      <div class="input-group-2">
        <div class="input-group">
          <label for="phone">Teléfono celular</label>
          <input type="text" id="phone" formControlName="phone" />
          <p
            *ngIf="
              addressForm.controls.phone.touched &&
              addressForm.controls.phone.hasError('required')
            "
            class="errorMessage"
          >
            Campo obligatorio
          </p>
        </div>
        <div class="input-group">
          <label for="phoneLocal">Teléfono fijo (opcional)</label>
          <input type="text" id="phoneLocal" formControlName="phoneLocal" />
        </div>
      </div>

      <button class="btn" [disabled]="!addressForm.valid">
        {{ creatingAddress ? "Crear" : "Editar" }} Dirección
      </button>
    </form>
  </ng-container>

  <ng-container *ngIf="!creatingAddress && !editingAddress">
    <ng-container *ngFor="let address of addresses">
      <div
        class="addresses-selector__address"
        [ngClass]="{ selected: selected?.id === address.id, hover: selectable }"
        (click)="selectAddress(address)"
      >
        <div class="addresses-selector__address__data">
          <p>{{ address.firstName }} {{ address.lastName }}</p>
          <p>{{ address.addressOne }}</p>
          <p *ngIf="!selectable">{{ address.addressTwo }}</p>
          <p>{{ address.city || "" }}</p>
          <p>{{ address.state }}, {{ address.country }}</p>
          <p *ngIf="!selectable">{{ address.zipCode }}</p>
          <p *ngIf="!selectable">Teléfono: {{ address.phone }}</p>
          <p *ngIf="!selectable && address.phoneLocal">Teléfono local: {{ address.phoneLocal }}</p>
        </div>
        <div class="addresses-selector__address__actions" *ngIf="!selectable">
          <button class="btn" (click)="onEditAddress(address)">Editar</button>
          <button class="btn btn-outline" (click)="deleteAddress(address)">
            Eliminar
          </button>
        </div>
      </div>
    </ng-container>
    <button class="btn" (click)="creatingAddress = true">
      Nueva dirección
    </button>
  </ng-container>
</div>
