import { CommentExpand } from './../models/Comment';
import { Client } from './../models/Client';
import { switchMap, map, take } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { Comment } from '../models/Comment';
import { uniq } from 'lodash';
import * as firebase from 'firebase';


@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(
    private db: AngularFirestore
  ) { }

  getProductComments(productId: string): Observable<CommentExpand[]> {
    return this.db.doc(`products/${productId}`)
      .collection<Comment>('comments', ref => ref.orderBy('createdAt', 'desc'))
      .valueChanges({ idField: 'id' })
      .pipe(
        switchMap(dbComments => {
          const usersIds =
            uniq(dbComments.map(comment => comment.userId)).length === 0 ?
              ['null'] :
              uniq(dbComments.map(comment => comment.userId));

          return combineLatest([
            of(dbComments),
            combineLatest(
              [...usersIds.map(userId =>
                this.db.collection('clients').doc<Client>(userId).valueChanges())]
            ),
          ]);
        }),
        map(([comments, users]) => {
          return comments.map(comment => ({
            ...comment,
            user: (users as Client[]).find(user => user.id === comment.userId)
          }));
        })
      );

    // return of([
    //   {
    //     username: 'Carlos Fontes',
    //     content: 'My skin has always been soft. But now it feels like a babies bottom. I love it and the smell is pleasent and not strong',
    //     rating: 4.1,
    //     createdAt: new Date('11/12/2019')
    //   },
    //   {
    //     username: 'Victoria Lee',
    //     content: 'Great, non irritating eye cream. Better tan dermatologist product costing 10x more! Absorbs easy and leaves area soft to the touch. Need very little so should last a long time. When I saw the bags vanish and an age spot fade, I immediately bought a spare!',
    //     rating: 2.6,
    //     createdAt: new Date('2/6/2020')
    //   },
    //   {
    //     username: 'Pedro Montenegro',
    //     content: 'Great, non irritating eye cream. Better tan dermatologist product costing 10x more! Absorbs easy and leaves area soft to the touch. Need very little so should last a long time. When I saw the bags vanish and an age spot fade, I immediately bought a spare!',
    //     rating: 2.6,
    //     createdAt: new Date('2/6/2020')
    //   }
    // ]);
  }

  postComment(productId: string, comment: Comment): Promise<any> {
    const batch = this.db.firestore.batch();

    const commentId = this.db.createId();

    const commentDocRef = this.db.doc(`products/${productId}`).collection<Comment>('comments').doc(commentId).ref;

    batch.set(commentDocRef, comment);
    
    if (comment.rating) {
      const productDocRef = this.db.doc(`products/${productId}`).ref;
      batch.update(productDocRef, {
        timesRated: firebase.firestore.FieldValue.increment(1),
        ratingsSum: firebase.firestore.FieldValue.increment(comment.rating || 0),
      })
    }

    return batch.commit();
    // return this.db.doc(`products/${productId}`).collection<Comment>('comments').add(comment);
  }

  alreadyRated(productId: string, userId: string): Observable<number> {
    return this.db.doc(`products/${productId}`)
      .collection<Comment>('comments', ref => ref.where('userId', '==', userId))
      .valueChanges()
      .pipe(
        map(dbComments => {
          if (dbComments.find(comment => comment.rating)) {
            return dbComments.find(comment => comment.rating).rating;
          } else {
            return null;
          }
        })
      );
  }

  deleteComment(comment: CommentExpand, productId: string): Promise<any> {
    return this.db.doc(`products/${productId}`).collection('comments').doc(comment.id).delete();
  }

  async updateRating(productId: string, userId: string, newRating: number): Promise<any> {
    const comment = await this.db.doc(`products/${productId}`)
      .collection<Comment>('comments', ref => ref.where('userId', '==', userId))
      .valueChanges({ idField: 'id' })
      .pipe(
        map(dbComments => {
          if (dbComments.find(dbComment => dbComment.rating)) {
            return dbComments.find(dbComment => dbComment.rating);
          } else {
            return null;
          }
        }),
        take(1)
      ).toPromise();

    if (comment) {
      const commentRef = this.db.doc(`products/${productId}`).collection('comments').doc(comment.id);
      return commentRef.set({ rating: newRating }, { merge: true });
    }

    return null;
  }
}
