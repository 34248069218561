import { ZoomImageComponent } from './../../components/zoom-image/zoom-image.component';
import { OrderDetailsComponent } from './../../components/order-details/order-details.component';
import { AddressSelectorComponent } from './../../components/address-selector/address-selector.component';
import { AuthService } from './../../services/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommentBoxSenderComponent } from './../../components/comment-area/comment-box-sender/comment-box-sender.component';
import { RatingStarsComponent } from './../../components/rating-stars/rating-stars.component';
import { RouterModule } from '@angular/router';
import { CardCategoryComponent } from './../../components/cards/card-category/card-category.component';
import { CardProductComponent } from '../../components/cards/card-product/card-product.component';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { CommentAreaComponent } from 'src/app/components/comment-area/comment-area.component';
import { CartPurchasePanelComponent } from '../cart/cart-purchase-panel/cart-purchase-panel.component';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { StarsRatingPipe } from '../../pipes/stars-rating.pipe';
import { LanguagePipe } from 'src/app/pipes/language.pipe';
import { DollarToCurrencyPipe } from 'src/app/pipes/dollar-to-currency.pipe';
import { FormatAmountPipe } from 'src/app/pipes/format-amount.pipe';

import localeDE from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { DiscountPipe } from '../../pipes/discount.pipe';
import { SafePipe } from 'src/app/pipes/safe.pipe';

registerLocaleData(localeDE);

@NgModule({
  declarations: [
    CardProductComponent,
    CardCategoryComponent,
    RatingStarsComponent,
    CommentAreaComponent,
    CommentBoxSenderComponent,
    CartPurchasePanelComponent,
    AddressSelectorComponent,
    OrderDetailsComponent,
    ZoomImageComponent,
    StarsRatingPipe,
    LanguagePipe,
    DollarToCurrencyPipe,
    FormatAmountPipe,
    DiscountPipe,
    SafePipe,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AngularSvgIconModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    AngularSvgIconModule,
    CardProductComponent,
    CardCategoryComponent,
    RatingStarsComponent,
    CommentAreaComponent,
    CommentBoxSenderComponent,
    FormsModule,
    ReactiveFormsModule,
    CartPurchasePanelComponent,
    AddressSelectorComponent,
    OrderDetailsComponent,
    ZoomImageComponent,
    MatCardModule,
    MatGridListModule,
    StarsRatingPipe,
    LanguagePipe,
    DollarToCurrencyPipe,
    FormatAmountPipe,
    DiscountPipe,
    SafePipe,
  ],
  providers: [
    AuthService, 
    StarsRatingPipe, 
    CurrencyPipe,
    { provide: 'de', useValue: 'de' },
    DiscountPipe,
  ],
})
export class SharedModule {}
