<div class="navbar">
  <div class="navbar__info-box container">
    <div class="navbar__info-box__social">
      <ng-container *ngIf="headerContent">
        <!-- <pre>{{headerContent | json}}</pre> -->
          <ng-container *ngFor="let link of headerContent.links">
                <!-- INTERNAL LINK -->
            <a *ngIf="!link.external" class="link" [routerLink]="link.url" >
              <ng-container [ngTemplateOutlet]="linkContent" [ngTemplateOutletContext]="{'link': link}"></ng-container>
            </a>

            <!-- EXTERNAL LINK -->
            <a *ngIf="link.external" class="link" [href]="link.url" target="blank">
              <ng-container [ngTemplateOutlet]="linkContent" [ngTemplateOutletContext]="{'link': link}"></ng-container>
            </a>
          </ng-container>
      </ng-container>
    </div>

    <div class="navbar__info-box__settings">
      <!-- <a [routerLink]="['info', 'delivery-policy']" class="toHide">Politica de envios</a> -->
      <!-- <a *ngIf="headerContent && helpLink" class="link" [href]="helpLink.url" target="blank">
        <ng-container [ngTemplateOutlet]="linkContent" [ngTemplateOutletContext]="{'link': helpLink}"></ng-container>
      </a> -->
      <!-- <div>
        <app-dropdown-actions [title]="'USD'" [actions]="currencyActions"></app-dropdown-actions>
      </div>
      <div>
        <app-dropdown-actions [title]="'Lenguaje'" [actions]="languageActions"></app-dropdown-actions>
      </div> -->
    </div>
  </div>
  <header class="navbar__panel-box container">
    <img [routerLink]="['/home']" class="logo" src="../../../assets/images/pcManiaLogo.svg" alt="PC Mania" />
    <div class="separator"></div>
    <form class="navbar__panel-box__search-box" (ngSubmit)="searchProducts()">
      <label for="search"></label>
      <input id="search" class="search" type="search" placeholder="Búsqueda" name="search-text"
        [(ngModel)]="searchText" />
      <div 
        class="category" 
        [ngClass]="{ selected: selectedCategory !== undefined }"
        (click)="setCategoriesDropdown(!showCategoriesDropdown, $event)"
        appClickOutside
        (clickOutside)="onClickedOutside()"
      >
        <input class="category-selection-aux-input" readonly [value]="selectedCategory?.name || 'Todas las categorías'" />
        <div 
          class="categories-dropdown" 
          
          *ngIf="showCategoriesDropdown"
          [ngClass]="{ show: showCategoriesDropdown }"
            
        >
          <div 
            class="category-item-container"
            (click)="selectCategory(undefined); setCategoriesDropdown(false, $event)"
          >
            <p class="category-item"
              [ngClass]="{ selected: selectedCategory === category }">
              Todas las categorías
            </p>
          </div>
          <ng-container *ngFor="let category of categories">
            <div 
              class="category-item-container"
              (mousedown)="handleCategorySelection(categories, 'search_bar_categories', 'Search bar categories')"
              (click)="selectCategory(category); setCategoriesDropdown(false, $event)"
            >
              <p class="category-item"
                [ngClass]="{ selected: selectedCategory === category }">
                {{ category.name }}
              </p>
            </div>
          </ng-container>
        </div>
        <img 
          class="title arrow" src="../../../assets/icons/up-and-down-arrows.svg" 
        >
        <div class="overlay" *ngIf="showCategoriesDropdown" (click)="showCategoriesDropdown = false"></div>
      </div>
      <button [disabled]="!searchText && !selectedCategory" (click)="searchProducts()" type="submit">
        <svg-icon [class]="'icon'" [applyClass]="true" src="../../../assets/images/icons/search.svg"></svg-icon>
      </button>
    </form>
    <div class="navbar__panel-box__user-panel">
      <div class="navbar__panel-box__user-panel__buttons">
        <div class="icon-circle-button" [routerLink]="['/cart']">
          <svg-icon [class]="'image'" [applyClass]="true" src="../../../assets/images/icons/cart.svg">
          </svg-icon>
          <span class="badge" [ngClass]="{show: quantityOnCart > 0}">{{quantityOnCart}}</span>
        </div>
        <div *ngIf="user" class="icon-circle-button">
          <svg-icon [class]="'image'" [applyClass]="true" src="../../../assets/images/icons/user.svg">
          </svg-icon>
        </div>
      </div>
      <div class="info-user">
        <p class="name" *ngIf="user">
          {{user.firstName + ' ' + user.lastName}}
        </p>
        <a class="name" [routerLink]="['/welcome']" *ngIf="user === null">
          Iniciar <br> Registrarse
        </a>
        <ng-container >
          <app-dropdown-actions [title]="'Mi Cuenta'" [actions]="myAccountActions" *ngIf="user"></app-dropdown-actions>
        </ng-container>
      </div>
    </div>
  </header>
  <div class="navbar__categories-box container" *ngIf="!inCategories">
    <div class="fade-container" [ngClass]="{'hidden': !showCategoriesFade}"></div>
    <div class="navbar__categories-box__categories" (click)="showCategoriesDrawer = true; handleDrawerActive()" style="margin-right: 0;">
      <svg-icon [class]="'icon'" [applyClass]="true" src="../../../assets/images/icons/menu.svg"></svg-icon>
    </div>
    <nav class="navbar__categories-box__all-categories" #allCategoriesElement>
      <!-- <div class="fade-container"></div> -->
      <ul>
        <li class="category-nav-bubble">
          <a class="category-nav-bubble__anchor" [routerLink]="['/categories']">
            <div class="category-nav-bubble__icon-container">
              <img [src]="CATEGORIES_ICONS_DICT['categories']" alt="Categorías" />
            </div>
            <p class="title">Categorías</p>
          </a>
        </li>

        <li class="category-nav-bubble" *ngFor="let category of categories">
          <a
            class="category-nav-bubble__anchor"
            [routerLink]="['/category', category.slug]"
            (mousedown)="handleCategorySelection(categories, 'navbar_categories', 'Navbar categories')"
          > 
            <ng-container *ngIf="category.active">
              <div class="category-nav-bubble__icon-container">
                <img [src]="CATEGORIES_ICONS_DICT[category.iconName]" [alt]="category.name" />
              </div>
              <p class="title">{{ category.name }}</p>
            </ng-container>
            
          </a>
        </li>
      </ul>
            
          
      
    </nav>
    
    
  </div>
</div>

<div class="drawer-categories" [ngClass]="{'show': showCategoriesDrawer}">
  <!-- <div class="drawer-categories__user">
    Hi, <span>Carlos Fontes</span>
  </div> -->
  <ng-container *ngIf="loadedCategories">
    <div class="drawer-categories__categories">
      <ng-container *ngFor="let category of categories; let i = index">
        <div 
          class="drawer-categories__categories__category"
          (mousedown)="handleCategorySelection(categories, 'drawer_categories', 'Drawer categories')"
        >
          <label [for]="'dropdown' + i" (click)="navigateToProductListing(category.slug, null)">{{category.name}}</label>
          <input type="checkbox" [id]="'dropdown' + i" aria-hidden="true" hidden>
          <div class="drawer-categories__categories__category__subcategories">
            <ng-container *ngFor="let subcategory of category.subcategories">
              <p class="subcategory" (click)="navigateToProductListing(category.id, subcategory.id)">
                {{subcategory.name}}</p>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

  </ng-container>
  <ng-container *ngIf="!loadedCategories">
    <div class="sk-folding-cube">
      <div class="sk-cube1 sk-cube"></div>
      <div class="sk-cube2 sk-cube"></div>
      <div class="sk-cube4 sk-cube"></div>
      <div class="sk-cube3 sk-cube"></div>
    </div>
  </ng-container>
</div>
<div class="overlay" [ngClass]="{'show': showCategoriesDrawer}" (click)="showCategoriesDrawer = false"></div>

<ng-template #linkContent let-link="link">
  <svg-icon 
    *ngIf="link.iconUrl"
    [class]="'icon'" 
    [applyClass]="true" 
    [src]="link.iconUrl"
  >
  </svg-icon>
</ng-template>