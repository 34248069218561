import { AngularFirestore } from '@angular/fire/firestore';
import { functions } from 'firebase/app';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HomeBanner } from './../models/Home-Banner';
import { ContentSectionId } from '../models/General';
@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private db: AngularFirestore
  ) { }

  contentCollection = this.db.collection("content");

  async getContent<AnyContentDoc>(contentSectionId: string): Promise<AnyContentDoc> {
    try {
      const contentDocSnap = await this.contentCollection.doc(contentSectionId).ref.get();
      return Promise.resolve(contentDocSnap.data() as AnyContentDoc);
    } catch (error) {
      return Promise.reject(null);
    }
  }

  getHomeBannerInfo(): Observable<HomeBanner> {
    return this.db.collection('content').doc<HomeBanner>('home-banner').valueChanges();
  }

  sendEmailContactUs(data: { name: string, email: string, message: string}): Promise<any> {
    const callable = functions().httpsCallable('sendEmailContactUs');
    return callable(data);
  }
}
