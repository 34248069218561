import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../models/Currency';

@Pipe({
  name: 'dollarToCurrency',
})
export class DollarToCurrencyPipe implements PipeTransform {

  transform(amoutDollars: number, currency: Currency): number {
    return parseFloat((amoutDollars * currency.oneDollar).toFixed(2));
  }

}
