
<main class="container all-categories-component">
    <nav *ngIf="categories">
        <ul class="all-categories-grid">
            <ng-container *ngFor="let category of categories">
                <!-- <app-card-category [type]="'category'" [category]="category"></app-card-category> -->
                <li>
                    <a
                        class="category-home-bubble for-categories-view"
                        [routerLink]="['/category', category.slug]"
                    >
                        <div 
                            class="category-home-bubble__icon-container" 
                            [style]="'background-color: ' + (category.backgroundColor || '#e1e4e8')"
                        >
                            <img [ngClass]="{'white': category.iconColor === 'white'}" *ngIf="category.iconName" [src]="CATEGORIES_ICONS_DICT[category.iconName]" [alt]="category.name">
                        </div>
                        <p class="title">{{ category.name }}</p>
                    </a>
                </li>
            </ng-container>
        </ul>
    </nav>
</main>