import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Offer, ProductsInOffer } from '../models/Offer';

@Injectable({
  providedIn: 'root',
})
export class OffersService {

  private activeOffers$ = this.db
    .collection<Offer>("offers", (ref) => ref.where("active", "==", true))
    .valueChanges({ idField: "id" });

  static productsInOffer: ProductsInOffer;

  static productsInOffer$ = new BehaviorSubject<ProductsInOffer>({});

  constructor(
    private db: AngularFirestore,
  ) {
    if (!OffersService.productsInOffer) {
      this.activeOffers$.subscribe((offers) => {
        const _productsInOffer: ProductsInOffer = {};
        
        for (const offer of offers) {

          if (!_productsInOffer[offer.productId]) {
            _productsInOffer[offer.productId] = 0;
          }

          _productsInOffer[offer.productId] += offer.percentage;
        }
  
        OffersService.productsInOffer$.next(_productsInOffer);
        OffersService.productsInOffer = _productsInOffer;
      });
    }
  }

  getActiveOffers(): Observable<Offer[]> {
    return this.activeOffers$;
  }

  getAllOffers(): Observable<Offer[]> {
    return this.db.collection<Offer>('offers').valueChanges({ idField: 'id' });
  }

  getOfferById(offerId: string): Observable<Offer> {
    return this.db
      .collection<Offer>(`offers`)
      .doc(offerId)
      .valueChanges()
      .pipe(
        map((dbOffer: Offer) => {
          return dbOffer;
        })
      );
  }
}
