import { Coupon } from './Coupon';
import { Address } from './Address';
import { CartOrder, CartOrderExpand } from './Cart';
import { PaymentMethods } from './Payment';
import { Currency, CurrenciesDictionary } from './Currency';
import { Translated } from './Translated';
import { ShippingMethod, ShippingMethodType } from './Shipping';

export interface RatesSnapshot {
  list: Currency[];
  dict: CurrenciesDictionary;
}

export type OrderStatus = 'CANCELLED' | 'IN-PROGRESS' | 'PENDING' | 'PROCESSING' | 'SHIPPED' | 'FULFILLED';

export type OrderPaymentStatus = 'NO-PAY' | 'PENDING' | 'APPROVED' | 'REJECTED';

export const orderStatusesTranslations
  : { [status in OrderStatus]: Translated }
  = {
    "CANCELLED": {
      en: "Cancelled",
      es: "Cancelada"
    },
    "IN-PROGRESS": {
      en: "In progress",
      es: "En progreso"
    },
    "PENDING": {
      en: "Pending",
      es: "Pendiente"
    },
    "PROCESSING": {
      en: "Processing",
      es: "Procesando"
    },
    "SHIPPED": {
      en: "Shipped",
      es: "Enviada"
    },
    "FULFILLED": {
      en: "Fulfilled",
      es: "Completada"
    },
  }

export const orderPaymentStatusesTranslations
  : { [status in OrderPaymentStatus]: Translated }
  = {
    "NO-PAY": {
      en: "Not paid",
      es: "Sin pagar"
    },
    "PENDING": {
      en: "Pending",
      es: "Pendiente"
    },
    "APPROVED": {
      en: "Approved",
      es: "Aprobado"
    },
    "REJECTED": {
      en: "Rejected",
      es: "Rechazada"
    },
  }

export interface Order {
  id?: string;
  clientId?: string;
  orderNumber: number;
  createdAt: any;
  payedAt?: any;
  cart: CartOrder;
  discountPrice: number;
  usedCoupon?: Coupon;
  offersDiscountPrice?: number;
  deliveryPrice: number;
  orderStatus: OrderStatus;
  paymentStatus: OrderPaymentStatus;
  // Field in other steps
  billingAddress?: Address;
  shippingAddress?: Address;
  shippingType?: ShippingMethodType;
  totalPrice?: number;
  taxesPrice?: number;
  paymentMethod?: PaymentMethods;
  ratesSnapshot: RatesSnapshot;
  shippingMethod?: ShippingMethod;
  appliedOffersIds?: string[];
  clientCi?: number;
  surveyEmailSent?: boolean;
  shippingCompany?: string;
}

export interface OrderExpand {
  id?: string;
  clientId?: string;
  orderNumber: number;
  createdAt: any;
  payedAt?: any;
  cart: CartOrderExpand;
  discountPrice: number;
  offersDiscountPrice?: number;
  usedCoupon?: Coupon;
  deliveryPrice: number;
  orderStatus: OrderStatus;
  paymentStatus: OrderPaymentStatus;
  // Field in other steps
  billingAddress?: Address;
  shippingAddress?: Address;
  shippingType?: ShippingMethodType;
  totalPrice?: number;
  taxesPrice?: number;
  paymentMethod?: PaymentMethods;
  ratesSnapshot: RatesSnapshot;
  shippingMethod?: ShippingMethod;
  appliedOffersIds?: string[];
  clientCi?: number;
  surveyEmailSent?: boolean;
  shippingCompany?: string;
}


