import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';

export interface DropdownAction {
  name: string;
  route?: string;
  function?: () => void;
}

@Component({
  selector: 'app-dropdown-actions',
  templateUrl: './dropdown-actions.component.html',
  styleUrls: ['./dropdown-actions.component.scss']
})
export class DropdownActionsComponent implements OnInit {
  @Input() title: string;
  @Input() actions: DropdownAction[];
  showActions: boolean;

  constructor(
    private router: Router,
    private analyticsService: AnalyticsService,
  ) { }

  ngOnInit(): void {
  }

  executeEvent(action: DropdownAction): void {
    if (action.route) {
      this.router.navigate([action.route]);
    } else if (action.function) {
      action.function();
    }
  }

  handleActionMouseDown(actions: DropdownAction[], item_list_id: string, item_list_name: string) {
    const items = actions.map((action, index) => ({ item_id: action.route || "", item_name: action.name, index }) );
    this.analyticsService.triggerSelectItemEvent(items, item_list_id, item_list_name);
  }

}
