import { AuthGuard } from './guards/auth.guard';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { NoAuthGuard } from './guards/no-auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavigationComponent } from './navigation/navigation.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProductDetailsComponent } from './modules/product-details/product-details.component';
import { AllCategoriesComponent } from './modules/all-categories/all-categories/all-categories.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: NavigationComponent,
    children: [
      {
        path: 'welcome',
        loadChildren: () =>
          import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule),
        canActivate: [NoAuthGuard]
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      // {
      //   path: 'category',
      //   loadChildren: () =>
      //     import('./modules/category/category.module').then(
      //       (m) => m.CategoryModule
      //     ),
      // },
      {
        path: 'products',
        loadChildren: () =>
          import('./modules/product-listing/product-listing.module').then(
            (m) => m.ProductListingModule
          ),
      },
      {
        path: 'category/:cat_slug',
        data: { forCategory: true },
        loadChildren: () =>
          import('./modules/product-listing/product-listing.module').then(
            (m) => m.ProductListingModule
          ),
      },
      {
        path: 'p/:id',
        data: { byId: true },
        component: ProductDetailsComponent,
      },
      {
        path: 'product/:slug',
        component: ProductDetailsComponent,
      },
      {
        path: 'cart',
        loadChildren: () =>
          import('./modules/cart/cart.module').then(
            (m) => m.CartModule
          ),
      },
      {
        path: 'create-order',
        loadChildren: () =>
          import('./modules/create-order/create-order.module').then(
            (m) => m.CreateOrderModule
          ),
        canActivate: [AuthGuard]
      },
      {
        path: 'order-details',
        component: OrderDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'order-details/:id',
        component: OrderDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./modules/orders/orders.module').then(
            (m) => m.OrdersModule
          ),
        canActivate: [AuthGuard]
      },
      {
        path: 'info',
        loadChildren: () =>
          import('./modules/info/info.module').then(
            (m) => m.InfoModule
          ),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./modules/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
        canActivate: [AuthGuard]
      },
      {
        path: 'categories',
        component: AllCategoriesComponent
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
