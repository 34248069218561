import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.scss']
})
export class RatingStarsComponent implements OnInit, OnChanges {
  @Input() pointsIn: number;
  @Input() onlyView: boolean;
  @Input() cantReviews: number;
  @Input() disabled: boolean;
  @Output() pointsChanged = new EventEmitter();
  actualPoints: number;
  starHovered: number;
  hovered: boolean;
  zone: string;
  // Images path
  starFillPath: string;
  starNoFillPath: string;
  starHalfPath: string;

  constructor() { }

  ngOnInit(): void {
    this.starFillPath = '../../../assets/images/icons/full-star.svg';
    this.starNoFillPath = '../../../assets/images/icons/empty-star.svg';
    this.starHalfPath = '../../../assets/images/icons/half-star.svg';

    this.hovered = false;
    this.starHovered = -1;
    if (!this.pointsIn) {
      this.pointsIn = 0;
    }
    this.actualPoints = this.pointsIn;
  }

  ngOnChanges(): void {
    if (!this.pointsIn) {
      this.pointsIn = 0;
    }
    this.actualPoints = this.pointsIn;
  }

  rated(points: number): void {
    if (this.onlyView || this.disabled) {
      return;
    }
    this.pointsChanged.emit(points);
    this.actualPoints = points;
  }

  onHover(hovered: boolean, starHovered: number): void {
    if (this.disabled) {
      return;
    }
    this.hovered = this.onlyView ? false : hovered;
    this.starHovered = starHovered;
  }

}
