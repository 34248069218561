import { Client } from './../../models/Client';
import { AuthService } from './../../services/auth.service';
import { CartService } from './../../services/cart.service';
import { LoaderService } from './../../services/loader.service';
import { ProductsService } from './../../services/products.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject, combineLatest, of, pipe } from 'rxjs';
import { Product } from 'src/app/models/Product';
import { uniq } from 'lodash';
import { AnalyticsService } from '../../services/analytics.service';
import { noImageUrl } from 'src/app/constants/images.constant';
import { OffersService } from 'src/app/services/offers.service';
import { getRandomElementsFromArray } from "../../utils/arrays";
import { ProductsInOffer } from 'src/app/models/Offer';
import { ProductBreadcrumb, ProductBreadcrumbItem } from "../../models/Breadcrumb";
import { CategoriesService } from '../../services/categories.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();
  private destroyedCheckQuantity$ = new Subject<boolean>();
  Arr = Array;

  productId: string;
  productSlug: string;
  product: Product;
  actualUser: Client;
  quantityOnCart: number;
  maxQuantityToAdd: number;
  selectedQuantity: number;
  _allImagesPaths: string[];
  _currentImagePath: string;
  productPrice: number;
  productQuantity: number;
  productMaxPerOrder: number;
  hasVariations: boolean;
  selectedVariationPath: string[]; // ['s', 'blue', 'silk']
  availableVariations: string[][]; // [['s', 'blue', 'silk'], ['S', 'red', 'leather]
  lastSelectedVariation: { idxVariation: number; option: string }; // {idxVariation: 1 , option: 's'}

  noImageUrl = noImageUrl;
  noImageZoom = false;

  get allImagesPaths() {
    return this._allImagesPaths;
  }
  set allImagesPaths(value: string[]) {
    if (!value?.length) {
      this._allImagesPaths = [noImageUrl];
    } else {
      this._allImagesPaths = value;
    }
  }

  get currentImagePath() {
    return this._currentImagePath;
  }
  set currentImagePath(value: string) {
    if (!value) {
      this._currentImagePath = noImageUrl;
      this.noImageZoom = true;
    } else {
      this._currentImagePath = value
      this.noImageZoom = false;
    }
  }

  discountPercentage: number;

  relatedProducts: Product[];

  productsInOffer: ProductsInOffer = {};

  productBreadcrumb: ProductBreadcrumb;

  constructor(
    private activatedRoute: ActivatedRoute,
    private productsService: ProductsService,
    private loaderService: LoaderService,
    private cartService: CartService,
    private authService: AuthService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private offersService: OffersService,
    private categoriesService: CategoriesService,
    private meta: Meta,
    private title: Title,
  ) {}

  private async generateProductBreadcrumb(
    categoryId: string, 
    subcategoryId: string, 
    productName: string
  ): Promise<ProductBreadcrumb | null> {
    const categoryPromise = this.categoriesService.getCategoryById(categoryId).pipe(take(1)).toPromise();
    const subcategoryPromise = 
      subcategoryId ? 
        this.categoriesService.getSubcategoryById(categoryId, subcategoryId).pipe(take(1)).toPromise() 
        : 
        Promise.resolve(null);
    
    try {      
      const [
        category,
        subcategory,
      ] = await Promise.all([categoryPromise, subcategoryPromise]);

      const breadcrumbItems: ProductBreadcrumbItem[] = [];

      breadcrumbItems.push({
        label: category.name,
        categoryId,
      });

      if (subcategory) {
        breadcrumbItems.push({
          label: subcategory.name,
          categoryId,
          subcategoryId,
        });
      }

      breadcrumbItems.push({
        label: productName,
        last: true,
      });

      return { items: breadcrumbItems };

    } catch (error) {
      console.log(error)

      return null;
    }

  }

  private async fetchRelatedProducts(product: Product): Promise<Product[]> {

    try {
      let products = 
        await this.productsService.getProductsByCategoryId(product.categoryId, "server")
          .pipe(take(1))
          .toPromise();

        products = products.filter((prod) => prod.id !== product.id);
      
      if (products.length >= 10) {
        const relatedProducts: Product[] = getRandomElementsFromArray<Product>(products, 4);
        return Promise.resolve(relatedProducts);
      } else {
        return Promise.resolve(products.slice(0, 4));
      }
      
    } catch (error) {
      return Promise.resolve([]);
    }

  }

  private stripHTMLTagsFromDescription(desc: string = ""): string {
    const divEl = document.createElement("div");
    divEl.innerHTML = desc;
    return divEl.textContent || divEl.innerText || "";
  }

  ngOnInit() {
    this.loaderService.showLoader();
    
    const getById = !!this.activatedRoute.snapshot.data.byId;

    this.activatedRoute.params
      .pipe(
        switchMap((param) => {
          if (getById) {
            this.productId = param.id;
          } else {
            this.productSlug = param.slug;
          }
          return combineLatest([
            this.authService.authUser$,
            getById ? 
              this.productsService.getProductById(this.productId)
              :
              this.productsService.getProductBySlugWithComments(this.productSlug),
            OffersService.productsInOffer$,
          ]);
        }),
        switchMap(([user, product, productsInOffer]) => {
          this.title.setTitle(`${product.name} - PC Mania`);
          this.meta.removeTag("name='description'");
          this.meta.addTags([
            {name: 'description', content: this.stripHTMLTagsFromDescription(product.description) || "PC Mania - Accesorios y equipos de computación" },
            {name: 'keywords', content: `PC Mania, accesorios, equipos, computación, ${product.name.split(" ").join(",")}`}
          ]);
          this.productId = product.id!;
          this.productSlug = product.slug;
          if (product.id !== this.product?.id) {
            this.fetchRelatedProducts(product)
              .then((products) => {
                this.relatedProducts = products;
              });

            this.generateProductBreadcrumb(product.categoryId, product.subcategoryId, product.name)
              .then((breadcrumb) => {
                this.productBreadcrumb = breadcrumb;
                this.loaderService.hideLoader();
              });
          }

          this.product = product;
          this.actualUser = user;
          
          if (!product) {
            this.router.navigate(['/not-found']);
          }

          this.productsInOffer = productsInOffer;

          if (product) {
            this.discountPercentage = productsInOffer[product.id];
          }

          // Si el producto tiene variaciones
          if (product.variationsComb) {
            const combs = product.variationsComb
              .sort((a, b) => a.price - b.price)
              .filter(
                (comb) => comb.maxPerOrder && comb.price && comb.quantity
              );
            const selectedVariation = combs.find((comb) => comb.quantity > 0);

            this.hasVariations = true;
            this.availableVariations = combs
              .filter((comb) => comb.quantity > 0)
              .map((comb) => {
                return comb.path.split('/');
              });
            this.availableVariations = this.sortByWeight(
              this.availableVariations
            );
            this.selectedVariationPath = selectedVariation?.path.split('/');
            this.lastSelectedVariation = {
              idxVariation: 0,
              option: this.selectedVariationPath[0],
            };

            // console.log(this.availableVariations);
            // console.log(this.selectedVariationPath);

            this.currentImagePath =
              selectedVariation?.imagesUrl[0] || product.imagesUrl[0];
            this.allImagesPaths =
              selectedVariation?.imagesUrl || product.imagesUrl;
            this.productPrice = selectedVariation?.price || product.price;
            this.productQuantity =
              selectedVariation?.quantity || product.quantity;
            this.productMaxPerOrder =
              selectedVariation?.maxPerOrder || product.maxPerOrder;

            this.checkQuantityOnCartOnVariations();

            if (!this.currentImagePath) {
              this.currentImagePath = noImageUrl;
              this.noImageZoom = true;
            }
            if (!this.allImagesPaths.length) {
              this.allImagesPaths = [noImageUrl];
            }

            return of(null);
          } else {
            this.currentImagePath = product.imagesUrl && product.imagesUrl[0];
            this.allImagesPaths = product.imagesUrl;
            this.productPrice = product.price;
            this.productQuantity = product.quantity;
            this.productMaxPerOrder = product.maxPerOrder;

            if (!this.currentImagePath) {
              this.currentImagePath = noImageUrl;
              this.noImageZoom = true;
            }
            if (!this.allImagesPaths.length) {
              this.allImagesPaths = [noImageUrl];
            }

            return this.cartService.getQuantityOfProductInCart(
              this.productId,
              user ? user.id : null
            );
          }
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((quantityOnCart) => {
        if (quantityOnCart !== null) {
          this.quantityOnCart = quantityOnCart;
          this.maxQuantityToAdd =
            this.productMaxPerOrder <= this.productQuantity
              ? this.productMaxPerOrder - quantityOnCart
              : this.productQuantity - quantityOnCart;
          this.selectedQuantity = 1;
        }
      });
  }

  ngOnDestroy(): void {
    this.title.setTitle("PC Mania");
    this.meta.removeTag("name='description'");
    this.meta.removeTag("name='keywords'");
    this.meta.addTags([
      {name: 'description', content: "PC Mania - Accesorios y equipos de computación" },
      {name: 'keywords', content: 'PC Mania, accesorios, equipos, computación'}
    ]);
    this.destroyed$.next(true);
  }

  handleImageError(e) {
    e.target.src = this.noImageUrl;
  }

  checkQuantityOnCartOnVariations(): void {
    this.destroyedCheckQuantity$.next(true);

    this.cartService
      .getQuantityOfProductInCart(
        this.productId,
        this.actualUser ? this.actualUser.id : null,
        this.selectedVariationPath.join('/')
      )
      .pipe(takeUntil(this.destroyedCheckQuantity$))
      .subscribe((quantityOnCart) => {
        this.quantityOnCart = quantityOnCart;
        this.maxQuantityToAdd =
          this.productMaxPerOrder <= this.productQuantity
            ? this.productMaxPerOrder - quantityOnCart
            : this.productQuantity - quantityOnCart;

        this.maxQuantityToAdd =
          this.maxQuantityToAdd > 20 ? 20 : this.maxQuantityToAdd;
        this.selectedQuantity = 1;
      });
  }

  addToCart(): void {
    const max =
      (this.productMaxPerOrder || undefined) <= this.productQuantity
        ? this.productMaxPerOrder
        : this.productQuantity;
    this.cartService.changeQuantityOfProductOnCart(
      {
        productId: this.product.id,
        quantity: this.selectedQuantity,
        variationPath: this.selectedVariationPath?.join('/'),
      },
      max
    );

    this.analyticsService.triggerAddToCartEvent(this.product);
  }

  capitalizeString(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  /**
   * Description: Verify if is some variation available in all available variations
   * @author Carlos Fontes
   */
  someVariationAvailableWithAllOptions(
    option: string,
    idxVariation: number
  ): boolean {
    const allAvailableOptions = uniq(
      this.availableVariations.map((variation) => variation[idxVariation])
    ) as [];
    return allAvailableOptions.find((variation) => variation === option);
  }

  /**
   * Description: Verify if is some variation available with the last selected variation
   * @author Carlos Fontes
   */
  someVariationWithLastSelectedOption(
    option: string,
    idxVariation: number
  ): boolean {
    if (idxVariation === this.lastSelectedVariation.idxVariation) {
      return true;
    }

    const availableCombsBasedOnLastSelected = [
      ...this.availableVariations.filter((comb) => {
        return (
          comb[this.lastSelectedVariation.idxVariation] ===
          this.lastSelectedVariation.option
        );
      }),
    ];

    return !!availableCombsBasedOnLastSelected.find(
      (variation) => variation[idxVariation] === option
    );
  }

  /**
   * Description: Log Out the actual user
   * @author Carlos Fontes
   */
  changeVariation(
    option: string,
    idxVariation: number,
    disabled: boolean
  ): void {
    // Si no está disabled y no está seleccionada
    if (!disabled && this.selectedVariationPath[idxVariation] !== option) {
      this.lastSelectedVariation = { idxVariation, option };
      // Se filtran solo los path que contengan el path seleccionado
      const availableCombsBasedOnSelected = [
        ...this.availableVariations.filter(
          (comb) => comb[idxVariation] === option
        ),
      ];

      // Si solo hay una, seleccionar esa
      if (availableCombsBasedOnSelected.length === 1) {
        // Se actualizan variables de acuerdo a la nueva selección
        this.selectedVariationPath = availableCombsBasedOnSelected[0];
        const selectedVarPathStringOne = this.selectedVariationPath.join('/');
        const selectedVariationOne = this.product.variationsComb.find(
          (comb) => comb.path === selectedVarPathStringOne
        );
        this.currentImagePath =
          selectedVariationOne?.imagesUrl[0] || this.product.imagesUrl[0];
        this.allImagesPaths =
          selectedVariationOne?.imagesUrl || this.product.imagesUrl;
        this.productPrice = selectedVariationOne?.price || this.product.price;
        this.productQuantity =
          selectedVariationOne?.quantity || this.product.quantity;
        this.productMaxPerOrder =
          selectedVariationOne?.maxPerOrder || this.product.maxPerOrder;
        this.checkQuantityOnCartOnVariations();
        return;
      }

      // Calculamos cual de las opciones disponibles hace que más opciones ya seleccionadas no cambien:
      // Si está seleccionado S/Red/Leather y se selecciona M, y para M hay las opciones de M/Red/Leather y M/Red/Silk, se
      // selecciona M/Red/Leather porque es la que más en común tiene con la anteriormente seleccionada. Los puntos son cuantas
      // veces se repite y se agarra el primero que se encuentre mayor, porque ya el array está ordenado de manera jerárquica
      let points = -1;
      let combinationWithMorePoints = null;
      availableCombsBasedOnSelected.map((comb) => {
        const pointsCalc = comb.reduce(
          (sum, val, j) =>
            sum + (this.selectedVariationPath[j] === val ? 1 : 0),
          0
        );

        if (pointsCalc > points) {
          points = pointsCalc;
          combinationWithMorePoints = comb;
        }
      });

      // Se actualizan variables de acuerdo a la nueva selección
      this.selectedVariationPath = combinationWithMorePoints;
      const selectedVarPathString = this.selectedVariationPath.join('/');
      const selectedVariation = this.product.variationsComb.find(
        (comb) => comb.path === selectedVarPathString
      );
      this.currentImagePath =
        selectedVariation?.imagesUrl[0] || this.product.imagesUrl[0];
      this.allImagesPaths =
        selectedVariation?.imagesUrl || this.product.imagesUrl;
      this.productPrice = selectedVariation?.price || this.product.price;
      this.productQuantity =
        selectedVariation?.quantity || this.product.quantity;
      this.productMaxPerOrder =
        selectedVariation?.maxPerOrder || this.product.maxPerOrder;
      this.checkQuantityOnCartOnVariations();
    }
  }

  /**
   * Description: Groups the array of variations from last variation to first
   * @author Carlos Fontes
   */
  sortByWeight(combs: string[][]): string[][] {
    const combSorted = [...combs] as any[][];

    // Transform to numbers
    for (let i = 0; i < combSorted.length; i++) {
      combSorted[i] = combSorted[i].map((opt, idx) =>
        this.product.variations[idx].options.findIndex((op) => op === opt)
      );
    }

    // Sort
    for (let i = this.product.variations.length - 1; i >= 0; i--) {
      combSorted.sort((a, b) => (a[i] < b[i] ? -1 : a[i] > b[i] ? 1 : 0));
    }

    // Transform to strings
    for (let i = 0; i < combSorted.length; i++) {
      combSorted[i] = combSorted[i].map(
        (opt, idx) => this.product.variations[idx].options[opt]
      );
    }

    return combSorted;
  }
}
