import { Injectable } from '@angular/core';
import { Product } from '../models/Product';
import { GA4Events } from "../models/Analytics";
import { CategoriesService } from './categories.service';
import { take } from 'rxjs/operators';
import { Address } from '../models/Address';
import { Order } from '../models/Order';
import { ProductsService } from './products.service';
import { ProductCart } from '../models/Cart';
import { CartExpand } from 'src/app/models/Cart';


declare var gtag

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(
    private categoryService: CategoriesService,
    private productsService: ProductsService,
  ) { }

  async getEventItems(order: Partial<Order>): Promise<any> {
    const productsPromises = order.cart.products.map((product) => this.productsService.getProductById(product.productId).pipe(take(1)).toPromise());
  
    try {
      
      const [ products, categoriesById ] = 
        await Promise.all([
          Promise.all(productsPromises), 
          this.categoryService.getAllCategories_dict_id().pipe(take(1)).toPromise()
        ])
      
      const eventItems = products.map((product, index) => (
        {
          item_id: product.id,
          item_name: product.name,
          currency: "USD",
          item_category: categoriesById[product.categoryId]?.name || "",
          price: order.cart.products[index].salePrice,
          quantity: order.cart.products[index].quantity,
        }
      ))
    
      return Promise.resolve(eventItems);

    } catch (error) {
      return Promise.reject(error)
    }
    
  }

  async triggerViewPromotionEvent(promotion_id: string, promotion_name: string, items: { item_id: string, item_name: string, index: number}[]) {
    try {
      gtag("event", GA4Events.view_promotion, {
        promotion_id,
        promotion_name,
        items,
      });
    } catch (error) {
      console.log("Error with event", GA4Events.view_promotion, error);
    }
  }

  async triggerViewCartEvent(cart: CartExpand) {
    try {

      const fakeOrder: Partial<Order> = {
        cart: {
          products: cart.products.map((product) => ({
            ...product as any,
            salePrice: product.product.price || 0
          }))
        }
      }

      const eventItems = await this.getEventItems(fakeOrder);

      const eventDataValue = fakeOrder.cart.products.reduce((previous, current) => previous + current.salePrice || 0, 0)

      const eventData = {
        currency: "USD",
        value: parseFloat(eventDataValue.toFixed(2)),
        items: eventItems,
      }

      gtag("event", GA4Events.view_cart, eventData);
    } catch (error) {
      console.log("Error with event", GA4Events.view_cart, error);
    }
  }

  async triggerViewItemListEvent(items: { item_id: string, item_name: string, index: number, price?: number }[], item_list_id: string, item_list_name: string) {
    try {
      gtag("event", GA4Events.view_item_list, {
        item_list_id,
        item_list_name,
        items,
      })
    } catch (error) {
      console.log("Error with event", GA4Events.view_item_list, error);
    }
  }

  async triggerSelectItemEvent(items: { item_id: string, item_name: string, index: number }[], item_list_id: string, item_list_name: string) {
    try {
      gtag("event", GA4Events.select_item, {
        item_list_id,
        item_list_name,
        items,
      })
    } catch (error) {
      console.log("Error with event", GA4Events.select_item, error);
    }
  }

  async triggerViewItemEvent(viewedProduct: Product, products: Partial<Product>[]) {
    try {  

      const categoriesById = await this.categoryService.getAllCategories_dict_id().pipe(take(1)).toPromise();

      const eventItems = products.map((product) => (
        {
          item_id: product.id,
          item_name: product.name,
          currency: "USD",
          item_category: categoriesById[product.categoryId]?.name || "",
          price: product.price,
          quantity: 1,
        }
      ))
      
      gtag("event", GA4Events.view_item, {
        currency: "USD",
        value: viewedProduct.price,
        items: eventItems,
      })
    } catch (error) {
      console.log("Error with event", GA4Events.view_item, error);
    }
  }

  async triggerRemoveFromCartEvent(products: ProductCart[], totalPrice: number) {
    try {  
      const eventItems = await this.getEventItems({ cart: { products } } as any);
      
      gtag("event", GA4Events.remove_from_cart, {
        currency: "USD",
        value: totalPrice,
        shipping_tier: "Ground",
        items: eventItems,
        item_list_id: "cart",
        item_list_name: "Cart",
      })
    } catch (error) {
      console.log("Error with event", GA4Events.remove_from_cart, error);
    }
  }

  async triggerPurchaseEvent(order: Order) {
    try {  
      const eventItems = await this.getEventItems(order);
      
      gtag("event", GA4Events.purchase, {
        currency: "USD",
        value: order.totalPrice,
        shipping_tier: "Ground",
        items: eventItems,
        transaction_id: order.orderNumber,
        item_list_id: "checkout",
        item_list_name: "Checkout",
      })
    } catch (error) {
      console.log("Error with event", GA4Events.purchase, error);
    }
  }

  async triggerBeginCheckoutEvent(order: Order) {
    try {  
      const eventItems = await this.getEventItems(order);
      
      gtag("event", GA4Events.begin_checkout, {
        currency: "USD",
        value: order.totalPrice,
        shipping_tier: "Ground",
        items: eventItems,
        item_list_id: "checkout",
        item_list_name: "Checkout",
      })
    } catch (error) {
      console.log("Error with event", GA4Events.begin_checkout, error);
    }
  }

  async triggerAddShippingInfoEvent(order: Order) {

    try {  
      const eventItems = await this.getEventItems(order);
      
      gtag("event", GA4Events.add_shipping_info, {
        currency: "USD",
        value: order.totalPrice,
        shipping_tier: "Ground",
        items: eventItems,
        item_list_id: "checkout",
        item_list_name: "Checkout",
      })
    } catch (error) {
      console.log("Error with event", GA4Events.add_shipping_info, error);
    }

  }

  async triggerAddToCartEvent(product: Product) {

    try {
      const category = await this.categoryService.getCategoryById(product.categoryId).pipe(take(1)).toPromise();    
      gtag("event", GA4Events.add_to_cart, {
        currency: "USD",
        value: product.price,
        items: [
          {
            item_id: product.code,
            item_name: product.name,
            currency: "USD",
            item_category: category.name,
            price: product.price,
            quantity: 1
          }
        ]
      });
    } catch (error) {}


  }
}
