<div
  class="order-details"
  [ngClass]="{ container: fromComponent }"
  *ngIf="order"
>
  
  
  <h2 class="title">Detalles de la Orden</h2>
  <div class="order-details__data">
    <div>
      <p class="order-number">Nº #{{this.order.orderNumber}}</p>
      <p *ngIf="order.clientCi" class="client-ci">C.I. del cliente: {{ order.clientCi }}</p>
      <br>

      <p class="order-sub">
        Subtotal: 
        <span>{{ getSubtotalPrice() | currency }}</span>
        <ng-container 
          [ngTemplateOutlet]="convertedAmount" 
          [ngTemplateOutletContext]="{'amount': getSubtotalPrice() , 'dict': order.ratesSnapshot?.dict, 'currencyCode': 'ves'}">
        </ng-container>
      </p>
      <p class="order-sub">
        Delivery: 
        <span>{{ (this.order.deliveryPrice || 0) | currency }}</span>
        <ng-container 
          [ngTemplateOutlet]="convertedAmount" 
          [ngTemplateOutletContext]="{'amount': order.deliveryPrice, 'dict': order.ratesSnapshot?.dict, 'currencyCode': 'ves'}">
        </ng-container>
      </p>
      <p class="order-sub">
        Impuestos: 
        <span>{{ (this.order.taxesPrice || 0) | currency }}</span>
        <ng-container 
          [ngTemplateOutlet]="convertedAmount" 
          [ngTemplateOutletContext]="{'amount': order.taxesPrice, 'dict': order.ratesSnapshot?.dict, 'currencyCode': 'ves'}">
        </ng-container>
      </p>
      <p class="order-sub">
        Descuento (ofertas): 
        <span>-{{ this.order.offersDiscountPrice | currency }}</span>
        <ng-container 
          [ngTemplateOutlet]="convertedAmount" 
          [ngTemplateOutletContext]="{'amount': order.offersDiscountPrice, 'dict': order.ratesSnapshot?.dict, 'currencyCode': 'ves'}">
        </ng-container>
      </p>
      <p class="order-sub" *ngIf="this.order.usedCoupon">
        Descuento (cupón): 
        <span>-{{ this.order.discountPrice | currency }}</span>
        <ng-container 
          [ngTemplateOutlet]="convertedAmount" 
          [ngTemplateOutletContext]="{'amount': order.discountPrice, 'dict': order.ratesSnapshot?.dict, 'currencyCode': 'ves'}">
        </ng-container>
      </p>
      <p class="order-total">
        Orden Total: 
        <span>{{ this.order.totalPrice | currency }}</span>
        <ng-container 
          [ngTemplateOutlet]="convertedAmount" 
          [ngTemplateOutletContext]="{'amount': order.totalPrice, 'dict': order.ratesSnapshot?.dict, 'currencyCode': 'ves'}">
        </ng-container>
      </p>
      <p class="order-date">
        {{ this.order.createdAt.toDate() | date: "medium" }}
      </p>
    </div>
    <div class="status-container">
      <p class="status">
        <span>Estado de la orden:</span> {{ orderStatusesTranslations[order.orderStatus]?.es }}
      </p>
      <p class="status">
        
        <span>Estado del pago:</span> {{ orderPaymentStatusesTranslations[order.paymentStatus]?.es   }}
      </p>
    </div>
  </div>

  <div
    class="order-details__info"
    *ngIf="
      this.order.billingAddress ||
      this.order.shippingAddress ||
      this.order.paymentMethod
    "
  >
    <div class="order-details__info__billing" *ngIf="this.order.billingAddress">
      <p class="title">Dirección de cobro</p>
      <p>
        {{ this.order.billingAddress.firstName }}
        {{ this.order.billingAddress.lastName }}
      </p>
      <p>{{ this.order.billingAddress.addressOne }}</p>
      <p>
        {{ this.order.billingAddress.state }},
        {{ this.order.billingAddress.country }}
      </p>
      <p>{{ this.order.billingAddress.zipCode }}</p>
      <p>Tel: {{ this.order.billingAddress.phone }}</p>
    </div>
    <div
      class="order-details__info__shipping"
      *ngIf="this.order.shippingAddress"
    >
      <p class="title">Dirección de Envío</p>
      <p>
        {{ this.order.shippingAddress.firstName }}
        {{ this.order.shippingAddress.lastName }}
      </p>
      <p>{{ this.order.shippingAddress.addressOne }}</p>
      <p>
        {{ this.order.shippingAddress.state }},
        {{ this.order.shippingAddress.country }}
      </p>
      <p>{{ this.order.shippingAddress.zipCode }}</p>
      <p>Tel: {{ this.order.shippingAddress.phone }}</p>

      <p>---</p>
      <p *ngIf="order.shippingMethod">
        <strong>Método: </strong>{{ order.shippingMethod.name }}
      </p>
      <p *ngIf="order.shippingCompany">
        <strong>Empresa: </strong>{{ order.shippingCompany }}
      </p>
    </div>
    <div class="order-details__info__payment" *ngIf="this.order.paymentMethod">
      <p class="title">Método de pago</p>
      <p>{{ paymentMethodsTranslationsType[this.order.paymentMethod]?.es }}</p>
    </div>
  </div>

  <div class="order-details__products">
    <ng-container *ngFor="let product of order.cart.products">
      <div
        class="order-details__products__product"
        [routerLink]="['/product/' + product.productId]"
      >
        <img
          [src]="
            product.product.imagesUrl
              ? product.product.imagesUrl[0]
              : noImageUrl
          "
          [alt]="product.product.name"
          (error)="handleImageError($event)"
        />
        <div class="info">
          <p class="name">{{ product.product.name }}</p>
          <ng-container *ngIf="product.variationPath">
            <div class="variation">
              <ng-container
                *ngFor="
                  let variationOption of product.variationPath.split('/');
                  let i = index
                "
              >
                <span>{{
                  capitalizeString(product.product.variations[i].name)
                }}</span
                >:
                {{ capitalizeString(variationOption) }}
                <span *ngIf="i !== product.variationPath.split('/').length - 1">
                  -
                </span>
              </ng-container>
            </div>
          </ng-container>
          <p class="price" [ngClass]="{ 'discounted': product.percentageDiscounted }">
            <span>{{ product.salePrice | currency }}</span> x {{product.quantity}}
            <br>
            <ng-container 
              [ngTemplateOutlet]="convertedAmount" 
              [ngTemplateOutletContext]="{'amount': product.salePrice, 'dict': order.ratesSnapshot?.dict, 'currencyCode': 'ves'}">
            </ng-container>
          </p>

          <p class="price" *ngIf="product.percentageDiscounted">
            <span>{{ product.salePrice | discount : product.percentageDiscounted | currency }}</span> x
            {{ product.quantity }}
            <ng-container 
              [ngTemplateOutlet]="convertedAmount" 
              [ngTemplateOutletContext]="{'amount': product.salePrice | discount : product.percentageDiscounted, 'dict': order.ratesSnapshot?.dict, 'currencyCode': 'ves'}">
            </ng-container>
          </p>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #convertedAmount let-amount="amount" let-dict="dict" let-currencyCode="currencyCode">
  <span *ngIf="(amount !== undefined) && dict" class="price__converted">
    ({{ amount | dollarToCurrency : dict[currencyCode] | formatAmount : dict[currencyCode].sign }})
  </span>
</ng-template>