import { NotificationsService } from 'angular2-notifications';
import { CartService } from './../../services/cart.service';
import { AuthService } from './../../services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { CategoryWithSubcategories, Category } from './../../models/Category';
import { takeUntil, switchMap, flatMap, filter, startWith, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { DropdownAction } from '../dropdown-actions/dropdown-actions.component';
import { CategoriesService } from 'src/app/services/categories.service';
import { Subject, of } from 'rxjs';
import { Client } from 'src/app/models/Client';
import { CATEGORIES_ICONS_DICT } from "../../constants/icons.constant";
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ContentService } from 'src/app/services/content.service';
import { FooterContent, FooterSection, FooterSectionLink } from 'src/app/models/Footer';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {

  @ViewChild("allCategoriesElement") allCategoriesElement: ElementRef;

  private destroyed$ = new Subject<boolean>();

  currencyActions: DropdownAction[];
  languageActions: DropdownAction[];
  myAccountActions: DropdownAction[];

  categories: CategoryWithSubcategories[];
  showCategoriesDropdown: boolean;
  showCurrencyDropdown: boolean;
  showLanguageDropdown: boolean;
  user: Client;
  quantityOnCart: number;
  selectedCategory: CategoryWithSubcategories;
  searchText: string;

  showCategoriesDrawer: boolean;
  loadedCategories: boolean;

  showCategoriesFade = true;
  showBox= false

  CATEGORIES_ICONS_DICT = CATEGORIES_ICONS_DICT;
  inCategories:boolean=false

  headerContent: FooterSection;
  helpLink: FooterSectionLink;

  constructor(
    private router: Router,
    private categoriesService: CategoriesService,
    private authService: AuthService,
    private cartService: CartService,
    private notificationService: NotificationsService,
    private analyticsService: AnalyticsService,
    private contentService: ContentService) {}

  private handleAllCategoriesElementScroll(e: Event) {
    const { offsetWidth, scrollWidth, scrollLeft } = e.target as HTMLElement;
    const leftToScroll = (scrollWidth - scrollLeft) - offsetWidth;
    const scrollingIsEnough = leftToScroll <= 50;
    
    if (scrollingIsEnough) {
      this.showCategoriesFade = false;
    } else {
      this.showCategoriesFade = true;
      
    }
  }


  private async setHeaderContent() {
    try {
      const content = await this.contentService.getContent<FooterContent>("footer");
      this.headerContent = content.sections.find((section:FooterSection)=> section.title.es === 'Redes sociales' || section.title.en==='Social networks' )   
    } catch (error) {}
  }

  ngAfterViewInit() {
    this.allCategoriesElement.nativeElement.addEventListener("scroll", this.handleAllCategoriesElementScroll.bind(this));
  }

  ngOnInit(): void {
    this.currencyActions = [
      { name: 'USD', route: '' },
      // { name: 'EUR', route: '' },
      // { name: 'VES', route: '' },
    ];

    this.languageActions = [
      { name: 'Spanish', route: '' },
      // { name: 'English', route: '' },
    ];

    this.myAccountActions = [
      { name: 'Carrito', route: '/cart' },
      { name: 'Ordenes', route: '/orders' },
      { name: 'Direcciones', route: '/profile/addresses' },
      { name: 'Informacion', route: '/profile/data' },
      { name: 'Cerrar Sesion', function: () => this.authService.signOut() },
    ];

    this.categoriesService
      .getCategoriesWithSubcategories()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((categories) => {
        this.categories = categories;
        this.loadedCategories = true;
      });

    this.authService.authUser$
      .pipe(
        switchMap((user) => {
          this.user = user;

          if (user && !user.active) {
            this.authService.signOut(true);
            this.notificationService.error('Tu usuario ha sido bloqueado');
          }

          return this.cartService.getTotalQuantityOnCart(user ? user.id : null);
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((quantityOnCart) => {
        this.quantityOnCart = quantityOnCart;
      });

   

    this.router.events
    .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router)
    )
    .subscribe((event: NavigationEnd) => {
      // there will be first router.url - and next - router events
      this.inCategories=event.url.includes('categories')
    })
    
    this.setHeaderContent();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  setCategoriesDropdown(to: boolean, e?: Event) {
    this.showCategoriesDropdown = to;
    
    if (e) {
      e.stopPropagation();
    }
  }

  selectCategory(category: CategoryWithSubcategories | undefined): void {
    this.selectedCategory = category;
  }

  navigateToProductListing(categorySlug: string, subcategoryId: string): void {
    this.showCategoriesDrawer = false;

    if (categorySlug && subcategoryId) {
      this.router.navigate(['/category', categorySlug], {
        queryParams: { subcatg: subcategoryId },
      });
    } else {
      this.router.navigate(['/category', categorySlug]);
    }
  }

  searchProducts(): void {
    if (this.selectedCategory || this.searchText) {
      const path = this.selectedCategory ? ['category'] : ['products'];
      if (this.selectedCategory?.slug) path.push(this.selectedCategory?.slug);
      this.router.navigate(path, {
        queryParams: {
          queryString: this.searchText,
        },
      });
      this.searchText = undefined;
      this.selectedCategory = undefined;
    }
  }

  onClickedOutside(){
    this.showCategoriesDropdown=false
  }

  handleCategorySelection(categories: Category[], item_list_id: string, item_list_name: string) {
    const items = categories.map((category, index) => ({ item_id: category.id || "", item_name: category.name, index }) );
    this.analyticsService.triggerSelectItemEvent(items, item_list_id, item_list_name);
  }

  handleDrawerActive() {
    if (this.categories) {
      this.analyticsService.triggerViewItemListEvent(
        this.categories.map((category, index) => ({ item_id: category.id || "", item_name: category.name, index })),
        "categories_drawer",
        "Categories drawer",
      )
    }
  }
}
