import { LoaderService } from './services/loader.service';
import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import { filter } from "rxjs/operators";
import { environment } from '../environments/environment';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'aether-ecommerce';
  prevAuthState: boolean = undefined;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
  ) {
    const navigationEndEvent$ = 
      this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      );

    navigationEndEvent$.subscribe((event: NavigationEnd) => {
      gtag('config', 'G-V7WD7JPG9L', {
        'page_path': event.urlAfterRedirects,
        // 'debug_mode': true,
      })
    })
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if ((event instanceof NavigationEnd)) {
        window.scrollTo(0, 0);
        this.loaderService.hideLoader();
      }
    });
  }
}
