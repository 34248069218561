<footer class="footer container" *ngIf="footerContent">
  <div class="footer__links">

    <div *ngFor="let section of footerContent.sections" class="footer__links__link">
      <p class="title">{{ section.title | lang }}</p>

      <ng-container *ngFor="let link of section.links">

        <!-- INTERNAL LINK -->
        <a *ngIf="!link.external" class="link" [routerLink]="link.url" >
          <ng-container [ngTemplateOutlet]="linkContent" [ngTemplateOutletContext]="{'link': link}"></ng-container>
        </a>

        <!-- EXTERNAL LINK -->
        <a *ngIf="link.external" class="link" [href]="link.url" target="blank">
          <ng-container [ngTemplateOutlet]="linkContent" [ngTemplateOutletContext]="{'link': link}"></ng-container>
        </a>

      </ng-container>


    </div>
    
    <div class="footer__links__link logo">
      
        <img [src]="footerContent.footerLogoUrl" alt="logo"/>
        
    </div>
  </div>
  <div class="divider"></div>
  <div class="footer__copy">
    <div class="footer__copy__text">
      <p>{{ footerContent.copyrightText }}</p>
      <p>{{ footerContent.footerEmail }}</p>
      <p>{{ footerContent.phone }}</p>
      <p>{{ footerContent.footerAddress }}</p>
    </div>
    <div class="footer__copy__payments">
      <img src="../../../assets/images/icons/amex.png" alt="amex">
      <img src="../../../assets/images/icons/master.png" alt="master">
      <img src="../../../assets/images/icons/paypal.png" alt="paypal">
      <img src="../../../assets/images/icons/visa.png" alt="visa">
    </div>
  </div>
</footer>


<ng-template #linkContent let-link="link">
  <svg-icon 
    *ngIf="link.iconUrl"
    [class]="'icon'" 
    [applyClass]="true" 
    [src]="link.iconUrl"
  >
  </svg-icon>
  {{ link.text | lang }}
</ng-template>