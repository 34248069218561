import { NotificationsService } from 'angular2-notifications';
import { OrderExpand } from './../models/Order';
import {
  Product,
} from './../models/Product';
import { Injectable } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap, map } from 'rxjs/operators';
import { Comment } from '../models/Comment';
import { firestore } from 'firebase';
import { ProductCartOrder } from '../models/Cart';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  productsPerPage = 12;

  constructor(
    private db: AngularFirestore,
  ) {}

  getProductById(id: string): Observable<Product> {
    return this.db.collection("products").doc<Product>(id).snapshotChanges().pipe<Product>(
      map((item) => ({ ...item.payload.data(), id: item.payload.id } as Product))
    )
  }
  
  getProductsByCategoryId(
    categoryId: string, 
    source: "default" | "server" | "cache" = "default", 
    limit?: number
  ): Observable<Product[]> {
    return this.db.collection<Product>("products", (ref) => {
      let query = ref
        .where("categoryId", "==", categoryId)
        .where("active", "==", true);

      if (limit) {
        query = ref.limit(limit)
      }

      return query;
    })
    .get({ source })
    .pipe(
      map((querySnap) => {
        return querySnap.docs.map((doc) => ({ ...doc.data() as Product, id: doc.id }));
      })
    );
  }

  getProductsByCategoryAndSubcategory(
    categoryId: string = null,
    subcategoryId: string = null,
    startAfter: any = null,
    startAt: any = null,
    endBefore: any = null
  ): Observable<Product[]> {
    // Si estamos buscando next
    if (startAfter) {
      if (!categoryId) {
        const products$ = this.db
          .collection<Product>('products', (ref) =>
            ref
              .where('quantity', '>', 0)
              .where('active', '==', true)
              .limit(this.productsPerPage)
              .orderBy('quantity')
              .startAfter(startAfter === 'firstTime' ? null : startAfter)
          )
          .snapshotChanges()
          .pipe(
            map((products) => {
              return products.map((prod) => ({
                ...prod.payload.doc.data(),
                id: prod.payload.doc.id,
                ref: prod.payload.doc,
              }));
            })
          );

        return products$;
      } else if (categoryId && !subcategoryId) {
        const products$ = this.db
          .collection<Product>('products', (ref) =>
            ref
              .where('categoryId', '==', categoryId)
              .where('quantity', '>', 0)
              .where('active', '==', true)
              .limit(this.productsPerPage)
              .orderBy('quantity')
              .startAfter(startAfter === 'firstTime' ? null : startAfter)
          )
          .snapshotChanges()
          .pipe(
            map((products) => {
              return products.map((prod) => ({
                ...prod.payload.doc.data(),
                id: prod.payload.doc.id,
                ref: prod.payload.doc,
              }));
            })
          );

        return products$;
      } else {
        const products$ = this.db
          .collection<Product>('products', (ref) =>
            ref
              .where('categoryId', '==', categoryId)
              .where('subcategoryId', '==', subcategoryId)
              .where('quantity', '>', 0)
              .where('active', '==', true)
              .limit(this.productsPerPage)
              .orderBy('quantity')
              .startAfter(startAfter === 'firstTime' ? null : startAfter)
          )
          .snapshotChanges()
          .pipe(
            map((products) => {
              return products.map((prod) => ({
                ...prod.payload.doc.data(),
                id: prod.payload.doc.id,
                ref: prod.payload.doc,
              }));
            })
          );

        return products$;
      }
    } else {
      if (!categoryId) {
        const products$ = this.db
          .collection<Product>('products', (ref) =>
            ref
              .where('quantity', '>', 0)
              .where('active', '==', true)
              .limit(this.productsPerPage)
              .orderBy('quantity')
              .startAt(startAt)
              .endBefore(endBefore)
          )
          .snapshotChanges()
          .pipe(
            map((products) => {
              return products.map((prod) => ({
                ...prod.payload.doc.data(),
                id: prod.payload.doc.id,
                ref: prod.payload.doc,
              }));
            })
          );

        return products$;
      } else if (categoryId && !subcategoryId) {
        const products$ = this.db
          .collection<Product>('products', (ref) =>
            ref
              .where('categoryId', '==', categoryId)
              .where('quantity', '>', 0)
              .where('active', '==', true)
              .limit(this.productsPerPage)
              .orderBy('quantity')
              .startAt(startAt)
              .endBefore(endBefore)
          )
          .snapshotChanges()
          .pipe(
            map((products) => {
              return products.map((prod) => ({
                ...prod.payload.doc.data(),
                id: prod.payload.doc.id,
                ref: prod.payload.doc,
              }));
            })
          );

        return products$;
      } else {
        const products$ = this.db
          .collection<Product>('products', (ref) =>
            ref
              .where('categoryId', '==', categoryId)
              .where('subcategoryId', '==', subcategoryId)
              .where('quantity', '>', 0)
              .where('active', '==', true)
              .limit(this.productsPerPage)
              .orderBy('quantity')
              .startAt(startAt)
              .endBefore(endBefore)
          )
          .snapshotChanges()
          .pipe(
            map((products) => {
              return products.map((prod) => ({
                ...prod.payload.doc.data(),
                id: prod.payload.doc.id,
                ref: prod.payload.doc,
              }));
            })
          );

        return products$;
      }
    }
  }

  getProductsBySearchQueryAndCategoryAndSubcategory(
    queryString: string,
    categoryId: string = null,
    subcategoryId: string = null,
    startAfter: any = null,
    startAt: any = null,
    endBefore: any = null
  ): Observable<Product[]> {
    // Si estamos buscando next
    if (startAfter) {
      if (queryString && !categoryId && !subcategoryId) {
        const products$ = this.db
          .collection<Product>('products', (ref) =>
            ref
              .where('quantity', '>', 0)
              .where('keywords', 'array-contains', queryString.toLowerCase())
              .where('active', '==', true)
              .limit(this.productsPerPage)
              .orderBy('quantity')
              .startAfter(startAfter === 'firstTime' ? null : startAfter)
          )
          .snapshotChanges()
          .pipe(
            map((products) => {
              return products.map((prod) => ({
                ...prod.payload.doc.data(),
                id: prod.payload.doc.id,
                ref: prod.payload.doc,
              }));
            })
          );

        return products$;
      } else if (queryString && categoryId && !subcategoryId) {
        const products$ = this.db
          .collection<Product>('products', (ref) =>
            ref
              .where('categoryId', '==', categoryId)
              .where('quantity', '>', 0)
              .where('active', '==', true)
              .where('keywords', 'array-contains', queryString.toLowerCase())
              .limit(this.productsPerPage)
              .orderBy('quantity')
              .startAfter(startAfter === 'firstTime' ? null : startAfter)
          )
          .snapshotChanges()
          .pipe(
            map((products) => {
              return products.map((prod) => ({
                ...prod.payload.doc.data(),
                id: prod.payload.doc.id,
                ref: prod.payload.doc,
              }));
            })
          );

        return products$;
      } else {
        const products$ = this.db
          .collection<Product>('products', (ref) =>
            ref
              .where('categoryId', '==', categoryId)
              .where('subcategoryId', '==', subcategoryId)
              .where('quantity', '>', 0)
              .where('active', '==', true)
              .where('keywords', 'array-contains', queryString.toLowerCase())
              .limit(this.productsPerPage)
              .orderBy('quantity')
              .startAfter(startAfter === 'firstTime' ? null : startAfter)
          )
          .snapshotChanges()
          .pipe(
            map((products) => {
              return products.map((prod) => ({
                ...prod.payload.doc.data(),
                id: prod.payload.doc.id,
                ref: prod.payload.doc,
              }));
            })
          );

        return products$;
      }
    } else {
      if (queryString && !categoryId && !subcategoryId) {
        const products$ = this.db
          .collection<Product>('products', (ref) =>
            ref
              .where('quantity', '>', 0)
              .where('active', '==', true)
              .where('keywords', 'array-contains', queryString.toLowerCase())
              .limit(this.productsPerPage)
              .orderBy('quantity')
              .startAt(startAt)
              .endBefore(endBefore)
          )
          .snapshotChanges()
          .pipe(
            map((products) => {
              return products.map((prod) => ({
                ...prod.payload.doc.data(),
                id: prod.payload.doc.id,
                ref: prod.payload.doc,
              }));
            })
          );

        return products$;
      } else if (queryString && categoryId && !subcategoryId) {
        const products$ = this.db
          .collection<Product>('products', (ref) =>
            ref
              .where('categoryId', '==', categoryId)
              .where('active', '==', true)
              .where('quantity', '>', 0)
              .where('keywords', 'array-contains', queryString.toLowerCase())
              .limit(this.productsPerPage)
              .orderBy('quantity')
              .startAt(startAt)
              .endBefore(endBefore)
          )
          .snapshotChanges()
          .pipe(
            map((products) => {
              return products.map((prod) => ({
                ...prod.payload.doc.data(),
                id: prod.payload.doc.id,
                ref: prod.payload.doc,
              }));
            })
          );

        return products$;
      } else {
        const products$ = this.db
          .collection<Product>('products', (ref) =>
            ref
              .where('categoryId', '==', categoryId)
              .where('active', '==', true)
              .where('subcategoryId', '==', subcategoryId)
              .where('quantity', '>', 0)
              .where('keywords', 'array-contains', queryString.toLowerCase())
              .limit(this.productsPerPage)
              .orderBy('quantity')
              .startAt(startAt)
              .endBefore(endBefore)
          )
          .snapshotChanges()
          .pipe(
            map((products) => {
              return products.map((prod) => ({
                ...prod.payload.doc.data(),
                id: prod.payload.doc.id,
                ref: prod.payload.doc,
              }));
            })
          );

        return products$;
      }
    }
  }

  getAllProducts(
    categoryId: string = null,
    subcategoryId: string = null,
    queryString: string,
  ): Observable<Product[]>{
    //Default all products
    
    let query= this.db.collection<Product>('products', (ref)=> 
    ref
      .where('active', '==', true)
      .where('quantity', '>', 0)
      .orderBy('quantity')
    )

    if(queryString){
      if(categoryId){
        if(subcategoryId){
          query=this.db.collection<Product>('products',(ref)=> 
            ref
              .where('categoryId', '==', categoryId)
              .where('active', '==', true)
              .where('subcategoryId', '==', subcategoryId)
              .where('quantity', '>', 0)
              .where('keywords', 'array-contains', queryString.toLowerCase())
              .orderBy('quantity')
          )
        }else{
          query=this.db.collection<Product>('products',(ref)=> 
            ref
              .where('categoryId', '==', categoryId)
              .where('active', '==', true)
              .where('quantity', '>', 0)
              .where('keywords', 'array-contains', queryString.toLowerCase())
              .orderBy('quantity')
          )
        }
      }else{
        query=this.db.collection<Product>('products',(ref)=> 
            ref
              .where('active', '==', true)
              .where('quantity', '>', 0)
              .where('keywords', 'array-contains', queryString.toLowerCase())
              .orderBy('quantity')
          )
      }
    }else{
      if(categoryId){
        if(subcategoryId){
          query=this.db.collection<Product>('products',(ref)=> 
            ref
              .where('categoryId', '==', categoryId)
              .where('active', '==', true)
              .where('subcategoryId', '==', subcategoryId)
              .where('quantity', '>', 0)
              .orderBy('quantity')
          )
        }else{
          query=this.db.collection<Product>('products',(ref)=> 
            ref
              .where('categoryId', '==', categoryId)
              .where('active', '==', true)
              .where('quantity', '>', 0)
              .orderBy('quantity')
          )
        }
      }
    }

    return query.snapshotChanges()
    .pipe(
      map((products) => {
        return products.map((prod) => ({
          ...prod.payload.doc.data(),
          id: prod.payload.doc.id,
          ref: prod.payload.doc,
        }));
      })
    );;
  }

  goToPage(
    categoryId: string = null,
    subcategoryId: string = null,
    queryString: string,
  ): Observable<Product[]>{
    
    //Default all products
    let query= this.db.collection<Product>('products', (ref)=> 
    ref
      .where('active', '==', true)
      .where('quantity', '>', 0)
      .orderBy('quantity')
    )

    if(queryString){
      if(categoryId){
        if(subcategoryId){
          query=this.db.collection<Product>('products',(ref)=> 
            ref
              .where('categoryId', '==', categoryId)
              .where('active', '==', true)
              .where('subcategoryId', '==', subcategoryId)
              .where('quantity', '>', 0)
              .where('keywords', 'array-contains', queryString.toLowerCase())
              .orderBy('quantity')
          )
        }else{
          query=this.db.collection<Product>('products',(ref)=> 
            ref
              .where('categoryId', '==', categoryId)
              .where('active', '==', true)
              .where('quantity', '>', 0)
              .where('keywords', 'array-contains', queryString.toLowerCase())
              .orderBy('quantity')
          )
        }
      }else{
        query=this.db.collection<Product>('products',(ref)=> 
            ref
              .where('active', '==', true)
              .where('quantity', '>', 0)
              .where('keywords', 'array-contains', queryString.toLowerCase())
              .orderBy('quantity')
          )
      }
    }else{
      if(categoryId){
        if(subcategoryId){
          query=this.db.collection<Product>('products',(ref)=> 
            ref
              .where('categoryId', '==', categoryId)
              .where('active', '==', true)
              .where('subcategoryId', '==', subcategoryId)
              .where('quantity', '>', 0)
              .orderBy('quantity')
          )
        }else{
          query=this.db.collection<Product>('products',(ref)=> 
            ref
              .where('categoryId', '==', categoryId)
              .where('active', '==', true)
              .where('quantity', '>', 0)
              .orderBy('quantity')
          )
        }
      }
    }

    return query.snapshotChanges()
    .pipe(
      map((products) => {
        return products.map((prod) => ({
          ...prod.payload.doc.data(),
          id: prod.payload.doc.id,
          ref: prod.payload.doc,
        }));
      })
    );;

  }

  getPopularProducts(): Observable<Product[]> {
    const products$ = this.db
      .collection<Product>('products', (ref) => {
        return ref
          .where('featured', '==', true)
          .where('quantity', '>', 0)
          .where('active', '==', true)
          .where('_categoryIsActive', '==', true);
      })
      .valueChanges({ idField: 'id' });

    return products$;
  }

  getNewArrivedProducts(): Observable<Product[]> {
    const products$ = this.db
      .collection<Product>('products', (ref) => {
        return ref
          .where('newArrived', '==', true)
          .where('quantity', '>', 0)
          .where('active', '==', true)
          .where('_categoryIsActive', '==', true);
      })
      .valueChanges({ idField: 'id' });

    return products$;
  }

  getProductBySlug(slug: string): Observable<Product> {
    const product$ = this.db
      .collection<Product>(`products/`, (ref) => {
        return ref.where("slug", "==", slug).limit(1);
      })
      .valueChanges({ idField: 'id' })
      .pipe(
        map((products) => {
          return products[0] as Product | undefined;
        })
      );

    return product$;
  }

  getProductOnce(id: string): Promise<Product | undefined> {
    return this.db.firestore.collection("products").doc(id).get()
      .then((snap) => snap.data() as Product | undefined);
  }

  getProductBySlugWithComments(slug: string): Observable<Product | undefined> {
    const product$ = this.getProductBySlug(slug)
      .pipe(
        switchMap((product) => {
          return this.db
            .collection<Comment>(`products/${product.id!}/comments`)
            .valueChanges({ idField: 'id' })
            .pipe(
              map((comments) => {
                return {
                  ...product,
                  comments: comments.map((comment) => ({ ...comment })),
                };
              })
            );
        })
      );

    return product$;
  }

  getProductWithComments(productId: string): Observable<Product> {
    const product$ = this.db
      .doc<Product>(`products/${productId}`)
      .valueChanges()
      .pipe(
        switchMap((dbProduct) => {
          return this.db
            .collection<Comment>(`products/${productId}/comments`)
            .valueChanges({ idField: 'id' })
            .pipe(
              map((comments) => {
                return {
                  id: productId,
                  ...dbProduct,
                  comments: comments.map((comment) => ({ ...comment })),
                };
              })
            );
        })
      );

    return product$;
  }

  async reduceInventory(order: OrderExpand): Promise<any> {
    const batch = this.db.firestore.batch();

    for (const product of order.cart.products) {
      if (product.variationPath) {
        const docRef = this.db.doc<Product>(
          `products/${product.productId}`
        ).ref;
        const docData = (await docRef.get()).data() as Product;

        const variationsComb = docData.variationsComb;
        variationsComb.find(
          (comb) => comb.path === product.variationPath
        ).quantity -= product.quantity;

        await docRef.update({ variationsComb });
      } else {
        const docRef = this.db.doc<Product>(
          `products/${product.productId}`
        ).ref;
        const decrement = firestore.FieldValue.increment(product.quantity * -1);

        batch.update(docRef, { quantity: decrement });
      }
    }

    return batch.commit();
  }

  checkStockOfProducts(products: ProductCartOrder[]): Observable<any[]> {
    const obsProducts = [];

    for (const prod of products) {
      obsProducts.push(
        this.db
          .doc<Product>(`products/${prod.productId}`)
          .get({ source: 'server' })
          .pipe(
            map((dbProduct) => {
              const data = dbProduct.data() as Product;

              if (prod.variationPath) {
                const prodComb = data.variationsComb.find(
                  (comb) => comb.path === prod.variationPath
                );
                if (prod.quantity > prodComb.quantity || !data.active) {
                  return false;
                } else {
                  return true;
                }
              } else {
                // Si el stock cambió y ya no hay suficiente
                if (prod.quantity > data.quantity || !data.active) {
                  return false;
                } else {
                  return true;
                }
              }
            })
          )
      );
    }

    return combineLatest([...obsProducts]);
  }
}

  // async addDummyRatings() {
  //   const batch = this.db.firestore.batch();
  //   const productsSnap = await this.db.collection("products").ref.get()

  //   productsSnap.docs.forEach((productDoc) => {
  //     const timesRated = Math.ceil(Math.random() * 20);
  //     const ratings = new Array(timesRated).fill(1).map(() => {
  //       const rating = Math.ceil(Math.random() * 5);
  //       return rating;
  //     });
  //     const ratingsSum = ratings.reduce((previous, current) => previous + current, 0);

  //     batch.update(productDoc.ref, { timesRated, ratingsSum })
  //   })

  //   batch.commit();
  // }