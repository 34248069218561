import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../models/Product';

@Pipe({
  name: 'starsRating'
})
export class StarsRatingPipe implements PipeTransform {

  transform(product: Product): number {
    if (!product) return 0;

    const { ratingsSum = 0, timesRated = 1 } = product;

    return parseFloat((Math.round((ratingsSum / (timesRated || 1)) * 2) / 2).toFixed(1));
  }

}
