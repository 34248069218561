import { Translated } from './Translated';

export type PaymentStatus = 'PENDING' | 'APPROVED' | 'REJECTED';

export const paymentStatusesTranslations
  : { [status in PaymentStatus]: Translated }
  = {
    "PENDING": {
      en: "Pending",
      es: "Pendiente",
    },
    "APPROVED": {
      en: "Approved",
      es: "Aprobado",
    },
    "REJECTED": {
      en: "Rejected",
      es: "Rechazado",
    }
  }

export interface Payment {
  type: PaymentMethods;
  clientId: string;
  orderId: string;
  orderNumber: number;
  value: number;
  name: string;
  identification: string;
  bank: string;
  ref: string;
  captureUrl: string;
  status: PaymentStatus;
  createdAt: any;
  // Paypal
  email?: string;
  // Credit Card
  last4Number?: string;
  sourceAccountNumber?: string;
}

export interface BanescoPayment {
  type: PaymentMethods;
  clientId: string;
  orderId: string;
  orderNumber: number;
  value: number;
  name: string;
  identification: string;
  bank: string;
  ref: string;
  captureUrl: string;
  status: PaymentStatus;
  createdAt: any;
  last4Number?: string;
}

export interface PanamaBankPayment {
  type: PaymentMethods;
  clientId: string;
  orderId: string;
  orderNumber: number;
  value: number;
  name: string;
  bank: string;
  ref: string;
  captureUrl: string;
  status: PaymentStatus;
  createdAt: any;
  sourceAccountNumber?: string;
}

export interface ZellePayment {
  type: PaymentMethods;
  clientId: string;
  orderId: string;
  orderNumber: number;
  value: number;
  name: string;
  originBank: string;
  email: string;
  ref: string;
  captureUrl: string;
  status: PaymentStatus;
  createdAt: any;
}

export interface MovilPayment {
  type: PaymentMethods;
  clientId: string;
  orderId: string;
  orderNumber: number;
  value: number;
  name: string;
  originBank: string;
  identification: string;
  ref: string;
  captureUrl: string;
  status: PaymentStatus;
  createdAt: any;
}

export interface CashPayment {
  type: PaymentMethods;
  clientId: string;
  orderId: string;
  orderNumber: number;
  value: number;
  captureUrl: string;
  status: PaymentStatus;
  createdAt: any;
  extraComments: string;
}

export interface PayPalPayment {
  type: PaymentMethods;
  clientId: string;
  orderId: string;
  orderNumber: number;
  value: number;
  name: string;
  payerEmail: string;
  payerName: string;
  payerSurname: string;
  ref: string;
  status: PaymentStatus;
  createdAt: any;
}

export enum PaymentMethods {
  'BANESCOTRANSFER',
  'PANAMATRANSFER',
  'PAYPAL',
  'ZELLETRANSFER',
  'MOBILEPAYMENT',
  'CASHPAYMENT',
}

export const paymentMethodsTranslationsType
  : { [ key: string ]: Translated }
  = {
      "BANESCOTRANSFER": {
        en: "",
        es: "Transferencia Banesco",
      },
      "PANAMATRANSFER": {
        en: "",
        es: "Transferencia Banesco Panamá",
      },
      "PAYPAL": {
        en: "",
        es: "PayPal",
      },
      "ZELLETRANSFER": {
        en: "",
        es: "Zelle",
      },
      "MOBILEPAYMENT": {
        en: "",
        es: "Pago móvil",
      },
      "CASHPAYMENT": {
        en: "",
        es: "Efectivo"
      },
  }

export const paymentMethodsTranslationsEnum
  : { [index in PaymentMethods]: Translated }
  = {
    0: {
      en: "",
      es: "Transferencia Banesco",
    },
    1: {
      en: "",
      es: "Transferencia Banesco Panamá",
    },
    2: {
      en: "",
      es: "PayPal",
    },
    3: {
      en: "",
      es: "Zelle",
    },
    4: {
      en: "",
      es: "Pago móvil",
    },
    5: {
      en: "",
      es: "Efectivo"
    },
  };
