<div class="cart-purchase-panel" [ngClass]="{ order: !onCartView }">
  <ng-container *ngIf="!onCartView">
    <h2 class="title">Carrito de compras</h2>
    <p class="quantity">
      Tu carrito: {{ cartLength }}
      {{ cartLength > 1 ? "productos" : "producto" }}
    </p>

    <div class="cart-purchase-panel__products">
      <ng-container *ngFor="let product of order.cart.products">
        <div class="cart-purchase-panel__products__product">
          <img
            [src]="product.product.imagesUrl ? product.product.imagesUrl[0] : noImageUrl"
            [alt]="product.product.name"
            (error)="handleImageError($event)"
          />
          <div class="info">
            <p class="name">{{ product.product.name }}</p>
            <ng-container *ngIf="product.variationPath">
              <div class="variation">
                <ng-container
                  *ngFor="
                    let variationOption of product.variationPath.split('/');
                    let i = index
                  "
                >
                  <span>{{
                    capitalizeString(product.product.variations[i].name)
                  }}</span
                  >:
                  {{ capitalizeString(variationOption) }}
                  <span
                    *ngIf="i !== product.variationPath.split('/').length - 1"
                  >
                    <br />
                  </span>
                </ng-container>
              </div>
            </ng-container>
            <p class="price" [ngClass]="{ 'discounted': product.percentageDiscounted }">
              <span>{{ product.salePrice | currency }}</span> x
              {{ product.quantity }}
              <ng-container 
                [ngTemplateOutlet]="convertedAmount" 
                [ngTemplateOutletContext]="{'amount': product.salePrice, 'dict': order.ratesSnapshot?.dict, 'currencyCode': 'ves'}">
              </ng-container>
            </p>

            <p class="price" *ngIf="product.percentageDiscounted">
              <span>{{ product.salePrice | discount : product.percentageDiscounted | currency }}</span> x
              {{ product.quantity }}
              <ng-container 
                [ngTemplateOutlet]="convertedAmount" 
                [ngTemplateOutletContext]="{'amount': product.salePrice | discount : product.percentageDiscounted, 'dict': order.ratesSnapshot?.dict, 'currencyCode': 'ves'}">
              </ng-container>
            </p>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div class="cart-purchase-panel__coupons" *ngIf="!onCartView">
    <div class="input-group-double">
      <label for="coupon">¿Tienes un cupón?</label>
      <div class="input-button-container">
        <input
          type="text"
          id="coupon"
          placeholder="Código del cupón"
          [(ngModel)]="couponCode"
        />
        <button class="btn" (click)="verifyCoupon()" [disabled]="!couponCode">
          Aplicar
        </button>
      </div>
      <p class="used-coupon" *ngIf="order.usedCoupon">
        <span>Cupones usados:</span> {{ order.usedCoupon.code }} (-{{
          order.usedCoupon.type === "AJUSTADO"
            ? (order.usedCoupon.amount | currency)
            : (order.usedCoupon.amount / 100 | percent)
        }})
      </p>
    </div>
  </div>

  
  <div class="cart-purchase-panel__review" *ngIf="loaded">
    <div class="cart-purchase-panel__review__row">
      <p class="title">Subtotal:</p>
      <p class="price">
        {{ subtotalPrice | currency }}

        <ng-container 
          [ngTemplateOutlet]="convertedAmount" 
          [ngTemplateOutletContext]="{'amount': subtotalPrice, 'dict': (order ? order.ratesSnapshot?.dict : currenciesDictionary), 'currencyCode': 'ves'}">
        </ng-container>
      </p>
    </div>
    <div class="cart-purchase-panel__review__row discount" *ngIf="!onCartView">
      <p class="title">Descuento (cupón):</p>
      <p class="price">
        - {{ discountPrice | currency }}
        <ng-container 
          [ngTemplateOutlet]="convertedAmount" 
          [ngTemplateOutletContext]="{'amount': -discountPrice, 'dict': (order ? order.ratesSnapshot?.dict : currenciesDictionary), 'currencyCode': 'ves'}">
        </ng-container>
      </p>
    </div>
    <div class="cart-purchase-panel__review__row discount">
      <p class="title">Descuento (ofertas):</p>
      <p class="price">
        - {{ offersDiscountPrice | currency }}
        <ng-container 
          [ngTemplateOutlet]="convertedAmount" 
          [ngTemplateOutletContext]="{'amount': -offersDiscountPrice || 0, 'dict': (order ? order.ratesSnapshot?.dict : currenciesDictionary), 'currencyCode': 'ves'}">
        </ng-container>
      </p>
    </div>
    <div class="cart-purchase-panel__review__row">
      <p class="title">Delivery:</p>
      <p class="price">
        {{ deliveryPrice === undefined ? "A ser calculado" : deliveryPrice | currency }}
        <ng-container 
          [ngTemplateOutlet]="convertedAmount" 
          [ngTemplateOutletContext]="{'amount': deliveryPrice, 'dict': (order ? order.ratesSnapshot?.dict : currenciesDictionary), 'currencyCode': 'ves'}">
        </ng-container>
      </p>
    </div>
    <div class="cart-purchase-panel__review__row">
      <p class="title">Impuestos:</p>
      <p class="price">
        {{ taxesPrice === undefined ? "A ser calculado" : (taxesPrice | currency) }}
        <ng-container 
          [ngTemplateOutlet]="convertedAmount" 
          [ngTemplateOutletContext]="{'amount': taxesPrice, 'dict': (order ? order.ratesSnapshot?.dict : currenciesDictionary), 'currencyCode': 'ves'}">
        </ng-container>
      </p>
    </div>
    <div class="cart-purchase-panel__review__row total">
      <p class="title">Est. total:</p>
      <p class="price">
        {{ totalPrice | currency }}
        <ng-container 
          [ngTemplateOutlet]="convertedAmount" 
          [ngTemplateOutletContext]="{'amount': totalPrice, 'dict': (order ? order.ratesSnapshot?.dict : currenciesDictionary), 'currencyCode': 'ves'}">
        </ng-container>
      </p>
    </div>
    <button class="btn" (click)="onMakePurchaseClick()" *ngIf="onCartView" [disabled]="creatingOrder">
      Continuar con la orden
    </button>
    <button class="btn btn-red" (click)="cancelOrder()" *ngIf="!onCartView">
      Cancelar orden
    </button>
    <div class="payments" *ngIf="onCartView">
      <img src="../../../assets/images/icons/amex.png" alt="amex" />
      <img src="../../../assets/images/icons/master.png" alt="master" />
      <img src="../../../assets/images/icons/paypal.png" alt="paypal" />
      <img src="../../../assets/images/icons/visa.png" alt="visa" />
    </div>
  </div>
</div>

<ng-template #convertedAmount let-amount="amount" let-dict="dict" let-currencyCode="currencyCode">
  <span *ngIf="(amount !== undefined) && dict" class="price__converted">
    ({{ amount | dollarToCurrency : dict[currencyCode] | formatAmount : dict[currencyCode].sign }})
  </span>
</ng-template>
