export const CATEGORIES_ICONS_DICT = {
    "categories":"https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2Fcil-applications.svg?alt=media&token=0748d0fa-91c5-4e97-9659-a11c597cbfd7",
    "electronics_cord_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F001-cord.svg?alt=media&token=c81b0837-3060-4140-a193-f21135bad11b",
    "electronics_cord_1": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F065-cord.svg?alt=media&token=b8388412-5e9b-4b86-851a-54b9c425ed13",
    "electronics_remote_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F002-remote.svg?alt=media&token=d827b9ad-c688-422b-8b6b-af4b18165693",
    "electronics_modem_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F003-modem.svg?alt=media&token=3d7f4f06-af1f-4533-a8bf-6d111513d937",
    "electronics_earphone_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F004-earphone.svg?alt=media&token=136353c8-6120-4652-bf0d-ab3c6141de7c",
    "electronics_lcd_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F005-lcd.svg?alt=media&token=075eb5f9-0092-4ad4-b03a-5ad606c5a417",
    "electronics_mouse_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F006-mouse.svg?alt=media&token=6dc4fdc9-8c5a-4ae0-84de-d0e425fe2ca8",
    "electronics_radio_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F007-radio.svg?alt=media&token=77261d75-a804-419d-b676-ac9de7e397ca",
    "electronics_camera_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F008-movie%20camera.svg?alt=media&token=fe29f348-4751-467a-b679-77e0d06d1aab",
    "electronics_projector_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F009-projector.svg?alt=media&token=2721d524-c253-4c24-b1bb-1c6252ff0701",
    "electronics_megaphone_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F010-megaphone.svg?alt=media&token=e6895b33-28ee-4340-a032-e506f9e13886",
    "electronics_keyboard_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F011-keyboard.svg?alt=media&token=167665f2-fc29-4fcf-9f0f-8ce73e7fae24",
    "electronics_woofer_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F012-woofer.svg?alt=media&token=7381582b-ccc4-4bba-a4e9-dc9c1bc1147c",
    "electronics_joystick_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F013-joystick.svg?alt=media&token=47d34ac5-213b-451d-91f4-a56656ba8302",
    "electronics_cable_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F014-data%20cable.svg?alt=media&token=70b8787d-7f83-4385-bbd9-00bc778c6534",
    "electronics_printer_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F015-printer.svg?alt=media&token=e370fd54-79ca-432a-b41f-e2124ae49c2d",
    "electronics_floppy_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F016-floppy.svg?alt=media&token=2b708249-9001-40c2-ac63-5b9d9633796d",
    "electronics_mic_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F017-mic.svg?alt=media&token=aac760ce-fb17-4360-857e-07f5e7d3355c",
    "electronics_socket_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F018-socket.svg?alt=media&token=7c9c4bb7-bb17-4a3e-973a-689e897737a6",
    "electronics_ram_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F019-ram.svg?alt=media&token=03aafdd7-3e71-440f-9437-be6ba9775b80",
    "electronics_flash_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F020-flash.svg?alt=media&token=cf159643-387d-4376-9e07-d45ba1329be6",
    "electronics_laptop_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F021-laptop.svg?alt=media&token=09e86316-f434-4e00-a6bd-b9a37e8dcd96",
    "electronics_off_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F022-turn%20off.svg?alt=media&token=9ff4e0ea-d6c8-435d-8e99-5fdbde66c0dd",
    "electronics_call_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F023-call.svg?alt=media&token=691770d7-1b2f-4daf-983d-e45599af3e8b",
    "electronics_piano_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F024-piano.svg?alt=media&token=5bf90c4a-675a-4240-aa81-e970dae20525",
    "electronics_telephone_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F025-telephone.svg?alt=media&token=6ad01b02-739c-4289-b692-90e4c4d9cf57",
    "electronics_fridge_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F026-fridge.svg?alt=media&token=42bd0e72-2704-46d2-9e2c-d3447db57090",
    "electronics_mic_1": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F027-mic.svg?alt=media&token=7cdf23d0-9a88-48f6-a866-6275b03afbc1",
    "electronics_iron_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F028-iron.svg?alt=media&token=a9c73a02-31d1-4f8f-977c-911ac040eadd",
    "electronics_recorder_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F029-recorder.svg?alt=media&token=5d88242f-211d-4ef2-91c1-058a508f984d",
    "electronics_port_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F030-port.svg?alt=media&token=9597813a-efbf-4fb6-93cb-1baee2ca243d",
    "electronics_speaker_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F031-speaker.svg?alt=media&token=0dad892a-fc88-4412-9084-04c281690239",
    "electronics_cloud_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F032-cloud.svg?alt=media&token=7e79d933-97de-49bf-b358-74075936036c",
    "electronics_bulb_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F033-bulb.svg?alt=media&token=14535049-580d-4a43-a68e-3e8164f27913",
    "electronics_sim_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F034-sim.svg?alt=media&token=e74549e4-c2ae-417c-b328-e44bfffdf532",
    "electronics_card_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F035-graphic%20card.svg?alt=media&token=b8344bbe-c483-4716-a95a-4a444ce87222",
    "electronics_dryer_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F036-dryer.svg?alt=media&token=7ae0b5cf-ce82-4a55-bf3a-1c87f0cf7724",
    "electronics_bulb_1": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F037-bulb.svg?alt=media&token=9e03c3af-27a8-4ba2-bcdb-32624447e50d",
    "electronics_webcam_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F038-webcam.svg?alt=media&token=58c9ae24-331c-4e13-b7c7-1d9f9574df80",
    "electronics_bulb_2": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F039-bulb.svg?alt=media&token=618e8f2d-62c3-4bbf-b2ea-d8ab595c000a",
    "electronics_gramophone_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F040-gramophone.svg?alt=media&token=0d6d415d-2f56-41d7-8196-4de7bcc4dbac",
    "electronics_cpu_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F041-cpu.svg?alt=media&token=e353caa6-b956-494a-8390-4c70e3bf8cf9",
    "electronics_machine_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F042-washing%20machine.svg?alt=media&token=68ddb90d-3b0f-4b88-b2fa-ce5ba6cf8cd1",
    "electronics_signal_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F043-traffic%20signal.svg?alt=media&token=cfb55e96-b31f-460c-9bac-56c7768ece62",
    "electronics_plug_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F044-plug.svg?alt=media&token=b08a3e27-aea3-41d6-b6a3-0ecb5341fb56",
    "electronics_player_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F045-dvd%20player.svg?alt=media&token=b4064efc-c6a3-49fc-a7b1-4b9e1446353c",
    "electronics_cloud_1": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F046-cloud.svg?alt=media&token=e95b20a2-9e71-4174-9aad-3151ae2d96fd",
    "electronics_drill_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F047-drill.svg?alt=media&token=cdb710cb-453e-4e07-9904-546dad2e29a2",
    "electronics_battery_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F048-battery.svg?alt=media&token=778a9f50-80d6-4578-83af-06b5c572dfe1",
    "electronics_wire_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F049-wire.svg?alt=media&token=7fbb45e2-b244-47af-a1fc-8b26aaea9923",
    "electronics_phone_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F050-phone.svg?alt=media&token=bbb2ae1d-4053-42ad-a604-05b6e2b29c6b",
    "electronics_cassette_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F051-cassette.svg?alt=media&token=914bbabf-0d44-41c6-bb91-606a29a42841",
    "electronics_processor_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F052-processor.svg?alt=media&token=df62eab4-f075-4c3a-920f-cd416220d4bd",
    "electronics_cd_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F053-cd.svg?alt=media&token=0fd2a5b1-d804-4825-aab3-5bf4459274f3",
    "electronics_battery_1": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F054-battery.svg?alt=media&token=f62b7050-0442-4913-94ca-d50390440ece",
    "electronics_disk_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F055-hard%20disk.svg?alt=media&token=01bbcbca-3cc7-4c36-a4af-8ea01bec181d",
    "electronics_microwave_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F056-microwave.svg?alt=media&token=bd619e8c-4b0d-4dad-b024-a095cdbf40c0",
    "electronics_tv_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F057-tv.svg?alt=media&token=2b23aa22-4c9c-41a6-b07e-3713b8d2cef6",
    "electronics_chip_3": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F058-chip.svg?alt=media&token=5a8da06d-907e-46cf-8371-38597dda8dea",
    "electronics_speaker_1": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F059-speaker.svg?alt=media&token=de21f218-9961-48a6-bcf0-6aa2bea37139",
    "electronics_toaster_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F060-toaster.svg?alt=media&token=c22c847f-3ced-4619-a839-72933a81f407",
    "electronics_clock_2": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F061-clock.svg?alt=media&token=a19e2135-9a44-41b2-8353-dbb044950c84",
    "electronics_player_1": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F062-music%20player.svg?alt=media&token=1c4c893c-4049-4f5c-8ca8-84236da76ec3",
    "electronics_headphone_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F063-headphone.svg?alt=media&token=9e0f518a-d19f-48fb-b86f-bb5421a55474",
    "electronics_printer_1": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F064-printer.svg?alt=media&token=5d780b1f-165e-4f2c-9732-b8be1f10b1f9",
    "electronics_torch_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F066-torch.svg?alt=media&token=3f5bf683-26b9-426b-877d-8971be239774",
    "electronics_cctv_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F067-cctv.svg?alt=media&token=deba256e-e618-4699-89de-fe8122e65a94",
    "electronics_system_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F068-solar%20system.svg?alt=media&token=d6ebb2d4-cfab-4bb0-8a5d-29e73d0c140b",
    "electronics_monitor_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F069-monitor.svg?alt=media&token=694b4df8-01de-4114-85e1-db1cea33f444",
    "electronics_robot_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F070-robot.svg?alt=media&token=7997d0b2-bf06-456b-a742-1e3ceca67318",
    "electronics_trimmer_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F071-trimmer.svg?alt=media&token=4fb5049a-7c5f-4d35-ac7e-9fe05e8a2644",
    "electronics_scale_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F072-weight%20scale.svg?alt=media&token=5343733f-7f6d-45c0-9dd8-2bbad70a7da1",
    "electronics_server_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F073-server.svg?alt=media&token=185da3cd-21a2-49cc-bc59-277df0192405",
    "electronics_bluetooth_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F074-bluetooth.svg?alt=media&token=1c0b3b6e-11ea-45d9-8376-883be6163553",
    "electronics_clock_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F075-wall%20clock.svg?alt=media&token=abc6de68-6872-419b-99f1-9c5ebf595739",
    "electronics_conditioning_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F076-air%20conditioning.svg?alt=media&token=766def7a-cd0f-4e86-bb74-a6f01ca2137e",
    "electronics_chip_0": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F077-chip.svg?alt=media&token=8837a121-280c-44e3-baa7-aed6f9b8a0ad",
    "electronics_chip_1": "https://firebasestorage.googleapis.com/v0/b/ae-pcmania.appspot.com/o/categories%2Ficons%2F078-chip.svg?alt=media&token=efdaff5b-71c9-4978-9a05-0e8e629df795"
}