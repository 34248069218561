import { OrdersService } from 'src/app/services/orders.service';
import {
  OrderExpand,
  orderPaymentStatusesTranslations,
  orderStatusesTranslations,
} from './../../models/Order';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { noImageUrl } from '../../constants/images.constant';
import {
  paymentMethodsTranslationsType,
  paymentStatusesTranslations,
} from 'src/app/models/Payment';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();

  @Input() order: OrderExpand;
  fromComponent: boolean;

  noImageUrl = noImageUrl;

  orderPaymentStatusesTranslations = orderPaymentStatusesTranslations;
  orderStatusesTranslations = orderStatusesTranslations;
  paymentMethodsTranslationsType = paymentMethodsTranslationsType;

  constructor(
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private orderService: OrdersService
  ) {}

  ngOnInit(): void {
    if (!this.order) {
      this.loaderService.showLoader();
      this.activatedRoute.params
        .pipe(
          switchMap((param) => {
            const orderId = param.id;
            return this.orderService.getOrderById(orderId);
          }),
          takeUntil(this.destroyed$)
        )
        .subscribe((order) => {
          this.fromComponent = true;
          this.order = order;
          console.log('order:', this.order);
          this.loaderService.hideLoader();
        });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  capitalizeString(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  getSubtotalPrice(): number {
    return this.order.cart.products.reduce(
      (acum, val) => acum + val.quantity * val.salePrice,
      0
    );
  }

  handleImageError(e) {
    e.target.src = noImageUrl;
  }
}
