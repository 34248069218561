// Modules
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { ProductDetailsModule } from './modules/product-details/product-details.module';
// Components
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DropdownActionsComponent } from './components/dropdown-actions/dropdown-actions.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DialogModalComponent } from './components/dialog-modal/dialog-modal.component';
// Services
import { ProductsService } from './services/products.service';
import { LoaderService } from './services/loader.service';
import { DummyService } from './services/dummy.service';
import { CommentsService } from './services/comments.service';
import { CartService } from './services/cart.service';
import { AuthService } from './services/auth.service';
import { CategoriesService } from './services/categories.service';
// Others
import { environment } from '../environments/environment';
import { SharedModule } from './modules/shared/shared.module';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { AllCategoriesModule } from './modules/all-categories/all-categories.module';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    NavbarComponent,
    FooterComponent,
    NotFoundComponent,
    DropdownActionsComponent,
    LoaderComponent,
    DialogModalComponent,
    ClickOutsideDirective,
  ],
  imports: [
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:5000',
    }),
    BrowserModule,
    BrowserAnimationsModule,

    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SimpleNotificationsModule.forRoot({
      showProgressBar: true,
      pauseOnHover: true,
      timeOut: 3000,
    }),
    MatDialogModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCarouselModule.forRoot(),
    SharedModule,
    ProductDetailsModule,
    AllCategoriesModule,
  ],
  providers: [
    AuthService,
    CartService,
    CategoriesService,
    CommentsService,
    DummyService,
    LoaderService,
    ProductsService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogModalComponent],
})
export class AppModule {}
