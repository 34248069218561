<div class="product-details container" *ngIf="product && productBreadcrumb !== undefined">

  <div *ngIf="productBreadcrumb" class="product-details__breadcrumb">
    <div 
      *ngFor="let item of productBreadcrumb.items; let i = index; let c = count" 
      class="product-details__breadcrumb__item"
      [ngClass]="{ 'last': i === c - 1 }"
    >
      <a 
        [routerLink]="['/products']"
        [queryParams]="{catg: item.categoryId, subcatg: item.subcategoryId}"
        *ngIf="i < c - 1"
      >
        {{ item.label }} <span>/</span>&nbsp;
      </a>
      <span *ngIf="i === c - 1">
        {{ item.label }}
      </span>
    </div>
  </div>
  <main class="product-details__information">
    <div class="product-details__information__images">
      <div class="product-details__information__images__smalls">
        <ng-container *ngFor="let image of allImagesPaths">
          <div class="image" [ngClass]="{selected: currentImagePath === image}" (click)="currentImagePath = image">
            <img [src]="image" [alt]="product.name">
          </div>
        </ng-container>
      </div>
      <div class="product-details__information__images__big">
        <app-zoom-image [noImageZoom]="noImageZoom" [image]="currentImagePath" [alt]="product.name"></app-zoom-image>
      </div>
    </div>
    <div class="product-details__information__text">
      <app-rating-stars [pointsIn]="product | starsRating" [onlyView]="true">
      </app-rating-stars>
      <p class="reviews">{{product.timesRated > 0 ? product.timesRated + ' Calificaciones' : 'Sin Calificaciones'}}</p>

      <h1 class="name">
        {{product.name}}
        <div *ngIf="discountPercentage" class="discount-container">
          <span class="text">
            -{{ discountPercentage | number : '1.0-0' }}%
          </span>
        </div>
      </h1>
      <p class="price">
        <span [ngClass]="{ 'discounted': discountPercentage }">{{productPrice | currency}}</span>
        <span *ngIf="discountPercentage" class="price-with-discount">{{ productPrice | discount : discountPercentage | currency }}</span>
      </p>

      <div class="quantity">
        <p class="title" *ngIf="maxQuantityToAdd > 0 && product.active">Cantidad</p>
        <div class="number-button">
          
          <div class="select-container margin-right">
            <ng-container *ngIf="maxQuantityToAdd > 0 && product.active">
              <svg-icon class="icon-container" [class]="'icon'" [applyClass]="true"
                src="../../../assets/images/icons/arrow-down.svg">
              </svg-icon>
              <select [(ngModel)]="selectedQuantity" class="height">
                <ng-container *ngFor="let a of Arr(maxQuantityToAdd).fill(1); let i = index">
                  <option [ngValue]="i+1" selected>{{i+1}}</option>
                </ng-container>
              </select>
            </ng-container>
            <ng-container *ngIf="maxQuantityToAdd <= 0 && productQuantity !== 0 && product.active">
              <p class="max-message">Max items on cart</p>
            </ng-container>
            <ng-container *ngIf="productQuantity === 0 || !product.active">
              <p class="max-message">Product out of stock</p>
            </ng-container>
          </div>
          <div class="button-container">
            <button class="btn" (click)="addToCart()" [disabled]="maxQuantityToAdd <= 0 || !product.active">Añadir al carrito</button>
            <div class="quantity-on-cart" [ngClass]="{show: quantityOnCart > 0}">
              <span>{{quantityOnCart}}</span> on cart
            </div>
          </div>

        </div>
      </div>

      
      
      <div class="variations" *ngIf="hasVariations">
        <ng-container *ngFor="let variation of product.variations; let i = index">
          <div class="variations__variation">
            <p class="title">{{capitalizeString(variation.name)}}</p>
            <div class="variations__variation__options">
              <ng-container *ngFor="let option of variation.options">
                <div class="option"
                  (click)="changeVariation(option, i, !someVariationAvailableWithAllOptions(option, i))"
                  [ngClass]="{selected: selectedVariationPath[i] === option, disabled: !someVariationAvailableWithAllOptions(option, i), noForLastSelected: !someVariationWithLastSelectedOption(option, i)}">
                  {{capitalizeString(option)}}
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>



    </div>
  </main>

  <div class="product-details__description">
    <h2 class="title">Descripción de producto</h2>
    <p class="description" [innerHtml]="product.description"></p>
  </div>

  <br><br><br>
  <div class="product-details__related">
    <h2 class="title">Productos relacionados</h2>

    <ng-container *ngIf="relatedProducts?.length === 0">
      <p class="description">
        No hay productos relacionados para mostrar
      </p>
    </ng-container>

    <div *ngIf="relatedProducts; else loadingRelatedProducts" class="product-details__related__grid">
      <ng-container *ngFor="let product of relatedProducts">
        <app-card-product 
          [type]="'two'" 
          [product]="product"
          [discountPercentage]="productsInOffer[product.id]"
        >
        </app-card-product>
      </ng-container>
    </div>

    <ng-template #loadingRelatedProducts>
      <br>
      <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
      </div>
      <br>
    </ng-template>
  </div>

  <ng-container *ngIf="product.canComment && !actualUser">
    <p class="login-to-comment-text">
      Debes iniciar sesión para escribir reseñas
    </p>
  </ng-container>
  <ng-container *ngIf="product.canComment">
    
  </ng-container>
  <ng-container *ngIf="product.canComment && actualUser">
    <app-comment-area [productId]="product?.id" [userId]="actualUser?.id"></app-comment-area>
  </ng-container>
</div>
