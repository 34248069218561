import { ProductCartExpand } from './../models/Cart';
import { CartExpand, Cart, ProductCart } from '../models/Cart';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { take, map, switchMap, flatMap } from 'rxjs/operators';
import { Client } from '../models/Client';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { NotificationsService } from 'angular2-notifications';
import { uniq } from 'lodash';
import { Product } from '../models/Product';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  localCart = new BehaviorSubject<Cart>({ products: [] });
  user: Client;
  prevAuthState: boolean = undefined;

  constructor(
    private authService: AuthService,
    private db: AngularFirestore,
    private notificationService: NotificationsService,
    private http: HttpClient
  ) {
    if (localStorage.getItem('cart')) {
      this.localCart.next(JSON.parse(localStorage.getItem('cart')));
    } else {
      this.localCart.next({ products: [] });
      localStorage.setItem('cart', JSON.stringify({ products: [] }));
    }

    this.authService.authUser$.subscribe((user) => {
      if (
        this.prevAuthState !== undefined &&
        this.prevAuthState !== true &&
        user
      ) {
        this.mergeCarts(user.id);
      }
      this.prevAuthState = user ? true : null;
    });
  }

  /**
   * Description: Gets the cart of actual user with the products info
   * @author Carlos Fontes
   */
  getCartWithProductInfo(userId: string): Observable<CartExpand> {
    const cartExpand$ = (
      userId
        ? this.db.doc<Cart>(`carts/${userId}`).valueChanges()
        : this.localCart
    ).pipe(
      switchMap((cart) => {
        const productsIds =
          uniq(cart.products.map((prod) => prod.productId)).length === 0
            ? ['null']
            : uniq(cart.products.map((prod) => prod.productId));

        return combineLatest([
          of(cart),
          combineLatest([
            ...productsIds.map((productId) =>
              this.db
                .collection('products')
                .doc<Product>(productId)
                .valueChanges()
                .pipe(
                  map((product) => {
                    return {
                      ...product,
                      id: productId,
                    };
                  })
                )
            ),
          ]),
        ]);
      }),
      map(([cart, products]) => {
        const cartToReturn = cart as CartExpand;

        for (const product of cartToReturn.products) {
          if (product.variationPath) {
            const prodOnBdd = (products as Product[]).find(
              (prod) => prod.id === product.productId
            );
            const combOfProduct = prodOnBdd.variationsComb.find(
              (comb) => comb.path === product.variationPath
            );

            product.product = {
              ...prodOnBdd,
              quantity: combOfProduct.quantity,
              price: combOfProduct.price,
              maxPerOrder: combOfProduct.maxPerOrder,
              imagesUrl: combOfProduct.imagesUrl,
            };
          } else {
            product.product = (products as Product[]).find(
              (prod) => prod.id === product.productId
            );
          }
        }

        return cartToReturn;
      })
    );

    return cartExpand$;
  }

  /**
   * Description: Add or sum the quantity of a product on cart
   * @author Carlos Fontes
   */
  changeQuantityOfProductOnCart(
    productCart: ProductCart | ProductCartExpand,
    maxPerOrder: number
  ): void {
    this.authService.authUser$
      .pipe(
        take(1),
        switchMap((actualUser) => {
          return combineLatest([
            (actualUser
              ? this.db.doc<Cart>(`carts/${actualUser.id}`).valueChanges()
              : this.localCart
            ).pipe(take(1)),
            of(actualUser),
          ]);
        })
      )
      .subscribe(([cartDoc = { products: [] }, actualUser]) => {
        let products = [...cartDoc.products];
        const productInCart = productCart.variationPath
          ? products.find(
              (item) =>
                item.productId === productCart.productId &&
                item.variationPath === productCart.variationPath
            )
          : products.find((item) => item.productId === productCart.productId);

        if (products[0] && productInCart && productCart.productId) {
          delete (productInCart as ProductCartExpand).product;
          if (!productInCart.variationPath) {
            delete productInCart.variationPath;
          }

          // Si el producto ya está en el carrito, se suma la cantidad
          const toAdd =
            maxPerOrder < productInCart.quantity + productCart.quantity
              ? maxPerOrder - productInCart.quantity
              : productCart.quantity;

          productInCart.quantity += toAdd;
          productInCart.updatedAt = new Date();

          // Si el producto se va a cero de cantidad, eliminarlo
          if (productInCart.quantity === 0) {
            products = products.filter(
              (prod) => prod.productId !== productInCart.productId
            );
          }
        } else {
          if (!productCart.variationPath) {
            delete productCart.variationPath;
          }
          products.push({
            ...productCart,
            createdAt: new Date(),
            updatedAt: new Date(),
          });
        }

        actualUser
          ? this.updateBddCart(products, actualUser.id)
          : this.updateLocalCart(products);
      });
  }

  /**
   * Description: Gets quantity of an item in the cart
   * @author Carlos Fontes
   */
  getQuantityOfProductInCart(
    productId: string,
    userId: string,
    variationPath?: string
  ): Observable<number> {
    const quantity$ = (
      userId
        ? this.db.doc<Cart>(`carts/${userId}`).valueChanges()
        : this.localCart
    ).pipe(
      map((cart = { products: [] }) => {
        const products: ProductCart[] = cart.products;
        let quantityProduct = 0;

        if (variationPath) {
          quantityProduct =
            (products &&
              products.length !== 0 &&
              products.find(
                (prod) =>
                  prod.productId === productId &&
                  prod.variationPath === variationPath
              ) &&
              products.find(
                (prod) =>
                  prod.productId === productId &&
                  prod.variationPath === variationPath
              ).quantity) ||
            0;
        } else {
          quantityProduct =
            (products &&
              products.length !== 0 &&
              products.find((prod) => prod.productId === productId) &&
              products.find((prod) => prod.productId === productId).quantity) ||
            0;
        }

        return quantityProduct;
      })
    );

    return quantity$;
  }

  /**
   * Description: Gets quantity of all items on cart
   * @author Carlos Fontes
   */
  getTotalQuantityOnCart(userId: string): Observable<number> {
    const quantity$ = (
      userId
        ? this.db.doc<Cart>(`carts/${userId}`).valueChanges()
        : this.localCart
    ).pipe(
      map((cart = { products: [] }) => {
        const products: ProductCart[] = cart.products;

        const quantityOnCart =
          (products &&
            products.length !== 0 &&
            products.reduce((sum, act) => sum + act.quantity, 0)) ||
          0;
        return quantityOnCart;
      })
    );

    return quantity$;
  }

  /**
   * Description: Merge the localstorage cart with the bdd cart
   * @author Carlos Fontes
   */
  mergeCarts(userId: string): void {
    // console.log(
    //   '%c MERGE CARTS',
    //   'font-weight: bold; font-size: 50px; color: red; text - shadow: 3px 3px 0 rgb(217, 31, 38), 6px 6px 0 rgb(226, 91, 14), 9px 9px 0 rgb(245, 221, 8), 12px 12px 0 rgb(5, 148, 68), 15px 15px 0 rgb(2, 135, 206), 18px 18px 0 rgb(4, 77, 145), 21px 21px 0 rgb(42, 21, 113)'
    // );

    this.db
      .collection('carts')
      .doc<Cart>(userId)
      .valueChanges()
      .pipe(take(1))
      .subscribe((userCart) => {
        this.localCart.pipe(take(1)).subscribe((localCart) => {
          const bddProducts = [...userCart.products];

          for (const prod of localCart.products) {
            // Busco cada producto del local en el del usuario
            const prodInCart = bddProducts.find((p) => {
              return p.variationPath
                ? p.productId === prod.productId &&
                    p.variationPath === prod.variationPath
                : p.productId === prod.productId;
            });

            if (prodInCart) {
              // Si existe agarro la cantidad del local
              prodInCart.quantity = prod.quantity;
            } else {
              // Si no existe lo agrego por completo
              bddProducts.push(prod);
            }
          }

          this.updateLocalCart([]);
          this.updateBddCart(bddProducts, userId);
        });
      });
  }

  /**
   * Description: Deletes cart of actual user on BDD
   * @author Carlos Fontes
   */
  async deleteCart(): Promise<any> {
    const user = await this.authService.authUser$.pipe(take(1)).toPromise();

    return this.db
      .doc<Cart>(`carts/${user.id}`)
      .set({ products: [] }, { merge: true });
  }

  /**
   * Description: Refresh localCart value
   * @author Carlos Fontes
   */
  private updateLocalCart(products: ProductCart[]): void {
    this.localCart.next({ products });
    localStorage.setItem('cart', JSON.stringify({ products }));
    this.notificationService.success('Carrito actualizado')
  }

  /**
   * Description: Refresh localCart value
   * @author Carlos Fontes
   */
  private updateBddCart(products: ProductCart[], userId: string): void {
    const cartsRef: AngularFirestoreDocument<Cart> = this.db.doc(
      `carts/${userId}`
    );

    cartsRef
      .set({ products, expiredEmailSent: false }, { merge: true })
      .then(() => this.notificationService.success('Carrito actualizado'))
      .catch((err) => this.notificationService.error('Error al actualizar el carrito'));
  }
  async sendExpiredCartEmail(clientId: string): Promise<any> {
    const email = (
      await this.db.doc<Client>(`clients/${clientId}`).get().toPromise()
    ).data().email;
    // this.http
    //   .post(environment.functionsUrl + '/add', {
    //     data: { email },
    //   })
    //   .toPromise();
    return email;
  }
}
