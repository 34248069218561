import { Component, OnInit, Input, HostBinding, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-zoom-image',
  templateUrl: './zoom-image.component.html',
  styleUrls: ['./zoom-image.component.scss']
})
export class ZoomImageComponent implements OnInit {

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setSizes();
  }

  @Input() image: string;
  @Input() alt: string;
  @Input() noImageZoom = false;

  @HostBinding('class') class = 'img-zoom-lens';
  @ViewChild('thumbnail', { static: false }) thumbnail: ElementRef;
  resizeTimer: any;
  actualImageSlider: number;
  img: HTMLImageElement;
  lens: Element;
  result: Element;
  cx: number;
  cy: number;

  constructor() { }

  ngOnInit(): void {
  }

  private setSizes() {
    (this.lens as HTMLElement).setAttribute(
      'style',
      `position: absolute; border: 0.5px solid #ff300080; visibility: hidden; width: ${this.img.width / 4}px; height: ${this.img.width / 4}px;`
    );

    (this.lens as HTMLElement).style.backgroundImage = 'url(\'' + '../../../assets/images/lens/lens-grid.svg' + '\')';
    /* Insert lens: */
    this.img.parentElement.insertBefore(this.lens, this.img);
    // console.log("img sizes", (this.img as HTMLElement).offsetWidth, (this.img as HTMLElement).offsetHeight);

    /* Calculate the ratio between result DIV and lens: */
    this.cx = (this.result as HTMLElement).offsetWidth / (this.lens as HTMLElement).offsetWidth;
    this.cy = (this.result as HTMLElement).offsetHeight / (this.lens as HTMLElement).offsetHeight;
    /* Set background properties for the result DIV */
    (this.result as HTMLElement).style.backgroundImage = 'url(\'' + this.image + '\')';
    (this.result as HTMLElement).style.backgroundSize = (this.img.width * this.cx) + 'px ' + (this.img.height * this.cy) + 'px';
  }

  imageZoom(resultID: string): void {

    if (this.lens != null) {
      this.lens.remove();
      this.resetResult();
    }

    this.img = this.thumbnail.nativeElement;
    if (window.screen.width >= 500 && this.image) {
      this.result = document.getElementById(resultID);
      this.lens = document.createElement('div');

      (this.lens as HTMLElement)
        .setAttribute('style', 'position: absolute; border: 0.5px solid #ff300080; visibility: hidden');
      // if (this.img.width > 300 && this.img.height > 300) {
      //   (this.lens as HTMLElement).setAttribute(
      //     'style',
      //     'position: absolute; border: 0.5px solid #ff300080; visibility: hidden; width: 300px; height: 300px;'
      //   );
      // } else {
      //   (this.lens as HTMLElement).setAttribute(
      //     'style',
      //     `position: absolute; border: 0.5px solid #ff300080; visibility: hidden; width: ${this.img.width / 5}px; height: ${this.img.width / 5}px;`
      //   );
      // }
      this.setSizes();
      /* Execute a function when someone moves the cursor over the image, or the lens: */
      this.lens.addEventListener('mousemove', this.moveLens.bind(this));
      this.img.addEventListener('mousemove', this.moveLens.bind(this));
      this.lens.addEventListener('mouseleave', this.killLens.bind(this));
      /* And also for touch screens: */
      this.lens.addEventListener('touchmove', this.moveLens.bind(this));
      this.img.addEventListener('touchmove', this.moveLens.bind(this));
    }
  }

  moveLens(e: any): void {
    let pos: { x: number, y: number };
    let x: number;
    let y: number;

    e.preventDefault();
    /* Get the cursor's x and y positions: */
    pos = this.getCursorPos(e);
    /* Calculate the position of the lens: */
    x = pos.x - ((this.lens as HTMLElement).offsetWidth / 2);
    y = pos.y - ((this.lens as HTMLElement).offsetHeight / 2);
    /* Prevent the lens from being positioned outside the image: */
    if (x > this.img.width - (this.lens as HTMLElement).offsetWidth) { x = this.img.width - (this.lens as HTMLElement).offsetWidth; }
    if (x < 0) { x = 0; }
    if (y > this.img.height - (this.lens as HTMLElement).offsetHeight) { y = this.img.height - (this.lens as HTMLElement).offsetHeight; }
    if (y < 0) { y = 0; }
    /* Set the position of the lens: */
    (this.lens as HTMLElement).style.left = x + 'px';
    (this.lens as HTMLElement).style.top = y + 'px';
    /* Display what the lens "sees": */
    (this.result as HTMLElement).style.backgroundPosition = '-' + (x * this.cx) + 'px -' + (y * this.cy) + 'px';
    (this.lens as HTMLElement).style.visibility = 'visible';
    this.result.classList.remove('hide');
    this.result.classList.add('show');
  }

  getCursorPos(e): { x: number, y: number } {
    let a: any;
    let x = 0;
    let y = 0;
    // tslint:disable-next-line: deprecation
    e = e || window.event;
    /* Get the x and y positions of the image: */
    a = this.img.getBoundingClientRect();
    /* Calculate the cursor's x and y coordinates, relative to the image: */
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /* Consider any page scrolling: */
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x, y };
  }

  killLens(): void {
    // const elem = document.getElementsByClassName('img-zoom-lens')[0] as HTMLElement;
    (this.lens as HTMLElement).style.visibility = 'hidden';

    this.result.classList.remove('show');
    this.result.classList.add('hide');
  }

  resetResult(): void {
    (this.result as HTMLElement).style.backgroundImage = null;
    (this.result as HTMLElement).style.backgroundSize = null;
    this.killLens();
  }

}
