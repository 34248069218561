<div class="dropdown" (click)="showActions = !showActions">
  <p class="title">{{ title }} ▾</p>

  <div class="dropdown__actions" [ngClass]="{ show: showActions }">
    <ng-container *ngFor="let action of actions">
      <div 
        class="action-item-container" 
        (click)="executeEvent(action)"
        (mousedown)="handleActionMouseDown(actions, 'navbar_user_actions', 'Navbar user actions')"
      >
        <p class="action-item">
          {{ action.name }}
        </p>
      </div>
    </ng-container>
  </div>
</div>

<div class="overlay" *ngIf="showActions" (click)="showActions = false"></div>
