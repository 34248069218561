import { Payment } from './../models/Payment';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, of, combineLatest } from 'rxjs';
import { Client } from 'src/app/models/Client';
import { Order, OrderExpand } from './../models/Order';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { uniq } from 'lodash';
import { Product } from '../models/Product';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(
    private db: AngularFirestore,
    private authService: AuthService,
    private router: Router,
    private notificationsService: NotificationsService,
    private http: HttpClient
  ) {}

  generateOrder(order: Order): Promise<any> {
    return this.db
      .doc<Client>(`clients/${order.clientId}`)
      .collection<Order>('orders')
      .add(order)
      .then((a) => {
        this.router.navigate(['create-order']);
      });
  }

  hasInProgressOrder(): Observable<Observable<boolean>> {
    return this.authService.authUser$.pipe(
      map((user) => {
        if (user) {
          return this.db
            .doc<Client>(`clients/${user.id}`)
            .collection<Order>('orders', (ref) =>
              ref.where('orderStatus', '==', 'IN-PROGRESS')
            )
            .valueChanges()
            .pipe(
              map((orders) => {
                if (orders && orders.length !== 0) {
                  return true;
                } else {
                  return false;
                }
              })
            );
        } else {
          return of(false);
        }
      })
    );
  }

  getInProgressOrder(): Observable<OrderExpand> {
    return this.authService.authUser$.pipe(
      switchMap((user) => {
        return this.db
          .doc<Client>(`clients/${user.id}`)
          .collection<Order>('orders', (ref) => {
            return ref.where('orderStatus', '==', 'IN-PROGRESS').limit(1);
          })
          .valueChanges({ idField: 'id' });
      }),
      switchMap((order) => {
        const productsIds =
          !order[0] ||
          uniq(order[0].cart.products.map((prod) => prod.productId)).length ===
            0
            ? ['null']
            : uniq(order[0].cart.products.map((prod) => prod.productId));

        return combineLatest([
          of(order[0]),
          combineLatest([
            ...productsIds.map((productId) =>
              this.db
                .collection('products')
                .doc<Product>(productId)
                .valueChanges()
                .pipe(
                  map((product) => {
                    return {
                      ...product,
                      id: productId,
                    };
                  })
                )
            ),
          ]),
        ]);
      }),
      map(([order, products]) => {
        if (!order) {
          return null;
        }
        const orderToReturn = order as OrderExpand;

        for (const product of orderToReturn.cart.products) {
          if (product.variationPath) {
            const prodOnBdd = (products as Product[]).find(
              (prod) => prod.id === product.productId
            );
            const combOfProduct = prodOnBdd.variationsComb.find(
              (comb) => comb.path === product.variationPath
            );

            product.product = {
              ...prodOnBdd,
              quantity: combOfProduct.quantity,
              price: combOfProduct.price,
              maxPerOrder: combOfProduct.maxPerOrder,
              imagesUrl: combOfProduct.imagesUrl,
            };
          } else {
            product.product = (products as Product[]).find(
              (prod) => prod.id === product.productId
            );
          }
        }

        return orderToReturn;
      })
    );
  }

  getOrderById(orderId: string): Observable<OrderExpand> {
    return this.authService.authUser$.pipe(
      switchMap((user) => {
        return this.db
          .doc<Client>(`clients/${user.id}`)
          .collection('orders')
          .doc<Order>(orderId)
          .valueChanges();
      }),
      switchMap((order) => {
        const productsIds =
          !order ||
          uniq(order.cart.products.map((prod) => prod.productId)).length === 0
            ? ['null']
            : uniq(order.cart.products.map((prod) => prod.productId));

        return combineLatest([
          of(order),
          combineLatest([
            ...productsIds.map((productId) =>
              this.db
                .collection('products')
                .doc<Product>(productId)
                .valueChanges()
                .pipe(
                  map((product) => {
                    return {
                      ...product,
                      id: productId,
                    };
                  })
                )
            ),
          ]),
        ]);
      }),
      map(([order, products]) => {
        if (!order) {
          return null;
        }
        const orderToReturn = order as OrderExpand;

        for (const product of orderToReturn.cart.products) {
          if (product.variationPath) {
            const prodOnBdd = (products as Product[]).find(
              (prod) => prod.id === product.productId
            );
            const combOfProduct = prodOnBdd.variationsComb.find(
              (comb) => comb.path === product.variationPath
            );

            product.product = {
              ...prodOnBdd,
              quantity: combOfProduct.quantity,
              price: combOfProduct.price,
              maxPerOrder: combOfProduct.maxPerOrder,
              imagesUrl: combOfProduct.imagesUrl,
            };
          } else {
            product.product = (products as Product[]).find(
              (prod) => prod.id === product.productId
            );
          }
        }

        return orderToReturn;
      })
    );
  }

  getAllOrders(): Observable<OrderExpand[]> {
    return this.authService.authUser$.pipe(
      switchMap((user) => {
        return this.db
          .doc<Client>(`clients/${user.id}`)
          .collection<Order>('orders', (ref) =>
            ref.orderBy('createdAt', 'desc')
          )
          .valueChanges({ idField: 'id' });
      }),
      switchMap((orders) => {
        let productsIds = [];

        if (!orders || orders.length === 0) {
          productsIds = ['null'];
        } else {
          let productsIdsAll = [];
          for (const order of orders) {
            productsIdsAll = [
              ...productsIdsAll,
              ...order.cart.products.map((prod) => prod.productId),
            ];
          }
          productsIds =
            productsIdsAll.length === 0 ? ['null'] : uniq(productsIdsAll);
        }

        return combineLatest([
          of(orders),
          combineLatest([
            ...productsIds.map((productId) =>
              this.db
                .collection('products')
                .doc<Product>(productId)
                .valueChanges()
                .pipe(
                  map((product) => {
                    return {
                      ...product,
                      id: productId,
                    };
                  })
                )
            ),
          ]),
        ]);
      }),
      map(([orders, products]) => {
        if (!orders) {
          return null;
        }
        const ordersToReturn = orders as OrderExpand[];

        for (const order of ordersToReturn) {
          order.cart.products = order.cart.products.map((prod) => {
            if (prod.variationPath) {
              const prodOnBdd = products.find(
                (prd) => prd.id === prod.productId
              );
              const combOfProduct = prodOnBdd.variationsComb.find(
                (comb) => comb.path === prod.variationPath
              );

              return {
                ...prod,
                product: {
                  ...prodOnBdd,
                  quantity: combOfProduct.quantity,
                  price: combOfProduct.price,
                  maxPerOrder: combOfProduct.maxPerOrder,
                  imagesUrl: combOfProduct.imagesUrl,
                },
              };
            } else {
              return {
                ...prod,
                product: products.find((prd) => prd.id === prod.productId),
              };
            }
          });
        }

        return ordersToReturn;
      })
    );
  }

  async updateOrder(
    orderId: string,
    orderField: string,
    data: any
  ): Promise<any> {
    const user = await this.authService.authUser$.pipe(take(1)).toPromise();

    const orderRef: AngularFirestoreDocument<Order> = this.db
      .doc<Client>(`clients/${user.id}`)
      .collection<Order>('orders')
      .doc<Order>(orderId);

    const order = {} as Order;
    order[orderField] = data;

    return orderRef.set(order, { merge: true });
  }

  async updateOrderWithObject(orderId: string, data: any): Promise<any> {
    const user = await this.authService.authUser$.pipe(take(1)).toPromise();

    const orderRef: AngularFirestoreDocument<Order> = this.db
      .doc<Client>(`clients/${user.id}`)
      .collection<Order>('orders')
      .doc<Order>(orderId);

    const order = { ...data } as Order;

    return orderRef.update(order);
  }

  async sendClientOrderStatusEmail(
    order: OrderExpand,
    status: 'PENDING' | 'CANCELLED' | 'SHIPPED'
  ): Promise<any> {
    const email = (
      await this.db.doc<Client>(`clients/${order.clientId}`).get().toPromise()
    ).data().email;
    const client = (
      await this.db.doc<Client>(`clients/${order.clientId}`).get().toPromise()
    ).data();
    let ci = client.ci;
    let orderNumber = order.orderNumber;
    this.http
      .post(environment.functionsUrl + '/sendClientOrderStatusEmail', {
        data: { email, status, ci, orderNumber },
      })
      .toPromise();
  }

  async sendEcommerceOrderStatusEmail(
    order: OrderExpand,
    status: 'PENDING' | 'CANCELLED' | 'SHIPPED'
  ): Promise<any> {
    const client = (
      await this.db.doc<Client>(`clients/${order.clientId}`).get().toPromise()
    ).data();
    const clientId = (
      await this.db.doc<Client>(`clients/${order.clientId}`).get().toPromise()
    ).id;
    let email = client.email;
    let firstName = client.firstName;
    let lastName = client.lastName;
    const ci = (
      await this.db.doc<Client>(`clients/${order.clientId}`).get().toPromise()
    ).data().ci;
    let orderNumber = order.orderNumber;
    this.http
      .post(environment.functionsUrl + '/sendEcommerceOrderStatusEmail', {
        data: { clientId, email, status, firstName, lastName, ci, orderNumber },
      })
      .toPromise();
  }

  async generatePayment(payment: any): Promise<void> {
    return this.db
      .collection('payments')
      .add(payment)
      .then((r) => {
        this.notificationsService.success(
          'El pago ha sido enviado exitosamente.'
        );
      })
      .catch((e) => {
        console.log(e);
        this.notificationsService.error('Ha ocurrido un error.');
      });
  }

  productIsAlreadyPurchased(
    productId: string,
    userId: string
  ): Observable<boolean> {
    return this.db
      .doc<Client>(`clients/${userId}`)
      .collection<Order>('orders', (ref) =>
        ref.where('orderStatus', '==', 'FULFILLED')
      )
      .valueChanges({ idField: 'id' })
      .pipe(
        map((dbOrder) => {
          if (!dbOrder || dbOrder.length === 0) {
            return false;
          }

          for (const order of dbOrder) {
            for (const prod of order.cart.products) {
              if (prod.productId === productId) {
                return true;
              }
            }
          }

          return false;
        })
      );
  }
}
