import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { Category } from 'src/app/models/Category';
import { CategoriesService } from './../../../services/categories.service';
import { LoaderService } from './../../../services/loader.service';
import { CATEGORIES_ICONS_DICT } from "../../../constants/icons.constant";
import { AnalyticsService } from 'src/app/services/analytics.service';

@Component({
  selector: 'app-all-categories',
  templateUrl: './all-categories.component.html',
  styleUrls: ['./all-categories.component.scss']
})
export class AllCategoriesComponent implements OnInit {

  categories: Category[];

  CATEGORIES_ICONS_DICT = CATEGORIES_ICONS_DICT;

  constructor(
    private categoriesService: CategoriesService,
    private loaderService: LoaderService,
    private analyticsService: AnalyticsService,
  ) { }

  private async setAllCategories() {
    return this.categoriesService.getCategories().pipe(take(1)).toPromise()
      .then((categories) => {
        this.categories = categories;
      })
  }

  async ngOnInit() {
    this.loaderService.showLoader();
    await this.setAllCategories();
    this.loaderService.hideLoader();

    if (this.categories) {
      this.analyticsService.triggerViewItemListEvent(
        this.categories.map((category, index) => ({ item_id: category.id || "", item_name: category.name, index })),
        "categories_view",
        "Categories view",
      )
    }
  }

}
