import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { CouponsService } from './../../../services/coupons.service';
import { CountryData, StateData } from './../../../models/CountryData';
import { OrderExpand } from './../../../models/Order';
import { OrdersService } from './../../../services/orders.service';
import { EcommerceVarsService } from './../../../services/ecommerce-vars.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { CartExpand, ProductCartOrder } from 'src/app/models/Cart';
import { take, takeUntil } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { Order } from 'src/app/models/Order';
import { AnalyticsService } from '../../../services/analytics.service';
import { noImageUrl } from '../../../constants/images.constant';
import { CurrenciesDictionary } from 'src/app/models/Currency';
import { ProductsInOffer } from 'src/app/models/Offer';
import { DiscountPipe } from '../../../pipes/discount.pipe';
import { OffersService } from 'src/app/services/offers.service';
import { AuthService } from 'src/app/services/auth.service';
import { Client } from 'src/app/models/Client';

@Component({
  selector: 'app-cart-purchase-panel',
  templateUrl: './cart-purchase-panel.component.html',
  styleUrls: ['./cart-purchase-panel.component.scss']
})
export class CartPurchasePanelComponent implements OnInit, OnChanges, OnDestroy {
  private destroyed$ = new Subject<boolean>();

  @Input() cart: CartExpand;
  @Input() order: OrderExpand;
  @Input() onCartView: boolean;
  @Input() clientId: string;
  @Output() buttonClick = new EventEmitter();

  loaded: boolean;
  flatDeliveryPrice: number;
  countries: CountryData[];
  couponCode: string;

  noImageUrl = noImageUrl;

  private statesByCountry 
  : { [countryName: string]: StateData[] }
  = {}

  @Input() currenciesDictionary: CurrenciesDictionary;

  @Input() productsInOffer: ProductsInOffer = {};

  creatingOrder = false;

  client: Client;

  @Input() taxesActive: boolean = null;

  constructor(
    private router: Router,
    private ecommerceVars: EcommerceVarsService,
    private ordersService: OrdersService,
    private couponsService: CouponsService,
    private notificationsService: NotificationsService,
    private analyticsService: AnalyticsService,
    private discount: DiscountPipe,
    private offersService: OffersService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.cart = { ...this.cart };

    combineLatest([
      this.ecommerceVars.getDeliveryPrice().pipe(take(1)),
      this.ecommerceVars.getCountryWithStates().pipe(take(1)),
      this.authService.authUser$.pipe(take(1)),
    ]).toPromise()
    .then(([deliveryprice, countries, client]) => {
      this.flatDeliveryPrice = deliveryprice;
      this.countries = countries;
      this.loaded = true;

      this.client = client;

      const _statesByCountry = {};
      countries.forEach((country) => {
        _statesByCountry[country.name] = country.states;
      });

      this.statesByCountry = _statesByCountry;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  ngOnChanges(): void {
    if (!this.onCartView && this.order.shippingAddress && this.taxesPrice !== undefined) {
      if (!this.order.taxesPrice || this.order.taxesPrice !== this.taxesPrice) {
        this.ordersService.updateOrder(this.order.id, 'taxesPrice', this.taxesPrice);
        this.ordersService.updateOrder(this.order.id, 'totalPrice', this.totalPrice);
      }
    }

    // Subscribe to coupon to verify if the coupon turns invalid
    if (!this.onCartView && this.order.usedCoupon) {
      this.couponsService.getValidCoupon(this.order.usedCoupon.id).pipe(takeUntil(this.destroyed$))
        .subscribe(validCoupon => {
          if (!validCoupon) {
            this.ordersService.updateOrderWithObject(this.order.id, {
              usedCoupon: null,
              discountPrice: 0
            });

            this.notificationsService.warn('The coupon is no longer available');
          }
        });
    }
  }

  capitalizeString(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  // calculateBill() {

  //   // SUBTOTAL
  //   if (this.onCartView) {
  //     this.subtotalPrice = this.cart.products
  //       .reduce((acum, val) => {
  //         return acum + (this.discount.transform(val.product?.price, this.productsInOffer[val.productId] || 0) * val.quantity);
  //       }, 0);
  //   } else {
  //     this.subtotalPrice = this.order.cart.products
  //       .reduce((acum, val) => {
  //         return acum + (this.discount.transform(val.salePrice, this.productsInOffer[val.productId] || 0) * val.quantity);
  //       }, 0);
  //   }

  //   // DISCOUNT
  //   if (this.onCartView) {
  //     this.discountPrice = 0;
  //   } else {
  //     this.discountPrice = this.order.discountPrice;
  //   }
  // }

  get subtotalPrice(): number {
    if (this.onCartView) {
      return this.cart.products
        .reduce((acum, val) => {
          return acum + (val.product?.price * val.quantity);
        }, 0);
    } else {
      return this.order.cart.products
        .reduce((acum, val) => {
          return acum + (val.salePrice * val.quantity);
        }, 0);
    }
  }

  get offersDiscountPrice(): number {
    if (this.onCartView) {
      return this.cart.products
      .reduce((acum, val) => {
        return acum + ((val.product?.price - this.discount.transform(val.product?.price, this.productsInOffer[val.productId] || 0)) * val.quantity);
      }, 0);
    } else {
      return this.order.offersDiscountPrice || 0;
    } 
  }

  get discountPrice(): number {
    if (this.onCartView) {
      return 0
    } else {
      return this.order.discountPrice;
    }
  }

  get totalPrice(): number {
    return this.subtotalPrice - this.discountPrice - this.offersDiscountPrice + (this.deliveryPrice || 0) + (this.taxesPrice || 0);
  }

  get deliveryPrice(): number {

    if (this.order) {
      return this.order.deliveryPrice || 0;
    } else {
      return undefined;
    }


    if (this.onCartView) {
      return this.cart.products.find(prod => !prod.product?.freeShipping) ? this.flatDeliveryPrice : 0;
    } else {
      return this.order.deliveryPrice;
    }
  }

  get taxesPrice(): number {

    if (this.taxesActive === false) return 0;

    try {      
      if (this.onCartView) {
        return undefined;
      } else {
        if (this.order.shippingAddress) {
          const taxesPriceCalc = this.statesByCountry[this.order.shippingAddress.country].find((state) => state.name === this.order.shippingAddress.state)?.taxes *
            (this.subtotalPrice - this.discountPrice - this.offersDiscountPrice) || undefined;
          return taxesPriceCalc;
        }
        return undefined;
      }
    } catch (error) {
      return 0;
    }

  }

  get cartLength(): number {
    if (this.onCartView) {
      return this.cart.products.reduce((acum, val) => acum + val.quantity, 0);
    } else {
      return this.order.cart.products.reduce((acum, val) => acum + val.quantity, 0);
    }
  }

  private async getAppliedOffersIds(productsIds: string[]): Promise<string[]> {

    const appliedOffers: string[] = [];

    try {
      const [ offers ] = await Promise.all([
        this.offersService.getActiveOffers().pipe(take(1)).toPromise(),
      ]);

      offers.forEach((offer) => {
        if (productsIds.includes(offer.productId)) {
          appliedOffers.push(offer.id);
        }
      });

      return Promise.resolve(appliedOffers);
    } catch (error) {
      return Promise.resolve([]);
    }
  }

  async onMakePurchaseClick() {
    if (this.onCartView && this.clientId && this.client) {
      this.creatingOrder = true;

      const newProductsCart: ProductCartOrder[] = (this.cart as CartExpand).products.map(prod => {
        const percentageDiscounted = this.productsInOffer[prod.productId] || 0;
        if (prod.variationPath) {
          return {
            productId: prod.productId,
            quantity: prod.quantity,
            salePrice: prod.product.price,
            variationPath: prod.variationPath,
            percentageDiscounted,
          };
        } else {
          return {
            productId: prod.productId,
            quantity: prod.quantity,
            salePrice: prod.product.price,
            percentageDiscounted,
          };
        }
      });

      const currenciesDictionary_detached = 
        JSON.parse(
          JSON.stringify(this.currenciesDictionary)
        );

      const appliedOffersIds = await this.getAppliedOffersIds(this.cart.products.map((prod) => prod.productId));

      const order: Order = {
        cart: { products: newProductsCart },
        createdAt: new Date(),
        orderNumber: +((Math.random() * 100000000000) + 1000000).toFixed(0),
        deliveryPrice: this.deliveryPrice || 0,
        discountPrice: this.discountPrice,
        offersDiscountPrice: this.offersDiscountPrice || 0,
        orderStatus: 'IN-PROGRESS',
        paymentStatus: 'NO-PAY',
        clientId: this.clientId,
        ratesSnapshot: {
          dict: currenciesDictionary_detached,
          list: Object.values(currenciesDictionary_detached),
        },
        totalPrice: this.totalPrice,
        appliedOffersIds,
        clientCi: this.client.ci || null,
        surveyEmailSent: false,
      };

      this.ordersService.generateOrder(order)
        .then(() => this.creatingOrder = false);

      this.analyticsService.triggerBeginCheckoutEvent(order);
    } else {
      this.router.navigate(['welcome']);
    }
  }

  cancelOrder(): void {
    if (this.order) {
      this.ordersService.updateOrder(this.order.id, 'orderStatus', 'CANCELLED');
    }
  }

  async verifyCoupon(): Promise<any> {
    if (this.couponCode && !this.onCartView) {
      const { valid, discount, coupon, error } =
        await this.couponsService.verifyCouponDiscount(this.couponCode, this.order.clientId, this.subtotalPrice);

      if (!valid) {
        this.notificationsService.error(error);
      } else {
        // Valid coupon, change order properties
        const oldUsedCoupon = this.order.usedCoupon;

        delete coupon.usedBy;
        this.ordersService.updateOrderWithObject(this.order.id, {
          usedCoupon: coupon,
          discountPrice: discount
        }).then(res => {
          if (oldUsedCoupon) {
            this.notificationsService.success('Cupón intruducido (solo 1 por orden)');
          } else {
            this.notificationsService.success('Cupón introducido');
          }
        });

        // Subscribe to coupon to verify if the coupon turns invalid
        this.couponsService.getValidCoupon(coupon.id).pipe(takeUntil(this.destroyed$))
          .subscribe(validCoupon => {
            if (!validCoupon) {
              this.ordersService.updateOrderWithObject(this.order.id, {
                usedCoupon: null,
                discountPrice: 0
              });

              this.notificationsService.warn('The coupon is no longer available');
            }
          });
      }
    }
  }

  handleImageError(e) {
    e.target.src = noImageUrl;
  }

}
