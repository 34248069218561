<div
  *ngIf="type === 'category'"
  class="all-categories-grid__item"
  [routerLink]="['/category/' + category.id]"
  [style]="'background-image: linear-gradient(0deg, rgba(0,0,0,0.4822303921568627) 0%, rgba(0,0,0,0.3533788515406162) 6%, rgba(0,0,0,0.23012955182072825) 20%, rgba(0,0,0,0) 100%), url(' + category.imageUrl + ')'"
>
  <p class="title">{{ category.name }}</p>
</div>

<div
  *ngIf="type === 'subcategory'"
  class="all-categories-grid__item"
  (click)="navigateToProductListing()"
  [style]="'background-image: linear-gradient(0deg, rgba(0,0,0,0.4822303921568627) 0%, rgba(0,0,0,0.3533788515406162) 6%, rgba(0,0,0,0.23012955182072825) 20%, rgba(0,0,0,0) 100%), url(' + category.imageUrl + ')'"
>
  <p class="title">{{ category.name }}</p>
</div>