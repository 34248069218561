import { Address } from './../models/Address';
import { Router } from '@angular/router';
import { Cart } from './../models/Cart';
import { switchMap, take, first } from 'rxjs/operators';
import { Client } from './../models/Client';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { auth } from 'firebase/app';
import { NotificationsService } from 'angular2-notifications';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // tslint:disable: variable-name
  private _authUser$: Observable<Client | null>;
  private _authState: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Description: Returns the actual user as Observable
   * @author Carlos Fontes
   */
  get authUser$(): Observable<Client> {
    return this._authUser$;
  }

  /**
   * Description: Returns the auth state as Observable
   * @author Carlos Fontes
   */
  get authState$(): Observable<boolean> {
    return this._authState.asObservable();
  }

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private notificationsService: NotificationsService,
    private router: Router
  ) {
    this.initAuth();
  }

  /**
   * Description: Inicia la autenticación
   * @author Carlos Fontes
   */
  initAuth(): void {
    if (!this._authUser$) {
      this._authUser$ = this.afAuth.authState.pipe(
        switchMap((user) => {
          // Logged in
          if (user) {
            this._authState.next(true);
            return this.afs.doc<Client>(`clients/${user.uid}`).valueChanges();
          } else {
            // Logged out
            this._authState.next(false);
            return of(null);
          }
        })
      );
    }
  }

  async clientExists(email: string): Promise<any> {
    try {      
      const result = await (await this.afs
        .collection<Client>("clients", (ref) => ref.where("email", "==", email))
        .get()
        .pipe(take(1))
        .toPromise());
      
      const clientExists = result.docs.length;

      return Promise.resolve(clientExists);
    } catch (error) {
      return Promise.reject(null);
    }

  }

  /**
   * Description: Login with email and password
   * @author Carlos Fontes
   */
  async emailSignin(
    email: string,
    password: string
  ): Promise<firebase.User | Error> {
    let credential: auth.UserCredential;

    try {

      const result = await (await this.afs
        .collection<Client>("clients", (ref) => ref.where("email", "==", email))
        .get()
        .pipe(take(1))
        .toPromise());
      
      const clientExists = result.docs.length;

      if (!clientExists) throw { code: 'auth/user-not-found' };

      credential = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      );

      let active = false;
      let userData: Client;
      this.setAuthState(true);
      await this.authUser$
        .pipe(first())
        .toPromise()
        .then((user) => {
          userData = user;
          active = user ? user.active : false;
        });

      if (credential.user && active) {
        this.router.navigate(['/home']);
        return credential.user;
      } else if (credential.user && userData && !active) {
        this.notificationsService.error('Tu cuenta ha sido bloqueada.');
        this.signOut(true);
      }
    } catch (error) {
      throwError(error);
      if (error.code === 'auth/wrong-password') {
        this.notificationsService.error(
          'El correo o la contraseña ingresadas son incorrectas.'
        );
      } else if (error.code === 'auth/too-many-requests') {
        this.notificationsService.error(
          'Ha realizado demasiados intentos, vuelva a intentarlo más tarde.'
        );
      } else if (error.code === 'auth/user-not-found') {
        this.notificationsService.error(
          'El usuario con ese correo no se encontro en el nuestro sistema. Por favor, registrese primero.'
        );
      } else if (!error.code) {
        this.notificationsService.error(error);
      } else {
        this.notificationsService.error(
          'Ha ocurrido un error inesperado.' + error.code ? error.code : error
        );
      }
      throw new Error(error.code ? error.code : error);
    }
  }

  /**
   * Description: Register with email and password
   * @author Carlos Fontes
   */
  async registerUser(
    email: string,
    password: string,
    data: { firstName: string; lastName: string; email: string, ci: number }
  ): Promise<void | Error> {
    let credential: any;
    try {
      credential = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      if (credential.user) {
        const updatedUser = await this.updateUserData(credential.user, data);

        return updatedUser;
      }
    } catch (error) {
      throwError(error);
      if (error.code === 'auth/email-already-in-use') {
        this.notificationsService.error(
          'El correo ingresado ya esta registrado con otra cuenta de usuario en nuestros sistemas.'
        );
      } else {
        this.notificationsService.error('Ha ocurrido un error.');
      }
      console.log(error);
      return new Error(error.code);
    }
  }

  /**
   * Description: Sends an verification email
   * @author Carlos Fontes
   */
  async sendVerificationMail(): Promise<any> {
    return (await this.afAuth.currentUser)
      .sendEmailVerification({ url: 'http://localhost:4200/welcome' })
      .then(() => {});
  }

  /**
   * Description: Log Out the actual user
   * @author Carlos Fontes
   */
  async signOut(reload?: boolean): Promise<any> {
    await this.afAuth.signOut();
    this.clearAuthState();
    window.location.reload();
    if (!reload) {
      this.notificationsService.success('Su sesión ha sido cerrada.');
      this.router.navigate(['/']);
    }
  }

  /**
   * Description: Create/Updates the client data
   * @author Carlos Fontes
   */
  private updateUserData(
    firebaseUser: firebase.User,
    clientData: { firstName: string; lastName: string; email: string, ci: number }
  ): Promise<void> {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<Client> = this.afs.doc(
      `clients/${firebaseUser.uid}`
    );

    // Se crea la referencia para el carrito del usuario registrado
    const cartRef: AngularFirestoreDocument<Cart> = this.afs.doc(
      `carts/${firebaseUser.uid}`
    );

    const data: Client = {
      active: true,
      createdAt: new Date(),
      id: firebaseUser.uid,
      email: firebaseUser.email,
      firstName: clientData.firstName,
      lastName: clientData.lastName,
      ci: clientData.ci,
    };
    const userData = userRef.set(data, { merge: true });
    const cartData = cartRef.set({ products: [] }, { merge: true });

    return userData;
  }

  /**
   * Description: Sends password recovery email
   * @author Carlos Fontes
   */
  async resetPassword(email: string): Promise<any> {
    return this.afAuth
      .sendPasswordResetEmail(email)
      .then((res) => {
        // this.notificationsService.success('Please verify your email');
      })
      .catch((error) => {
        return error;
      });
  }

  /**
   * Description: Changes the auth state
   * @author Carlos Fontes
   */
  private setAuthState(value: boolean): void {
    this._authState.next(value);
  }

  /**
   * Description: Clears the auth state
   * @author Carlos Fontes
   */
  private clearAuthState(): void {
    this._authState.next(false);
    this._authUser$ = of(null);
  }

  /**
   * Description: Gets all the addresses of the user
   * @author Carlos Fontes
   */
  getAddresses(): Observable<Address[]> {
    return this.authUser$.pipe(
      switchMap((user) => {
        return this.afs
          .doc<Client>(`clients/${user.id}`)
          .collection<Address>('addresses')
          .valueChanges({ idField: 'id' });
      })
    );
  }

  /**
   * Description: Generates new address for the user
   * @author Carlos Fontes
   */
  async generateAddress(address: Address): Promise<string> {
    const user = await this.authUser$.pipe(take(1)).toPromise();
    const addressCreated = await this.afs
      .doc<Client>(`clients/${user.id}`)
      .collection<Address>('addresses')
      .add(address);

    return addressCreated.id;
  }

  /**
   * Description: Edits an address
   * @author Carlos Fontes
   */
  async editAddress(address: Address, addressId: string): Promise<any> {
    const user = await this.authUser$.pipe(take(1)).toPromise();
    const addressToEdit = this.afs
      .doc<Client>(`clients/${user.id}`)
      .collection('addresses')
      .doc<Address>(addressId).ref;

    return addressToEdit.set(address, { merge: true });
  }

  /**
   * Description: Generates new address for the user
   * @author Carlos Fontes
   */
  async deleteAddress(address: Address): Promise<any> {
    const user = await this.authUser$.pipe(take(1)).toPromise();
    return this.afs
      .doc<Client>(`clients/${user.id}`)
      .collection('addresses')
      .doc<Address>(address.id)
      .delete();
  }

  updateUserInfo(user: Client, userId: string): void {
    const userRef: AngularFirestoreDocument<Client> = this.afs.doc(
      `clients/${userId}`
    );

    userRef
      .set(user, { merge: true })
      .then((res) => {
        this.notificationsService.success(
          'La información del usuario ha sido actualizada correctamente'
        );
      })
      .catch((err) => {
        console.log(err);
        this.notificationsService.error('Ha ocurrido un error.');
      });
  }
}
