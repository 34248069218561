import { DialogModalComponent } from './../dialog-modal/dialog-modal.component';
import { NotificationsService } from 'angular2-notifications';
import { CountryData, StateData } from './../../models/CountryData';
import { EcommerceVarsService } from './../../services/ecommerce-vars.service';
import { Address } from './../../models/Address';
import { takeUntil, switchMap, take } from 'rxjs/operators';
import { LoaderService } from './../../services/loader.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Order } from 'src/app/models/Order';
import { AnalyticsService } from '../../services/analytics.service';

@Component({
  selector: 'app-address-selector',
  templateUrl: './address-selector.component.html',
  styleUrls: ['./address-selector.component.scss']
})
export class AddressSelectorComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();
  private defaultCountry = "Venezuela";

  @Input() selected: Address;
  @Input() selectable: boolean;
  @Input() order: Order;
  @Output() addressSelected = new EventEmitter();
  addresses: Address[];
  addressForm: FormGroup;
  creatingAddress: boolean;
  editingAddress: Address;
  countries: CountryData[] = [];
  selectedCountryStates: StateData[] = [];
  selectedStateCities: string[] = [];
  private selectedCountryIndex: number;
  private selectedStateIndex: number;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private notificationsService: NotificationsService,
    private fb: FormBuilder,
    private varsService: EcommerceVarsService,
    public dialog: MatDialog,
    private analyticsService: AnalyticsService,
  ) { }

  async ngOnInit() {

    this.loaderService.showLoader();

    this.countries = await this.varsService.getCountryWithStates().pipe(take(1)).toPromise();

    this.selectedCountryIndex = this.countries.findIndex((country) => country.name === this.defaultCountry);

    this.selectedCountryStates = this.countries[this.selectedCountryIndex].states;

    this.initializeForm();

    this.authService.getAddresses().pipe(
      takeUntil(this.destroyed$)
    ).subscribe(addresses => {
      this.addresses = addresses;
      if (addresses.length === 0) { this.creatingAddress = true; }
      else { this.creatingAddress = false; }

      this.loaderService.hideLoader();
    });
  }

  initializeForm(): void {
    this.addressForm = this.fb.group({
      firstName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      lastName: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      addressOne: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      addressTwo: [''],
      country: [this.defaultCountry, Validators.compose([Validators.required])],
      state: ['', Validators.compose([Validators.required])],
      city: ['', Validators.compose([Validators.required])],
      zipCode: ['', Validators.compose([Validators.required])],
      phone: ['', Validators.compose([Validators.required])],
      phoneLocal: [''],
    });

    this.addressForm.get("city").disable();

    this.addressForm.get("country").valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.addressForm.get("state").setValue('', { emitEvent: false });
      this.addressForm.get("city").setValue('', { emitEvent: false });
      this.addressForm.get("city").disable();
      this.selectedCountryIndex = this.countries.findIndex((country) => country.name === value);
      this.selectedCountryStates = this.countries[this.selectedCountryIndex].states;
    })
    this.addressForm.get("state").valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.addressForm.get("city").setValue('', { emitEvent: false });
      this.addressForm.get("city").enable();
      this.selectedStateIndex = this.selectedCountryStates.findIndex((state) => state.name === value);
      this.selectedStateCities = this.selectedCountryStates[this.selectedStateIndex].cities;
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  createAddress(): void {
    this.authService.generateAddress(this.addressForm.value).then(addressId => {
      this.addressSelected.emit({ ...this.addressForm.value, id: addressId });
    });

    if (this.order) {
      this.analyticsService.triggerAddShippingInfoEvent(this.order)
    }
  }

  editAddress(): void {
    this.authService.editAddress(this.addressForm.value, this.editingAddress.id).then(addressId => {
      this.editingAddress = null;
      this.notificationsService.success('Dirección actualizada');
    });
  }

  selectAddress(address: Address): void {
    this.addressSelected.emit(address);
  }

  deleteAddress(address: Address): void {
    this.dialog
      .open(DialogModalComponent, {
        data: `¿Está seguro de querer borrar esta dirección?`
      }).afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.authService.deleteAddress(address);
        }
      });
  }

  onEditAddress(address: Address): void {
    this.editingAddress = address;

    this.selectedCountryIndex = this.countries.findIndex((country) => country.name === address.country);
    this.selectedCountryStates = this.countries[this.selectedCountryIndex].states;

    this.selectedStateIndex = this.selectedCountryStates.findIndex((state) => state.name === address.state);
    this.selectedStateCities = this.selectedCountryStates[this.selectedStateIndex].cities;

    
    this.addressForm.patchValue(address, { emitEvent: false})
    
    this.addressForm.get("city").enable();
    
    // this.addressForm = this.fb.group({
    //   firstName: [address.firstName, Validators.compose([Validators.required, Validators.minLength(2)])],
    //   lastName: [address.lastName, Validators.compose([Validators.required, Validators.minLength(2)])],
    //   addressOne: [address.addressOne, Validators.compose([Validators.required, Validators.minLength(5)])],
    //   addressTwo: [address.addressTwo],
    //   country: [address.country, Validators.compose([Validators.required])],
    //   state: [address.state, Validators.compose([Validators.required])],
    //   city: [address.city, Validators.compose([Validators.required])],
    //   zipCode: [address.zipCode, Validators.compose([Validators.required])],
    //   phone: [address.phone, Validators.compose([Validators.required])],
    // });
  }

}
