import { NotificationsService } from 'angular2-notifications';
import { CommentExpand } from './../../models/Comment';
import { DummyService } from './../../services/dummy.service';
import { take, takeUntil } from 'rxjs/operators';
import { CommentsService } from './../../services/comments.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { Comment } from 'src/app/models/Comment';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-comment-area',
  templateUrl: './comment-area.component.html',
  styleUrls: ['./comment-area.component.scss']
})
export class CommentAreaComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();

  @Input() productId: string;
  @Input() userId: string;
  hasFullfilledOrderWithProduct:boolean=false
  comments: CommentExpand[];

  constructor(
    private commentsService: CommentsService,
    private notificationService: NotificationsService,
    private ordersService: OrdersService
  ) { }

  ngOnInit(): void {
    this.commentsService.getProductComments(this.productId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(comments => {
        this.comments = comments;
      });
    this.ordersService.productIsAlreadyPurchased(this.productId, this.userId).pipe(take(1), takeUntil(this.destroyed$)).subscribe((hasOrderedProduct:boolean) => {
      this.hasFullfilledOrderWithProduct=hasOrderedProduct
    })
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  getInitials(name: string): string {
    const matches = name.match(/\b(\w)/g);
    return matches.slice(0, 2).join('');
  }

  deleteComment(comment: CommentExpand): void {
    this.commentsService.deleteComment(comment, this.productId).then(res => {
      this.notificationService.success('Comentario borrado');
    });
  }

}
