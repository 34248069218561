import { Component, OnInit } from '@angular/core';
import { FooterContent } from './../../models/Footer';
import { ContentService } from './../../services/content.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerContent: FooterContent;

  constructor(
    private contentService: ContentService,
  ) { }

  private async setFooterContent() {
    try {
      this.footerContent = await this.contentService.getContent<FooterContent>("footer");
    } catch (error) {}
  }

  ngOnInit(): void {
    this.setFooterContent();
  }

}
