import { SubCategory } from './../../../models/SubCategory';
import { Category } from './../../../models/Category';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-category',
  templateUrl: './card-category.component.html',
  styleUrls: ['./card-category.component.scss'],
})
export class CardCategoryComponent implements OnInit {
  @Input() category: Category | SubCategory;
  @Input() type: 'category' | 'subcategory';

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void { }

  navigateToProductListing(): void {
    this.router.navigate(
      ['/products'],
      { queryParams: { catg: (this.category as SubCategory).categoryId, subcatg: this.category.id } }
    );
  }
}
