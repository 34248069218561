import { TransferAccount } from './../models/TransferAccount';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { CountryData } from '../models/CountryData';
import { DataDict, ServerTimeDoc } from '../models/General';
import * as firebase from 'firebase';
import { TaxesVar } from '../models/TaxesVar';

@Injectable({
  providedIn: 'root',
})
export class EcommerceVarsService {
  constructor(private db: AngularFirestore) {}

  getCountryWithStates(): Observable<CountryData[]> {
    return this.db
      .doc<any>('ecommerceVars/countriesTest')
      .valueChanges()
      .pipe(map((dbCountries) => dbCountries.countries));
  }

  getDeliveryPrice(): Observable<number> {
    return this.db
      .doc<any>('ecommerceVars/deliveryPrice')
      .valueChanges()
      .pipe(map((dbDeliveryPrice) => dbDeliveryPrice.deliveryPrice));
  }

  getBanescoTransferAccount(): Observable<TransferAccount> {
    return this.db
      .doc<any>('ecommerceVars/transferAccounts')
      .valueChanges()
      .pipe(
        map((dbTransferAccount) => dbTransferAccount.banesco)
      );
  }

  getTransferPanamaBankAccount(): Observable<TransferAccount> {
    return this.db
      .doc<any>('ecommerceVars/transferAccounts')
      .valueChanges()
      .pipe(
        map((dbTransferAccount) => dbTransferAccount.panamaBank)
      );
  }
  getTransferZelleAccount(): Observable<TransferAccount> {
    return this.db
      .doc<any>('ecommerceVars/transferAccounts')
      .valueChanges()
      .pipe(
        map((dbTransferAccount) => dbTransferAccount.zelle)
      );
  }
  getMovilPaymentAccount(): Observable<TransferAccount> {
    return this.db
      .doc<any>('ecommerceVars/transferAccounts')
      .valueChanges()
      .pipe(map((dbTransferAccount) => dbTransferAccount.pagoMovil));
  }

  getPaymentMethods() {
    return this.db
      .doc<any>('ecommerceVars/paymentMethods')
      .valueChanges()
      .pipe(map((dbPaymentMethods) => dbPaymentMethods?.paymentMethods));
  }

  getTransferAccounts(): Observable<DataDict<TransferAccount>> {
    return this.db
      .doc<DataDict<TransferAccount>>('ecommerceVars/transferAccounts')
      .valueChanges();
  }

  async getServerTime(): Promise<Date> {
    const docRef = this.db.doc<ServerTimeDoc<firebase.firestore.Timestamp>>('aux/serverTime');

    try {

      await docRef.set({
        time: firebase.firestore.FieldValue.serverTimestamp() as any,
      });

      return this.db
      .doc<ServerTimeDoc<firebase.firestore.Timestamp>>('aux/serverTime')
      .valueChanges()
      .pipe(
        take(1),
        map((doc) => doc.time.toDate())
      ).toPromise();

    } catch (error) {
      return Promise.resolve(null);
    }
  }

  getTaxesVar(): Observable<TaxesVar> {
    return this.db
      .collection("ecommerceVars")
      .doc<TaxesVar>("taxes")
      .valueChanges();
  }
}
