import { NotificationsService } from 'angular2-notifications';
import { OrdersService } from './../../../services/orders.service';
import { CommentsService } from './../../../services/comments.service';
import { takeUntil, switchMap } from 'rxjs/operators';
import { Client } from './../../../models/Client';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-comment-box-sender',
  templateUrl: './comment-box-sender.component.html',
  styleUrls: ['./comment-box-sender.component.scss']
})
export class CommentBoxSenderComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();

  @Input() productId: string;
  comment: string;
  rating: number;
  actualUser: Client;
  alreadyPurchased: boolean;
  ratedPerUser: number;

  constructor(
    private authService: AuthService,
    private commentsService: CommentsService,
    private ordersService: OrdersService,
    private notificationsService: NotificationsService,
  ) { }

  ngOnInit(): void {
    this.authService.authUser$.pipe(
      switchMap(user => {
        this.actualUser = user;

        return combineLatest([
          this.ordersService.productIsAlreadyPurchased(this.productId, user?.id || null),
          this.commentsService.alreadyRated(this.productId, user?.id || null),
        ]);
      }),
      takeUntil(this.destroyed$)
    ).subscribe(([alreadyPurchased, rating]) => {
      this.alreadyPurchased = alreadyPurchased;
      this.ratedPerUser = rating;
      this.rating = rating;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  postComment(): void {
    if (this.ratedPerUser && this.actualUser) {
      this.commentsService.postComment(this.productId, {
        hasContent: !!this.comment,
        content: this.comment,
        createdAt: new Date(),
        userId: this.actualUser.id
      }).then(res => {
        this.notificationsService.success('Comentario enviado');
        this.comment = null;
      });
    } else if (!this.ratedPerUser && this.actualUser) {
      this.commentsService.postComment(this.productId, {
        hasContent: !!this.comment,
        content: this.comment,
        rating: this.rating,
        createdAt: new Date(),
        userId: this.actualUser.id
      }).then(res => {
        this.notificationsService.success('Comentario enviado');
        this.comment = null;
      });
    }
  }

  onRate(rating: number): void {
    this.rating = rating;
  }

  onChangeOldRate(): void {
    if (this.rating !== this.ratedPerUser) {
      this.commentsService.updateRating(this.productId, this.actualUser.id, this.rating).then(res => {
        this.notificationsService.success('Puntuación guardada');
      });
    }
  }
}
