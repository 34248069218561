<div class="stars">
  <ng-container *ngIf="onlyView">
    <div (click)="rated(1)" (mouseover)="onHover(true, 0)" (mousemove)="onHover(true, 0)"
      [title]="pointsIn.toString() + ' stars'" (mouseout)="onHover(false, -1)"
      [ngClass]="{'opa': hovered && starHovered >= 0, 'noClick': onlyView }">
      <svg-icon [class]="'icon'" [applyClass]="true"
        [src]="(actualPoints >= 0.5 && !hovered) ? ((actualPoints >= 0.8 && !hovered) ? starFillPath : starHalfPath) : (hovered && starHovered >= 0.5) ? ((hovered && starHovered >= 1) ? starFillPath : starHalfPath) : starNoFillPath">
      </svg-icon>
    </div>
    <div (click)="rated(2)" (mouseover)="onHover(true, 1)" (mousemove)="onHover(true, 1)"
      [title]="pointsIn.toString() + ' stars'" (mouseout)="onHover(false, -1)"
      [ngClass]="{'opa': hovered && starHovered >= 1, 'noClick': onlyView }">
      <svg-icon [class]="'icon'" [applyClass]="true"
        [src]="(actualPoints >= 1.5 && !hovered) ? ((actualPoints >= 1.8 && !hovered) ? starFillPath : starHalfPath) : (hovered && starHovered >= 1.5) ? ((hovered && starHovered >= 2) ? starFillPath : starHalfPath) : starNoFillPath">
      </svg-icon>
    </div>
    <div (click)="rated(3)" (mouseover)="onHover(true, 2)" (mousemove)="onHover(true, 2)"
      [title]="pointsIn.toString() + ' stars'" (mouseout)="onHover(false, -1)"
      [ngClass]="{'opa': hovered && starHovered >= 2, 'noClick': onlyView }">
      <svg-icon [class]="'icon'" [applyClass]="true"
        [src]="(actualPoints >= 2.5 && !hovered) ? ((actualPoints >= 2.8 && !hovered) ? starFillPath : starHalfPath) : (hovered && starHovered >= 2.5) ? ((hovered && starHovered >= 3) ? starFillPath : starHalfPath) : starNoFillPath">
      </svg-icon>
    </div>
    <div (click)="rated(4)" (mouseover)="onHover(true, 3)" (mousemove)="onHover(true, 3)"
      [title]="pointsIn.toString() + ' stars'" (mouseout)="onHover(false, -1)"
      [ngClass]="{'opa': hovered && starHovered >= 3, 'noClick': onlyView }">
      <svg-icon [class]="'icon'" [applyClass]="true"
        [src]="(actualPoints >= 3.5 && !hovered) ? ((actualPoints >= 3.8 && !hovered) ? starFillPath : starHalfPath) : (hovered && starHovered >= 3.5) ? ((hovered && starHovered >= 4) ? starFillPath : starHalfPath) : starNoFillPath">
      </svg-icon>
    </div>
    <div (click)="rated(5)" (mouseover)="onHover(true, 4)" (mousemove)="onHover(true, 4)"
      [title]="pointsIn.toString() + ' stars'" (mouseout)="onHover(false, -1)"
      [ngClass]="{'opa': hovered && starHovered >= 4, 'noClick': onlyView }">
      <svg-icon [class]="'icon'" [applyClass]="true"
        [src]="(actualPoints >= 4.5 && !hovered) ? ((actualPoints >= 4.8 && !hovered) ? starFillPath : starHalfPath) : (hovered && starHovered >= 4.5) ? ((hovered && starHovered >= 5) ? starFillPath : starHalfPath) : starNoFillPath">
      </svg-icon>
    </div>
  </ng-container>


  <ng-container *ngIf="!onlyView">
    <div (click)="rated(1)" (mouseover)="onHover(true, 0)" (mouseout)="onHover(false, -1)"
      [ngClass]="{'opa': hovered && starHovered >= 0, 'notAllowedClick': disabled }">
      <svg-icon [class]="'icon'" [applyClass]="true"
        [src]="(actualPoints >= 1 && !hovered || (hovered && starHovered >= 0)) ? starFillPath : starNoFillPath">
      </svg-icon>
    </div>
    <div (click)="rated(2)" (mouseover)="onHover(true, 1)" (mouseout)="onHover(false, -1)"
      [ngClass]="{'opa': hovered && starHovered >= 1, 'notAllowedClick': disabled }">
      <svg-icon [class]="'icon'" [applyClass]="true"
        [src]="(actualPoints >= 2 && !hovered || (hovered && starHovered >= 1)) ? starFillPath : starNoFillPath">
      </svg-icon>
    </div>
    <div (click)="rated(3)" (mouseover)="onHover(true, 2)" (mouseout)="onHover(false, -1)"
      [ngClass]="{'opa': hovered && starHovered >= 2, 'notAllowedClick': disabled }">
      <svg-icon [class]="'icon'" [applyClass]="true"
        [src]="(actualPoints >= 3 && !hovered || (hovered && starHovered >= 2)) ? starFillPath : starNoFillPath">
      </svg-icon>
    </div>
    <div (click)="rated(4)" (mouseover)="onHover(true, 3)" (mouseout)="onHover(false, -1)"
      [ngClass]="{'opa': hovered && starHovered >= 3, 'notAllowedClick': disabled }">
      <svg-icon [class]="'icon'" [applyClass]="true"
        [src]="(actualPoints >= 4 && !hovered || (hovered && starHovered >= 3)) ? starFillPath : starNoFillPath">
      </svg-icon>
    </div>
    <div (click)="rated(5)" (mouseover)="onHover(true, 4)" (mouseout)="onHover(false, -1)"
      [ngClass]="{'opa': hovered && starHovered >= 4, 'notAllowedClick': disabled }">
      <svg-icon [class]="'icon'" [applyClass]="true"
        [src]="(actualPoints >= 5 && !hovered || (hovered && starHovered >= 4)) ? starFillPath : starNoFillPath">
      </svg-icon>
    </div>

  </ng-container>


</div>
