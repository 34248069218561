import { Pipe, PipeTransform } from '@angular/core';
import { Translated } from '../models/Translated';
import { LanguageService } from './../services/language.service';

@Pipe({ name: 'lang', pure: false })
export class LanguagePipe implements PipeTransform {

    constructor(
      private languageService: LanguageService,
    ) {
    }

    transform(value: Translated): any {
        if (typeof value === 'string') {
            return value;
        } else {
            return value[this.languageService.language] || value.es || value.en;
        }
    }
}
