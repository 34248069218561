export enum GA4Events {
    'add_payment_info' = 'add_payment_info',
    'add_shipping_info' = 'add_shipping_info',
    'add_to_cart' = 'add_to_cart',
    'add_to_wishlist' = 'add_to_wishlist',
    'begin_checkout' = 'begin_checkout',
    'checkout_progress' = 'checkout_progress',
    'exception' = 'exception',
    'generate_lead' = 'generate_lead',
    'login' = 'login',
    'page_view' = 'page_view',
    'purchase' = 'purchase',
    'refund' = 'refund',
    'remove_from_cart' = 'remove_from_cart',
    'screen_view' = 'screen_view',
    'search' = 'search',
    'select_item' = 'select_item',
    'select_content' = 'select_content',
    'select_promotion' = 'select_promotion',
    'share' = 'share',
    'sign_up' = 'sign_up',
    'view_item' = 'view_item',
    'view_item_list' = 'view_item_list',
    'view_promotion' = 'view_promotion',
    'view_cart' = 'view_cart'
  }