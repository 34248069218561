import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Product } from 'src/app/models/Product';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Client } from 'src/app/models/Client';
import { CartService } from 'src/app/services/cart.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StarsRatingPipe } from 'src/app/pipes/stars-rating.pipe';
import { AnalyticsService } from '../../../services/analytics.service';
import { noImageUrl } from '../../../constants/images.constant';

declare var gtag;

@Component({
  selector: 'app-card-product',
  templateUrl: './card-product.component.html',
  styleUrls: ['./card-product.component.scss'],
})
export class CardProductComponent implements OnInit {

  private _product: Product;

  @Input() 
  set product(value: Product) {
    this._product = value;
    this.rating = this.starsRatingPipe.transform(this.product);
    this.completeStars = Math.floor(this.rating);
    this.halfStars = this.rating % 1 >= 0.5 ? 1 : 0;
    this.emptyStars = this.halfStars == 0 ? 5 - this.completeStars : 5 - this.completeStars - 1;

    this.completeStarsArray = Array(this.completeStars)
    .fill(0)
    .map((x, i) => i);
    this.halfStarsArray = Array(this.halfStars)
    .fill(0)
    .map((x, i) => i);
    this.emptyStarsArray = Array(this.emptyStars)
    .fill(0)
    .map((x, i) => i);
  }

  get product(): Product {
    return this._product;
  }



  @Input() type: 'one' | 'two';
  completeStarsArray: Array<number>;
  halfStarsArray: Array<number>;
  emptyStarsArray: Array<number>;

  //
  private destroyed$ = new Subject<boolean>();
  actualUser: Client;
  quantityOnCart: number;
  maxQuantityToAdd: number;
  selectedQuantity: number;
  allImagesPaths: string[];
  currentImagePath: string;
  productPrice: number;
  productQuantity: number;
  productMaxPerOrder: number;
  hasVariations: boolean;
  selectedVariationPath: string[]; // ['s', 'blue', 'silk']
  availableVariations: string[][]; // [['s', 'blue', 'silk'], ['S', 'red', 'leather]
  lastSelectedVariation: { idxVariation: number; option: string }; // {idxVariation: 1 , option: 's'}

  rating: number;
  completeStars: number;
  halfStars: number;
  emptyStars: number;

  @Output() onSelection = new EventEmitter(null);

  @Input() discountPercentage: number;

  noImageUrl = noImageUrl;

  constructor(
    private router: Router,
    private authService: AuthService,
    private cartService: CartService,
    private starsRatingPipe: StarsRatingPipe,
    private analyticsService: AnalyticsService,
  ) {
    // console.log(this.completeStarsArray);
    // console.log(this.halfStars);
    // console.log(this.emptyStars);
  }

  ngOnInit(): void {
    this.authService.authUser$.subscribe((user) => {
      this.actualUser = user;
      this.cartService
        .getQuantityOfProductInCart(this.product.id, user ? user.id : null)
        .subscribe((quantityOnCart) => {
          if (quantityOnCart !== null) {
            this.quantityOnCart = quantityOnCart;
            this.maxQuantityToAdd =
              this.productMaxPerOrder <= this.productQuantity
                ? this.productMaxPerOrder - quantityOnCart
                : this.productQuantity - quantityOnCart;
            this.selectedQuantity = 1;
            return this.cartService.getQuantityOfProductInCart(
              this.product.id,
              user ? user.id : null
            );
          }
        });
    });
    this.authService.authUser$.pipe(
      switchMap((user) => {
        return this.cartService.getTotalQuantityOnCart(user ? user.id : null);
      }),
      takeUntil(this.destroyed$)
    );
  }
  ngOnDestroy(): void {
    //destroyed ngOnDestroy
    this.destroyed$.next(true);
  }

  handleImageError(e) {
    e.target.src = this.noImageUrl;
  }

  navigateToProductDetails(): void {
    this.router.navigate([`product/${this.product.slug}`]);
  }
  addToCart(): void {
    const max =
      (this.productMaxPerOrder || undefined) <= this.productQuantity
        ? this.productMaxPerOrder
        : this.productQuantity;
    this.cartService.changeQuantityOfProductOnCart(
      {
        productId: this.product.id,
        quantity: this.selectedQuantity || 1,
        variationPath: this.selectedVariationPath?.join('/'),
      },
      max
    );

    this.analyticsService.triggerAddToCartEvent(this.product);
  }
}
