import { LoaderService } from './../../services/loader.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();

  @Input() active = true;

  constructor(
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.loaderService.isActive.pipe(takeUntil(this.destroyed$))
      .subscribe(status => {
        this.active = status;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

}
