<div *ngIf="type === 'one'" class="card-product-home--one">
  <div
    class="card-product-home--one__image"
    (click)="navigateToProductDetails()"
    (mousedown)="onSelection.emit(null)"
  >
    <ng-container 
      *ngIf="discountPercentage" 
      [ngTemplateOutlet]="discountContainer" 
      [ngTemplateOutletContext]="{ 'percentage': discountPercentage }"
    >
    </ng-container>
    <img [src]="product.imagesUrl ? product.imagesUrl[0] : noImageUrl" (error)="handleImageError($event)" [alt]="product.name" />
  </div>

  <div
    class="card-product-home--one__info"
    (click)="navigateToProductDetails()"
    (mousedown)="onSelection.emit(null)"
  >
    <p class="name">{{ product.name }}</p>
    <div class="stars">
      <svg-icon
        *ngFor="let star of completeStarsArray"
        src="../../../assets/images/icons/full-star.svg"
      >
      </svg-icon>
      <svg-icon
        *ngFor="let star of halfStarsArray"
        src="../../../assets/images/icons/half-star.svg"
      >
      </svg-icon>
      <svg-icon
        *ngFor="let star of emptyStarsArray"
        src="../../../assets/images/icons/empty-star.svg"
      >
      </svg-icon>
      <!-- <p class="rating">{{ rating }}</p> -->
    </div>
    <p class="price" *ngIf="!product.highestPrice">
      <span [ngClass]="{ 'discounted': discountPercentage }">{{ product.price | currency }}</span>
      <span *ngIf="!product.highestPrice && discountPercentage">
        {{ product.price | discount : discountPercentage | currency }}
      </span>
    </p>
    <!-- <p class="price variation" *ngIf="product.highestPrice">
      {{ product.price | currency }} -
      {{ product.highestPrice | currency }}
    </p> -->
  </div>
  <div
    *ngIf="
      product.variationsComb === undefined;
      then addCartButton;
      else detailButton
    "
  ></div>
  <ng-template #addCartButton>
    <div class="card-product-home--one__add-to-cart" (click)="addToCart()">
      Añadir al carrito
    </div>
  </ng-template>
  <ng-template #detailButton>
    <div
      class="card-product-home--one__add-to-cart"
      (click)="navigateToProductDetails()"
    >
      <strong><h3>+</h3></strong>
    </div>
  </ng-template>
</div>

<div *ngIf="type === 'two'" class="card-product-home--two">
  <div
    class="card-product-home--two__image"
    (click)="navigateToProductDetails()"
    (mousedown)="onSelection.emit(null)"
  >
    <ng-container 
      *ngIf="discountPercentage" 
      [ngTemplateOutlet]="discountContainer" 
      [ngTemplateOutletContext]="{ 'percentage': discountPercentage }"
    >
    </ng-container>
    <img [src]="product.imagesUrl ? product.imagesUrl[0] : noImageUrl" (error)="handleImageError($event)" [alt]="product.name" />
  </div>

  <div
    class="card-product-home--two__info"
    (click)="navigateToProductDetails()"
    (mousedown)="onSelection.emit(null)"
  >
    <p class="name">{{ product.name }}</p>
    <ng-container *ngIf="!product.highestPrice">
      <p class="price" >
        <span [ngClass]="{ 'discounted': discountPercentage }">{{ product.price | currency }}</span>
        <span *ngIf="discountPercentage">
          {{ product.price | discount : discountPercentage | currency }}
        </span>
      </p>
    </ng-container>

    <ng-container *ngIf="product.highestPrice">
      <p class="price variation" *ngIf="!discountPercentage">
        {{ product.price | currency }} -
        {{ product.highestPrice | currency }}
      </p>
      <p class="price variation" *ngIf="discountPercentage">
        {{ product.price | discount : discountPercentage | currency }} -
        {{ product.highestPrice | discount : discountPercentage | currency }}
      </p>
    </ng-container>
  </div>

  <div
    *ngIf="
      product.variationsComb === undefined;
      then addCartButton;
      else detailButton
    "
  ></div>
  <ng-template #addCartButton>
    <div class="card-product-home--one__add-to-cart" (click)="addToCart()">
      Añadir al carrito
    </div>
  </ng-template>
  <ng-template #detailButton>
    <div
      class="card-product-home--one__add-to-cart"
      (click)="navigateToProductDetails()"
    >
      <strong><h3>+</h3></strong>
    </div>
  </ng-template>
</div>

<ng-template #discountContainer let-percentage="percentage">
  <div class="discount-container">
    <span class="text">
      -{{ percentage | number : '1.0-0' }}%
    </span>
  </div>
</ng-template>