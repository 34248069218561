/**
 * @source https://stackoverflow.com/a/19270021/5819227
 */
export function getRandomElementsFromArray<T>(arr: T[], n: number): T[] {
    var result = new Array<T>(n),
        len = arr.length,
        taken = new Array<number>(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}