import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AllCategoriesRoutingModule } from './all-categories-routing.module';
import { AllCategoriesComponent } from './all-categories/all-categories.component';
import { SharedModule } from "../shared/shared.module";


@NgModule({
  declarations: [
    AllCategoriesComponent,
  ],
  imports: [
    CommonModule,
    AllCategoriesRoutingModule,
    SharedModule,
  ],
  exports: [
    AllCategoriesComponent,
  ]
})
export class AllCategoriesModule { }
