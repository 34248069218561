import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DummyService {

  constructor(
    private db: AngularFirestore
  ) { }

  generateCategoriesAndSubcategories(): void {
    const data = [
      {
        id: '412324',
        name: 'Techonlogy',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/categories%2Ftechonology.png?alt=media&token=335e79d0-743d-453a-a90e-c751db191eb8',
        subcategories: [
          {
            id: '8934735',
            name: 'Computers',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/subcategories%2Fapple-books-desk-keyboard-115655%20(1).jpg?alt=media&token=afd1aabb-24a1-4b74-b2b5-050637192adf'
          },
          {
            id: '9422253',
            name: 'Smartphones',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/subcategories%2Fselective-focus-photography-of-person-holding-turned-on-1092644.jpg?alt=media&token=27b8261f-1c56-4f60-b733-e3b4c42e8684'
          },
          {
            id: '90054742',
            name: 'Music',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/subcategories%2Fbeats-depth-of-field-detail-electronics-577769.jpg?alt=media&token=bcbb998a-1cbc-4f1e-bbfd-44038914b9a1'
          },
          {
            id: '12345663',
            name: 'Videogames',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/subcategories%2Fblur-close-up-device-display-442576.jpg?alt=media&token=c1b5c772-a654-4d9b-a791-8388c96dea39'
          },
          {
            id: '900484848',
            name: 'Monitors',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/subcategories%2Fblack-flat-screen-computer-monitor-1714208.jpg?alt=media&token=96179bf8-8a67-489a-91e5-f89a5b3899f8'
          },
        ]
      },
      {
        id: '5464234',
        name: 'Personal Care',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/categories%2Fpersonal-care.png?alt=media&token=adf7167d-4b25-4d1b-9dbc-fe2601ce9e72',
        subcategories: [
          {
            id: '13412355',
            name: 'Face',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/subcategories%2FpersonaCare-face.png?alt=media&token=fd49ea77-d782-42b8-8a46-2d688e7f706c'
          },
          {
            id: '99090998',
            name: 'Feet',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/subcategories%2FpersonalCare-feet.png?alt=media&token=f3f83582-70a8-4595-851a-fd7c385cf255'
          },
          {
            id: '23455772',
            name: 'Body',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/subcategories%2FpersonalCare-body.png?alt=media&token=968754fd-4e76-442e-ba73-7ce5c350ac7b'
          }
        ]
      },
      {
        id: '5462342354',
        name: 'Clothing',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/categories%2Fclothing.png?alt=media&token=113fbbe8-abe0-4bc8-b2d3-c4a0fb4b33a7',
        subcategories: [
          {
            id: '959595734',
            name: 'Shirts',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/subcategories%2Fblur-box-business-checkered-shirt-297933.jpg?alt=media&token=56c4ce48-692d-4d6e-b64e-572615f992d3'
          },
          {
            id: '900059573111',
            name: 'Shoes',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/subcategories%2Fphoto-of-nike-shoes-1598505.jpg?alt=media&token=42224bca-3261-4a0f-a49c-557720c1855b'
          },
        ]
      },
      {
        id: '904873',
        name: 'Entertainment',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/categories%2Fentertainment.png?alt=media&token=00c8c9e5-e0db-4b50-9b1e-6d02575abfe7',
        subcategories: [
          {
            id: '90000878',
            name: 'Board Games',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/subcategories%2Fblur-board-game-business-challenge-278918.jpg?alt=media&token=ca7b366c-5d8d-4109-9d27-4c109caac36a'
          }
        ]
      },
      {
        id: '83276590',
        name: 'Home',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/categories%2Fhome.png?alt=media&token=ffb4afb4-55fb-4a25-b276-5c41792051f1',
        subcategories: [
          {
            id: '91190223',
            name: 'Carpets',
            imageUrl: 'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/subcategories%2Fblack-metal-framed-glass-door-1098982.jpg?alt=media&token=94ed1358-730e-4b4b-9f57-b69a32cf346c'
          }
        ]
      }
    ];

    for (const category of data) {
      const subcatgs = category.subcategories;
      const catgId = category.id;
      delete category.id;
      delete category.subcategories;

      this.db.collection('categories').doc(catgId).set(category).then(res => {
        for (const subcat of subcatgs) {
          const subcatId = subcat.id;
          delete subcat.id;
          this.db.collection('categories').doc(catgId).collection('subcategories').doc(subcatId).set(subcat);
        }
      });
    }
  }

  generateProducts(): void {
    const data = [
      {
        categoryId: '412324',
        subcategoryId: '8934735',
        active: true,
        featured: true,
        imagesUrl: [
          'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/products%2Fprod9.jpg?alt=media&token=35a7dfe7-84dc-4962-9ed2-1d5fd0139dc9',
          'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/products%2Fprod2.jpg?alt=media&token=b558e2db-8667-4036-b467-4b8870e8ae3a',
          'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/products%2Fprod3.jpg?alt=media&token=cd1708d9-fd21-4fdc-be89-1a804310106e'
        ],
        name: 'Bose Headphones 700',
        price: +(Math.random() * 100).toFixed(2),
        quantity: Math.floor(Math.random() * 100) + 5,
        maxPerOrder: Math.floor(Math.random() * 5) + 1,
        description: 'A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look.',
        rating: 4.2,

      },
      {
        categoryId: '412324',
        subcategoryId: '8934735',
        active: true,
        featured: true,
        imagesUrl: [
          'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/products%2Fprod2.jpg?alt=media&token=b558e2db-8667-4036-b467-4b8870e8ae3a',
        ],
        name: 'Innisfree Green Tea Balancing Cream',
        price: +(Math.random() * 100).toFixed(2),
        quantity: Math.floor(Math.random() * 100),
        maxPerOrder: Math.floor(Math.random() * 5) + 1,
        description: 'A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look.',
        rating: 4.2,

      },
      {
        categoryId: '412324',
        subcategoryId: '9422253',
        active: true,
        featured: true,
        imagesUrl: [
          'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/products%2Fprod3.jpg?alt=media&token=cd1708d9-fd21-4fdc-be89-1a804310106e',
        ],
        name: 'Claire’s Cloud 9 Blanc De Whitening Cream',
        price: +(Math.random() * 100).toFixed(2),
        quantity: Math.floor(Math.random() * 100),
        maxPerOrder: Math.floor(Math.random() * 5) + 1,
        description: 'A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look.',
        rating: 4.2,

      },
      {
        categoryId: '412324',
        subcategoryId: '12345663',
        active: true,
        featured: true,
        imagesUrl: [
          'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/products%2Fprod4.jpg?alt=media&token=498acbf9-e547-4bbd-b23c-5747a64f2518',
        ],
        name: 'Tonymoly Pure Eco Snail Moisture Gel',
        price: +(Math.random() * 100).toFixed(2),
        quantity: Math.floor(Math.random() * 100),
        maxPerOrder: Math.floor(Math.random() * 5) + 1,
        description: 'A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look.',
        rating: 4.2,

      },
      {
        categoryId: '5464234',
        subcategoryId: '13412355',
        active: true,
        newArrived: true,
        imagesUrl: [
          'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/products%2Fprod5.jpg?alt=media&token=8edb90ed-bd6e-46f3-ab2f-07c34ed5309e',
        ],
        name: 'Skin79 Fresh Garden Mask - Snail',
        price: +(Math.random() * 100).toFixed(2),
        quantity: Math.floor(Math.random() * 100),
        maxPerOrder: Math.floor(Math.random() * 5) + 1,
        description: 'A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look.',
        rating: 4.2,

      },
      {
        categoryId: '5464234',
        subcategoryId: '23455772',
        active: true,
        newArrived: true,
        imagesUrl: [
          'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/products%2Fprod6.jpg?alt=media&token=cd4d8725-e2ec-4d57-8d4d-9067f4b04975',
        ],
        name: 'Winter suit good one',
        price: +(Math.random() * 100).toFixed(2),
        quantity: Math.floor(Math.random() * 100),
        maxPerOrder: Math.floor(Math.random() * 5) + 1,
        description: 'A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look.',
        rating: 4.2,

      },
      {
        categoryId: '5462342354',
        subcategoryId: '959595734',
        active: true,
        newArrived: true,
        imagesUrl: [
          'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/products%2Fprod7.png?alt=media&token=73eb53db-2689-476a-b53f-a85a40834611',
        ],
        name: 'Blue jeans bag for travel',
        price: +(Math.random() * 100).toFixed(2),
        quantity: Math.floor(Math.random() * 100),
        maxPerOrder: Math.floor(Math.random() * 5) + 1,
        description: 'A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look.',
        rating: 4.2,

      },
      {
        categoryId: '5462342354',
        subcategoryId: '900059573111',
        active: true,
        newArrived: true,
        imagesUrl: [
          'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/products%2Fprod8.png?alt=media&token=5e964654-4a4a-450b-9ebb-e4dcd734efd0',
        ],
        name: 'Polo T-shirt big blue',
        price: +(Math.random() * 100).toFixed(2),
        quantity: Math.floor(Math.random() * 100),
        maxPerOrder: Math.floor(Math.random() * 5) + 1,
        description: 'A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look.',
        rating: 4.2,
      },
      {
        categoryId: '904873',
        subcategoryId: '90000878',
        active: true,
        imagesUrl: [
          'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/products%2Fprod1.jpg?alt=media&token=5e6ff2b6-c09d-4ed4-88b7-a674f848149c',
        ],
        name: 'Missha All Around Safe Block Sebum Zero Sun',
        price: +(Math.random() * 100).toFixed(2),
        quantity: Math.floor(Math.random() * 100),
        maxPerOrder: Math.floor(Math.random() * 5) + 1,
        description: 'A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look.',
        rating: 4.2,
      },
      {
        categoryId: '83276590',
        subcategoryId: '91190223',
        active: true,
        imagesUrl: [
          'https://firebasestorage.googleapis.com/v0/b/aetherecommerce.appspot.com/o/products%2Fprod10.jpg?alt=media&token=c80e073b-e52d-4df5-843c-9ec329869440',
        ],
        name: 'Amazing sofa for hall',
        price: +(Math.random() * 100).toFixed(2),
        quantity: Math.floor(Math.random() * 100),
        maxPerOrder: Math.floor(Math.random() * 5) + 1,
        description: 'A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look. A portable cooling eye stick that instantly relieves puffy, tired eyes by hydrating the delicate skin and minimizing fine lines around the eye for a refreshed and revitalized look.',
        rating: 4.2,
      },
    ];

    for (const product of data) {
      // const comments = product.comments;
      // delete product.comments;

      this.db.collection('products').add(product).then(res => {
        // for (const comment of comments) {
        //   res.collection('comments').add(comment);
        // }
      });
    }
  }

  // generateCountryCollection(): void {

  //   const us = {
  //     name: "United States",
  //     states: [{
  //     cities: CCS.getCities("US", "Alaska"),
  //     name: "Alaska",
  //     taxes: 0.06,
  //     value: "AK"
  //   }, {
  //     cities: CCS.getCities("US", "Alabama"),
  //     name: "Alabama",
  //     taxes: 0.1,
  //     value: "AL"
  //   }, {
  //     cities: CCS.getCities("US", "Arkansas"),
  //     name: "Arkansas",
  //     taxes: 0.03,
  //     value: "AR"
  //   }, {
  //     cities: CCS.getCities("US", "Arizona"),
  //     name: "Arizona",
  //     taxes: 0.09,
  //     value: "AZ"
  //   }, {
  //     cities: CCS.getCities("US", "California"),
  //     name: "California",
  //     taxes: 0.07,
  //     value: "CA"
  //   }, {
  //     cities: CCS.getCities("US", "Colorado"),
  //     name: "Colorado",
  //     taxes: 0.04,
  //     value: "CO"
  //   }, {
  //     cities: CCS.getCities("US", "Connecticut"),
  //     name: "Connecticut",
  //     taxes: 0.1,
  //     value: "CT"
  //   }, {
  //     cities: CCS.getCities("US", "District of Columbia"),
  //     name: "District of Columbia",
  //     taxes: 0.13,
  //     value: "DC"
  //   }, {
  //     cities: CCS.getCities("US", "Delaware"),
  //     name: "Delaware",
  //     taxes: 0.1,
  //     value: "DE"
  //   }, {
  //     cities: CCS.getCities("US", "Florida"),
  //     name: "Florida",
  //     taxes: 0.17,
  //     value: "FL"
  //   }, {
  //     cities: CCS.getCities("US", "Georgia"),
  //     name: "Georgia",
  //     taxes: 0.08,
  //     value: "GA"
  //   }, {
  //     cities: CCS.getCities("US", "Hawaii"),
  //     name: "Hawaii",
  //     taxes: 0.12,
  //     value: "HI"
  //   }, {
  //     cities: CCS.getCities("US", "Iowa"),
  //     name: "Iowa",
  //     taxes: 0.15,
  //     value: "IA"
  //   }, {
  //     cities: CCS.getCities("US", "Idaho"),
  //     name: "Idaho",
  //     taxes: 0.05,
  //     value: "ID"
  //   }, {
  //     cities: CCS.getCities("US", "Illinois"),
  //     name: "Illinois",
  //     taxes: 0.02,
  //     value: "IL"
  //   }, {
  //     cities: CCS.getCities("US", "Indiana"),
  //     name: "Indiana",
  //     taxes: 0.05,
  //     value: "IN"
  //   }, {
  //     cities: CCS.getCities("US", "Kansas"),
  //     name: "Kansas",
  //     taxes: 0.16,
  //     value: "KS"
  //   }, {
  //     cities: CCS.getCities("US", "Kentucky"),
  //     name: "Kentucky",
  //     taxes: 0.09,
  //     value: "KY"
  //   }, {
  //     cities: CCS.getCities("US", "Louisiana"),
  //     name: "Louisiana",
  //     taxes: 0.18,
  //     value: "LA"
  //   }, {
  //     cities: CCS.getCities("US", "Massachusetts"),
  //     name: "Massachusetts",
  //     taxes: 0.12,
  //     value: "MA"
  //   }, {
  //     cities: CCS.getCities("US", "Maryland"),
  //     name: "Maryland",
  //     taxes: 0.1,
  //     value: "MD"
  //   }, {
  //     cities: CCS.getCities("US", "Maine"),
  //     name: "Maine",
  //     taxes: 0.17,
  //     value: "ME"
  //   }, {
  //     cities: CCS.getCities("US", "Michigan"),
  //     name: "Michigan",
  //     taxes: 0.15,
  //     value: "MI"
  //   }, {
  //     cities: CCS.getCities("US", "Minnesota"),
  //     name: "Minnesota",
  //     taxes: 0.05,
  //     value: "MN"
  //   }, {
  //     cities: CCS.getCities("US", "Missouri"),
  //     name: "Missouri",
  //     taxes: 0.06,
  //     value: "MO"
  //   }, {
  //     cities: CCS.getCities("US", "Mississippi"),
  //     name: "Mississippi",
  //     taxes: 0.18,
  //     value: "MS"
  //   }, {
  //     cities: CCS.getCities("US", "Montana"),
  //     name: "Montana",
  //     taxes: 0.12,
  //     value: "MT"
  //   }, {
  //     cities: CCS.getCities("US", "North Carolina"),
  //     name: "North Carolina",
  //     taxes: 0.03,
  //     value: "NC"
  //   }, {
  //     cities: CCS.getCities("US", "North Dakota"),
  //     name: "North Dakota",
  //     taxes: 0.04,
  //     value: "ND"
  //   }, {
  //     cities: CCS.getCities("US", "Nebraska"),
  //     name: "Nebraska",
  //     taxes: 0.11,
  //     value: "NE"
  //   }, {
  //     cities: CCS.getCities("US", "New Hampshire"),
  //     name: "New Hampshire",
  //     taxes: 0.14,
  //     value: "NH"
  //   }, {
  //     cities: CCS.getCities("US", "New Jersey"),
  //     name: "New Jersey",
  //     taxes: 0.04,
  //     value: "NJ"
  //   }, {
  //     cities: CCS.getCities("US", "New Mexico"),
  //     name: "New Mexico",
  //     taxes: 0.05,
  //     value: "NM"
  //   }, {
  //     cities: CCS.getCities("US", "Nevada"),
  //     name: "Nevada",
  //     taxes: 0.09,
  //     value: "NV"
  //   }, {
  //     cities: CCS.getCities("US", "New York"),
  //     name: "New York",
  //     taxes: 0.04,
  //     value: "NY"
  //   }, {
  //     cities: CCS.getCities("US", "Ohio"),
  //     name: "Ohio",
  //     taxes: 0.11,
  //     value: "OH"
  //   }, {
  //     cities: CCS.getCities("US", "Oklahoma"),
  //     name: "Oklahoma",
  //     taxes: 0.11,
  //     value: "OK"
  //   }, {
  //     cities: CCS.getCities("US", "Oregon"),
  //     name: "Oregon",
  //     taxes: 0.01,
  //     value: "OR"
  //   }, {
  //     cities: CCS.getCities("US", "Pennsylvania"),
  //     name: "Pennsylvania",
  //     taxes: 0.18,
  //     value: "PA"
  //   }, {
  //     cities: CCS.getCities("US", "Rhode Island"),
  //     name: "Rhode Island",
  //     taxes: 0.11,
  //     value: "RI"
  //   }, {
  //     cities: CCS.getCities("US", "South Carolina"),
  //     name: "South Carolina",
  //     taxes: 0.01,
  //     value: "SC"
  //   }, {
  //     cities: CCS.getCities("US", "South Dakota"),
  //     name: "South Dakota",
  //     taxes: 0.19,
  //     value: "SD"
  //   }, {
  //     cities: CCS.getCities("US", "Tennessee"),
  //     name: "Tennessee",
  //     taxes: 0.05,
  //     value: "TN"
  //   }, {
  //     cities: CCS.getCities("US", "Texas"),
  //     name: "Texas",
  //     taxes: 0.11,
  //     value: "TX"
  //   }, {
  //     cities: CCS.getCities("US", "Utah"),
  //     name: "Utah",
  //     taxes: 0.13,
  //     value: "UT"
  //   }, {
  //     cities: CCS.getCities("US", "Virginia"),
  //     name: "Virginia",
  //     taxes: 0.13,
  //     value: "VA"
  //   }, {
  //     cities: CCS.getCities("US", "Vermont"),
  //     name: "Vermont",
  //     taxes: 0.13,
  //     value: "VT"
  //   }, {
  //     cities: CCS.getCities("US", "Washington"),
  //     name: "Washington",
  //     taxes: 0.12,
  //     value: "WA"
  //   }, {
  //     cities: CCS.getCities("US", "Wisconsin"),
  //     name: "Wisconsin",
  //     taxes: 0.17,
  //     value: "WI"
  //   }, {
  //     cities: CCS.getCities("US", "West Virginia"),
  //     name: "West Virginia",
  //     taxes: 0.1,
  //     value: "WV"
  //   }, {
  //     cities: CCS.getCities("US", "Wyoming"),
  //     name: "Wyoming",
  //     taxes: 0.14,
  //     value: "WY"
  //   }]
  //   };

  //   const ve = {
  //     country: "Venezuela",
  //     states: [{
  //       cities: ["Maroa", "Puerto Ayacucho", "San Fernando de Atabapo"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Amazonas",
  //       value: "Amazonas"
  //     },  {
  //       cities: ["Anaco", "Aragua de Barcelona", "Barcelona", "Boca de Uchire", "Cantaura", "Clarines", "El Chaparro", "El Pao Anzoátegui", "El Tigre", "El Tigrito", "Guanape", "Guanta", "Lechería", "Onoto", "Pariaguán", "Píritu", "Puerto La Cruz", "Puerto Píritu", "Sabana de Uchire", "San Mateo Anzoátegui", "San Pablo Anzoátegui", "San Tomé", "Santa Ana de Anzoátegui", "Santa Fe Anzoátegui", "Santa Rosa", "Soledad", "Urica", "Valle de Guanape"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Anzoátegui",
  //       value: "Anzoátegui"
  //     },  {
  //       cities: ["Achaguas", "Biruaca", "Bruzual", "El Amparo", "El Nula", "Elorza", "Guasdualito", "Mantecal", "Puerto Páez", "San Fernando de Apure", "San Juan de Payara"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Apure",
  //       value: "Apure"
  //     },  {
  //       cities: ["Barbacoas", "Cagua", "Camatagua", "Choroní", "Colonia Tovar", "El Consejo", "La Victoria", "Las Tejerías", "Magdaleno", "Maracay", "Ocumare de La Costa", "Palo Negro", "San Casimiro", "San Mateo", "San Sebastián", "Santa Cruz de Aragua", "Tocorón", "Turmero", "Villa de Cura", "Zuata"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Aragua",
  //       value: "Aragua"
  //     },  {
  //       cities: ["Barinas", "Barinitas", "Barrancas", "Calderas", "Capitanejo", "Ciudad Bolivia", "El Cantón", "Las Veguitas", "Libertad de Barinas", "Sabaneta", "Santa Bárbara de Barinas", "Socopó"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Barinas",
  //       value: "Barinas"
  //     },  {
  //       cities: ["Caicara del Orinoco", "Canaima", "Ciudad Bolívar", "Ciudad Piar", "El Callao", "El Dorado", "El Manteco", "El Palmar", "El Pao", "Guasipati", "Guri", "La Paragua", "Matanzas", "Puerto Ordaz", "San Félix", "Santa Elena de Uairén", "Tumeremo", "Unare", "Upata"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Bolívar",
  //       value: "Bolívar"
  //     },  {
  //       cities: ["Bejuma", "Belén", "Campo de Carabobo", "Canoabo", "Central Tacarigua", "Chirgua", "Ciudad Alianza", "El Palito", "Guacara", "Guigue", "Las Trincheras", "Los Guayos", "Mariara", "Miranda", "Montalbán", "Morón", "Naguanagua", "Puerto Cabello", "San Joaquín", "Tocuyito", "Urama", "Valencia", "Vigirimita"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Carabobo",
  //       value: "Carabobo"
  //     },  {
  //       cities: ["Aguirre", "Apartaderos Cojedes", "Arismendi", "Camuriquito", "El Baúl", "El Limón", "El Pao Cojedes", "El Socorro", "La Aguadita", "Las Vegas", "Libertad de Cojedes", "Mapuey", "Piñedo", "Samancito", "San Carlos", "Sucre", "Tinaco", "Tinaquillo", "Vallecito"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Cojedes",
  //       value: "Cojedes"
  //     },  {
  //       cities: ["Tucupita"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Delta Amacuro",
  //       value: "Delta Amacuro"
  //     },  {
  //       cities: ["Adícora", "Boca de Aroa", "Cabure", "Capadare", "Capatárida", "Chichiriviche", "Churuguara", "Coro", "Cumarebo", "Dabajuro", "Judibana", "La Cruz de Taratara", "La Vela de Coro", "Los Taques", "Maparari", "Mene de Mauroa", "Mirimire", "Pedregal", "Píritu Falcón", "Pueblo Nuevo Falcón", "Puerto Cumarebo", "Punta Cardón", "Punto Fijo", "San Juan de Los Cayos", "San Luis", "Santa Ana Falcón", "Santa Cruz De Bucaral", "Tocopero", "Tocuyo de La Costa", "Tucacas", "Yaracal"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Falcón",
  //       value: "Falcón"
  //     },  {
  //       cities: ["Altagracia de Orituco", "Cabruta", "Calabozo", "Camaguán", "Chaguaramas Guárico", "El Socorro", "El Sombrero", "Las Mercedes de Los Llanos", "Lezama", "Onoto", "Ortíz", "San José de Guaribe", "San Juan de Los Morros", "San Rafael de Laya", "Santa María de Ipire", "Tucupido", "Valle de La Pascua", "Zaraza"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Guárico",
  //       value: "Guárico"
  //     },  {
  //       cities: ["Aguada Grande", "Atarigua", "Barquisimeto", "Bobare", "Cabudare", "Carora", "Cubiro", "Cují", "Duaca", "El Manzano", "El Tocuyo", "Guaríco", "Humocaro Alto", "Humocaro Bajo", "La Miel", "Moroturo", "Quíbor", "Río Claro", "Sanare", "Santa Inés", "Sarare", "Siquisique", "Tintorero"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Lara",
  //       value: "Lara"
  //     },  {
  //       cities: ["Apartaderos Mérida", "Arapuey", "Bailadores", "Caja Seca", "Canaguá", "Chachopo", "Chiguara", "Ejido", "El Vigía", "La Azulita", "La Playa", "Lagunillas Mérida", "Mérida", "Mesa de Bolívar", "Mucuchíes", "Mucujepe", "Mucuruba", "Nueva Bolivia", "Palmarito", "Pueblo Llano", "Santa Cruz de Mora", "Santa Elena de Arenales", "Santo Domingo", "Tabáy", "Timotes", "Torondoy", "Tovar", "Tucani", "Zea"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Mérida",
  //       value: "Mérida"
  //     },  {
  //       cities: ["Araguita", "Carrizal", "Caucagua", "Chaguaramas Miranda", "Charallave", "Chirimena", "Chuspa", "Cúa", "Cupira", "Curiepe", "El Guapo", "El Jarillo", "Filas de Mariche", "Guarenas", "Guatire", "Higuerote", "Los Anaucos", "Los Teques", "Ocumare del Tuy", "Panaquire", "Paracotos", "Río Chico", "San Antonio de Los Altos", "San Diego de Los Altos", "San Fernando del Guapo", "San Francisco de Yare", "San José de Los Altos", "San José de Río Chico", "San Pedro de Los Altos", "Santa Lucía", "Santa Teresa", "Tacarigua de La Laguna", "Tacarigua de Mamporal", "Tácata", "Turumo"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Miranda",
  //       value: "Miranda"
  //     },  {
  //       cities: ["Aguasay", "Aragua de Maturín", "Barrancas del Orinoco", "Caicara de Maturín", "Caripe", "Caripito", "Chaguaramal", "Chaguaramas Monagas", "El Furrial", "El Tejero", "Jusepín", "La Toscana", "Maturín", "Miraflores", "Punta de Mata", "Quiriquire", "San Antonio de Maturín", "San Vicente Monagas", "Santa Bárbara", "Temblador", "Teresen", "Uracoa"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Monagas",
  //       value: "Monagas"
  //     },  {
  //       cities: ["Altagracia", "Boca de Pozo", "Boca de Río", "El Espinal", "El Valle del Espíritu Santo", "El Yaque", "Juangriego", "La Asunción", "La Guardia", "Pampatar", "Porlamar", "Puerto Fermín", "Punta de Piedras", "San Francisco de Macanao", "San Juan Bautista", "San Pedro de Coche", "Santa Ana de Nueva Esparta", "Villa Rosa"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Nueva Esparta",
  //       value: "Nueva Esparta"
  //     },  {
  //       cities: ["Acarigua", "Agua Blanca", "Araure", "Biscucuy", "Boconoito", "Campo Elías", "Chabasquén", "Guanare", "Guanarito", "La Aparición", "La Misión", "Mesa de Cavacas", "Ospino", "Papelón", "Payara", "Pimpinela", "Píritu de Portuguesa", "San Rafael de Onoto", "Santa Rosalía", "Turén"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Portuguesa",
  //       value: "Portuguesa"
  //     },  {
  //       cities: ["Altos de Sucre", "Araya", "Cariaco", "Carúpano", "Casanay", "Cumaná", "Cumanacoa", "El Morro Puerto Santo", "El Pilar", "El Poblado", "Guaca", "Guiria", "Irapa", "Manicuare", "Mariguitar", "Río Caribe", "San Antonio del Golfo", "San José de Aerocuar", "San Vicente de Sucre", "Santa Fe de Sucre", "Tunapuy", "Yaguaraparo", "Yoco"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Sucre",
  //       value: "Sucre"
  //     },  {
  //       cities: ["Abejales", "Borota", "Bramon", "Capacho", "Colón", "Coloncito", "Cordero", "El Cobre", "El Pinal", "Independencia", "La Fría", "La Grita", "La Pedrera", "La Tendida", "Las Delicias", "Las Hernández", "Lobatera", "Michelena", "Palmira", "Pregonero", "Queniquea", "Rubio", "San Antonio del Tachira", "San Cristobal", "San José de Bolívar", "San Josecito", "San Pedro del Río", "Santa Ana Táchira", "Seboruco", "Táriba", "Umuquena"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Táchira",
  //       value: "Táchira"
  //     },  {
  //       cities: ["Batatal", "Betijoque", "Boconó", "Carache", "Chejende", "Cuicas", "El Dividive", "El Jaguito", "Escuque", "Isnotú", "Jajó", "La Ceiba", "La Concepción de Trujllo", "La Mesa de Esnujaque", "La Puerta", "La Quebrada", "Mendoza Fría", "Meseta de Chimpire", "Monay", "Motatán", "Pampán", "Pampanito", "Sabana de Mendoza", "San Lázaro", "Santa Ana de Trujillo", "Tostós", "Trujillo", "Valera"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Trujillo",
  //       value: "Trujillo"
  //     },  {
  //       cities: ["Carayaca", "Litoral"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Vargas",
  //       value: "Vargas"
  //     },  {
  //       cities: ["Aroa", "Boraure", "Campo Elías de Yaracuy", "Chivacoa", "Cocorote", "Farriar", "Guama", "Marín", "Nirgua", "Sabana de Parra", "Salom", "San Felipe", "San Pablo de Yaracuy", "Urachiche", "Yaritagua", "Yumare"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Yaracuy",
  //       value: "Yaracuy"
  //     },  {
  //       cities: ["Bachaquero", "Bobures", "Cabimas", "Campo Concepción", "Campo Mara", "Campo Rojo", "Carrasquero", "Casigua", "Chiquinquirá", "Ciudad Ojeda", "El Batey", "El Carmelo", "El Chivo", "El Guayabo", "El Mene", "El Venado", "Encontrados", "Gibraltar", "Isla de Toas", "La Concepción", "La Paz", "La Sierrita", "Lagunillas", "Las Piedras", "Los Cortijos", "Machiques", "Maracaibo", "Mene Grande", "Palmarejo", "Paraguaipoa", "Potrerito", "Pueblo Nuevo", "Puertos de Altagracia", "Punta Gorda", "Sabaneta de Palma", "San Francisco", "San José de Perijá", "San Rafael del Moján", "San Timoteo", "Santa Bárbara del Zulia", "Santa Cruz de Mara", "Santa Cruz del Zulia", "Santa Rita", "Sinamaica", "Tamare", "Tía Juana", "Villa Rosario"],
  //       taxes: +(Math.random() * 20).toFixed(0) / 100,
  //       name: "Zulia",
  //       value: "Zulia"
  //     },  {
  //       ciudades: "Caracas",
  //       name: "Distrito Capital",
  //       value: "Distrito Capital"
  //     }]
  //   }

  
  //   this.db.doc('ecommerceVars/countriesTest').set({ countries: [us, ve] });
  // }
}
