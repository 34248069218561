import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  isActive = new BehaviorSubject(false);

  constructor() { }

  hideLoader(): void {
    this.isActive.next(false);
  }

  showLoader(): void {
    this.isActive.next(true);
  }
}
